import { WBFlex } from '@admiin-com/ds-web';

import { WBDrawer } from '@admiin-com/ds-web';
import { BillViewer } from '../../BillViewer';
import SimpleUpload from '../../SimpleUpload';
import { styled } from '@mui/material';
import { Bill } from '../../../PayBillCreateForm';

export const MobileBillUpload = ({
  bill,
  updateBill,
  open,
  setOpen,
}: {
  bill: Bill;
  updateBill: (bill: Bill) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <WBDrawer
      anchor={'bottom'}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      PaperProps={{
        sx: {
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          overflow: 'auto',
        },
      }}
    >
      <PDFPreviewContainer>
        {bill && bill.document ? (
          <BillViewer bill={bill} />
        ) : (
          <WBFlex
            justifyContent={'center'}
            alignItems={'center'}
            width={'100%'}
          >
            <SimpleUpload
              maxFiles={1}
              onChange={(documents) => {
                updateBill({
                  ...bill,
                  document: documents[0],
                });
              }}
            />
          </WBFlex>
        )}
      </PDFPreviewContainer>
    </WBDrawer>
  );
};

const PDFPreviewContainer = styled(WBFlex)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  padding: theme.spacing(2),
  minHeight: '500px',
}));
