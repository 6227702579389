import * as React from 'react';
import { WBCheckbox, WBSvgIcon } from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import { ScanItemsTableRow } from './ScanItemsTableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Bill } from '../../../PayBillCreateForm';
import { isBillLoading } from 'apps/react-app/src/app/helpers/tasks';

interface Props {
  bills: Bill[];
  opened: number;
  setOpened: any;
  setBills: (bills: Bill[]) => void;
}

export const ScanItemsTable = ({
  bills,
  opened,
  setOpened,
  setBills,
}: Props) => {
  const { t } = useTranslation();
  const loaded = bills.every((bill) => !isBillLoading(bill.documentAnalysis));

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <WBCheckbox
                checked={loaded && bills.every((bill) => bill.checked)}
                disabled
                icon={
                  <WBSvgIcon fontSize="small">{DefaultCheckIcon}</WBSvgIcon>
                }
                checkedIcon={
                  <WBSvgIcon fontSize="small">{CheckedIcon}</WBSvgIcon>
                }
              />
            </TableCell>
            <TableCell>{t('vendor', { ns: 'taskbox' })}</TableCell>
            <TableCell colSpan={2}>
              {t('paymentDetails', { ns: 'taskbox' })}
            </TableCell>
            <TableCell>{t('dueDate', { ns: 'taskbox' })}</TableCell>
            <TableCell align="right">{t('total', { ns: 'taskbox' })}</TableCell>
            <TableCell padding="checkbox" />
          </TableRow>
        </TableHead>
        <TableBody>
          {bills.map((bill: Bill, index: number) => (
            <ScanItemsTableRow
              key={bill.document?.key ?? index}
              index={index}
              opened={opened}
              setOpened={setOpened}
              bill={bill}
              updateBill={(newBill: Bill) => {
                const newBills = [...bills];
                newBills[index] = newBill;
                setBills(newBills);
              }}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const DefaultCheckIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="8.5" stroke="#DDDDDD" />
  </svg>
);

export const CheckedIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9ZM8.4864 12.852L13.668 6.3744L12.732 5.6256L8.3136 11.1468L5.184 8.5392L4.416 9.4608L8.4864 12.852Z"
      fill="#30C86D"
    />
  </svg>
);
