import { useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  getAdmiinAccount as GET_ADMIIN_ACCOUNT,
  getBalanceHistory as GET_BALANCE_HISTORY,
} from '@admiin-com/ds-graphql';
import { useCurrentEntityId } from '../useSelectedEntity/useSelectedEntity';

// getAdmiinAccount => Get Account Balance
// getBalanceHistory => Get Transactions

// addFundsInWallet => Add amount CC/GPay/ApplePay to connected account
// amountTransfer => For wallet to beneficiary transfer

export const useAdmiinAccount = () => {
  const [error, setError] = useState<any>({});
  const entityId = useCurrentEntityId();
  // get admiin account
  const {
    data: getAdmiinAccountData,
    loading: loadingAdmiinAccount,
    refetch: refetchAdmiinAccount,
  } = useQuery(gql(GET_ADMIIN_ACCOUNT), {
    variables: {
      entityId,
      currency: 'AUD',
    },
    skip: !entityId,
    onError(err) {
      console.log('ERROR GET_ADMIIN_ACCOUNT: ', err);
      setError(err);
    },
  });

  // get balance history
  const {
    data: getBalanceHistoryData,
    loading: loadingBalanceHistory,
    refetch: refetchBalanceHistory,
  } = useQuery(gql(GET_BALANCE_HISTORY), {
    variables: {
      entityId,
      currency: 'AUD',
      limit: 20,
      pageNum: 0,
    },
    skip: !entityId,
    onError(err) {
      console.log('ERROR GET_BALANCE_HISTORY: ', err);
      setError(err);
    },
  });

  const admiinAccount = useMemo(() => {
    return getAdmiinAccountData?.getAdmiinAccount;
  }, [getAdmiinAccountData]);

  const balanceHistory = useMemo(() => {
    return getBalanceHistoryData?.getBalanceHistory;
  }, [getBalanceHistoryData]);

  const refetchAll = () => {
    Promise.all([refetchAdmiinAccount(), refetchBalanceHistory()]);
  };

  console.log('balanceHistory: ', balanceHistory);

  return {
    admiinAccount,
    balanceHistory,
    loadingAdmiinAccount,
    loadingBalanceHistory,
    error,
    refetchAll,
  };
};
