import { PaymentSubmittedModal } from './PaymentSubmitedModal';
import { useState } from 'react';
import { TaskCreation } from '../../pages/TaskCreation/TaskCreation';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  closeButtonText?: string;
}

export function PaymentSubmittedModalContainer({
  open,
  onClose,
  title,
  description,
  closeButtonText,
}: Props) {
  const [openTaskCreation, setOpenTaskCreation] = useState(false);
  return (
    <>
      <PaymentSubmittedModal
        open={open}
        onClose={onClose}
        closeButtonText={closeButtonText}
        onCreateNewTask={() => {
          onClose();
          setOpenTaskCreation(true);
        }}
        title={title}
        description={description}
      />
      <TaskCreation
        open={openTaskCreation}
        handleCloseModal={() => {
          setOpenTaskCreation(false);
        }}
      />
    </>
  );
}
