import { useMediaQuery, useTheme } from '@mui/material';
import TaskCreatModal from './TaskCreateModalMobile';
import { useTaskCreateModal } from './useTaskCreateModal';
import TaskCreateModalDesktop from './TaskCreateModalDesktop';

function TaskCreatModalContainer() {
  const props = useTaskCreateModal();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return isMobile ? (
    <TaskCreatModal {...props} />
  ) : (
    <TaskCreateModalDesktop {...props} />
  );
}
export default TaskCreatModalContainer;
