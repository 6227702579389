import {
  WBButton,
  WBFlex,
  WBLinkButton,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LottieAnimation from '../LottieAnimation/LottieAnimation';
interface Props {
  onGetStarted: () => void;
  title?: string;
  description?: string;
  buttonTitle?: string;
  loading?: boolean;
  onBack?: () => void;
}
const Finish: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Conatiner flexDirection={'column'} alignItems={'center'} rowGap={2.5}>
      <ImagePlaceholder>
        <LottieAnimation path="/tick.lottie" />
      </ImagePlaceholder>
      <Title textAlign={'center'}>{props.title}</Title>
      <Description variant="body1" textAlign={'center'}>
        {props.description}
      </Description>
      <StartButton onClick={props.onGetStarted} loading={props.loading}>
        <WBTypography
          color={'inherit'}
          variant="body1"
          fontWeight={'medium'}
          noWrap
        >
          {props.buttonTitle}
        </WBTypography>
      </StartButton>
      <WBLinkButton color="primary.main" onClick={props.onBack}>
        {t('backToTaskbox', { ns: 'taskbox' })}
      </WBLinkButton>
    </Conatiner>
  );
};
const StartButton = styled(WBButton)(({ theme }) => ({
  padding: theme.spacing(2, 18),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 8),
  },
  flexWrap: 'nowrap',
}));
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  width: '200px',
  height: '200px',
  backgroundColor: 'transparent',
  borderRadius: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '90px',
    height: '90px',
  },
}));

const Title = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h3,
  },
}));

const Description = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body2,
  },
  color: theme.palette.text.secondary,
}));

const Conatiner = styled(WBFlex)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(20),
  marginTop: theme.spacing(5),
  paddingTop: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(2),
  },
}));

export default Finish;
