import { BeneficialOwner, Entity } from '@admiin-com/ds-graphql';
import React from 'react';
import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import PageSelector from '../../components/PageSelector/PageSelector';
import SmartUIDlg from '../../components/SmartUIDlg/SmartUIDlg';
import { isIndividualEntity } from '../../helpers/entities';
import { useOneSdkForm } from '../../hooks/useOneSdkForm/useOneSdkForm';
import VerificationBusiness from '../VerificationBusiness/VerificationBusiness';
import VerificationComplete from '../VerificationComplete/VerificationComplete';
import VerificationStart from '../VerificationStart/VerificationStart';
import { VerificationStep } from '../VerificationDlg/VerificationDlg';
import { WBFlex, WBLink } from '@admiin-com/ds-web';
import { t } from 'i18next';
import { useTheme } from '@mui/material';
export interface VerificationScreenProps {
  onSuccess: () => void;
  entity: Entity;
  onClose: () => void;
  verificationStep?: VerificationStep;
  isOnboarding?: boolean;
  isClients?: boolean;
}

export function VerificationScreen({
  entity,
  onClose,
  verificationStep,
  onSuccess,
  isOnboarding,
  isClients = false,
}: VerificationScreenProps) {
  // const [selectedEntity, setSelectedEntity] = React.useState<
  //   Entity | null | undefined
  // >();
  const mode = 'legacy';
  const [loadingVerification, setLoadingVerification] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<any>('');

  const {
    verifyOwner: initForm,
    form,
    ref,
  } = useOneSdkForm({
    entity: entity,
    mode: mode,
  });

  const [verificationModal, setVerificationModal] =
    React.useState<boolean>(false);

  const verifyOwner = async (owner: BeneficialOwner) => {
    try {
      setLoadingVerification(true);
      setVerificationModal(true);
      await initForm(owner);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoadingVerification(false);
    }
  };

  React.useEffect(() => {
    if (verificationStep === 'Complete') {
      onSuccess && onSuccess();
    }
  }, [verificationStep]);

  const handleClose = async () => {
    onClose && onClose();
  };

  const theme = useTheme();

  return (
    <>
      <PageSelector current={verificationStep}>
        <PageSelector.Page value={'Start'}>
          <VerificationStart
            entity={entity}
            onSuccess={() => {
              onSuccess && onSuccess();
            }}
            onClose={handleClose}
            isOnboarding={isOnboarding}
            isClients={isClients}
          />
        </PageSelector.Page>
        <PageSelector.Page value={'Business'}>
          <VerificationBusiness
            entity={entity}
            onSuccess={() => {
              if (isIndividualEntity(entity)) {
                if (entity?.entityBeneficialOwners?.items?.[0]?.beneficialOwner)
                  verifyOwner(
                    entity?.entityBeneficialOwners?.items?.[0]?.beneficialOwner
                  );
                else {
                  setError({ message: 'NO_BENEFICIAL_OWNER' });
                }
              } else onSuccess && onSuccess();
            }}
            onClose={handleClose}
          />

          {
            <WBFlex flexDirection="column" alignItems="center" pb={5}>
              {isOnboarding ? (
                <WBLink
                  variant="body2"
                  sx={{ mt: 5 }}
                  underline="always"
                  color={theme.palette.text.primary}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  {t('doThisLater', { ns: 'common' })}
                </WBLink>
              ) : null}
            </WBFlex>
          }
        </PageSelector.Page>
        <PageSelector.Page value={'Complete'}>
          <VerificationComplete
            onClose={() => {
              onSuccess && onSuccess();
              onClose();
            }}
          />
        </PageSelector.Page>
      </PageSelector>
      <ErrorHandler errorMessage={error?.message} isDialog />

      <SmartUIDlg
        ref={ref}
        form={form}
        mode={mode}
        open={verificationModal}
        onClose={() => setVerificationModal(false)}
      />
    </>
  );
}

export default VerificationScreen;
