import {
  xeroDisconnect,
  xeroGetConnectionStatus,
} from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';

export const useDisconnect = () => {
  const { entity } = useSelectedEntity();
  const [disConnect] = useMutation(gql(xeroDisconnect), {
    variables: {
      input: {
        entityId: entity?.id,
      },
    },
    refetchQueries: [{ query: gql(xeroGetConnectionStatus) }],
  });

  const onDisconnect = async () => {
    await disConnect();
  };
  return onDisconnect;
};
