import { WBSkeleton } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

export const ScanItemTableLoading = () => {
  return (
    <StyledTableRow>
      <TableCell padding="checkbox"></TableCell>
      <TableCell>
        <WBSkeleton variant="rounded" height={20} animation="wave" />
      </TableCell>
      <TableCell colSpan={2}>
        <WBSkeleton variant="rounded" height={20} animation="wave" />
      </TableCell>
      <TableCell>
        <WBSkeleton variant="rounded" height={20} animation="wave" />
      </TableCell>
      <TableCell align="right">
        <WBSkeleton variant="rounded" height={20} animation="wave" />
      </TableCell>
      <TableCell padding="checkbox" />
    </StyledTableRow>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
