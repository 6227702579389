import { useEffect, useState } from 'react';
import PdfViewer from '../../PdfViewer/PdfViewer';
import { getFromS3Storage } from '@admiin-com/ds-amplify';
import { Bill } from '../PayBillCreateForm';
import { WBS3Image } from '@admiin-com/ds-amplify-web';
import { WBImage } from '@admiin-com/ds-web';
import { S3UploadType } from '@admiin-com/ds-graphql';

export const BillViewer = (props: { bill: Bill }) => {
  const { bill } = props;
  const [url, setUrl] = useState(bill.document?.src);
  useEffect(() => {
    if (bill.document?.src) {
      setUrl(bill.document?.src);
    } else if (bill.document) {
      const { key, identityId, level } = bill.document;
      getFromS3Storage({ key, identityId, level }).then((url) => {
        setUrl(url);
      });
    }
  }, [bill.document]);
  const isImage = bill.document?.type === S3UploadType.IMAGE;
  return isImage && url ? (
    <WBImage src={url} responsive />
  ) : (
    <PdfViewer documentUrl={url} />
  );
};
