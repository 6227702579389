import { useFormContext } from 'react-hook-form';
import { BillCreateFormData } from '../../pages/TaskCreation/TaskCreation';
import InvoiceRenderer from './InvoiceRenderer';

const InvoiceRendererContainer = () => {
  const { watch } = useFormContext<BillCreateFormData>();

  const values = watch();

  return <InvoiceRenderer values={values} />;
};

export default InvoiceRendererContainer;
