import {
  WBButton,
  WBFlex,
  WBTypography,
  WBCheckbox,
  WBBox,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { updateEntity } from '@admiin-com/ds-graphql';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

interface Props {
  onGetStarted: () => void;
  title?: string;
  description?: string[];
  policy?: string;
  buttonTitle?: string;
}

export const OnboardingMessage: React.FC<Props> = (props) => {
  const [checked, setChecked] = useState(false);
  const handleChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const entityId = useCurrentEntityId();
  const { client } = useTaskCreationContext();
  const [updateEntityMutation, { loading }] = useMutation(gql(updateEntity));

  const onDone = async () => {
    if (checked) {
      await updateEntityMutation({
        variables: {
          input: {
            id: client?.id ?? entityId,
            termsOcrAccepted: true,
          },
        },
      });
    }
    props.onGetStarted();
  };
  return (
    <Conatiner flexDirection={'column'} alignItems={'center'} rowGap={2.5}>
      <ImagePlaceholder>
        <LottieAnimation path="/lottie/pay-bill.lottie" />
      </ImagePlaceholder>
      <Title textAlign={'center'}>{props.title}</Title>
      <DescriptionContainer>
        {props.description &&
          props.description.map((desc, index) => (
            <Description key={index} textAlign={'left'}>
              {desc}
            </Description>
          ))}
      </DescriptionContainer>
      <CheckboxMBWrapper>
        <CheckboxContainer>
          <Checkbox
            label={props.policy}
            alignItems={'flex-start'}
            checked={checked}
            onChange={handleChecked}
          />
        </CheckboxContainer>
      </CheckboxMBWrapper>
      <StartButton onClick={onDone} disabled={!checked} loading={loading}>
        {props.buttonTitle}
      </StartButton>
    </Conatiner>
  );
};
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  //width: '200px',
  //height: '200px',
  borderRadius: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    //width: '150px',
    //height: '150px'
  },
}));
const StartButton = styled(WBButton)(({ theme }) => ({
  padding: theme.spacing(2, 24),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 12),
    width: '100%',
  },
}));
const Title = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h4,
  },
}));

const Description = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body2,
  },
}));

const Conatiner = styled(WBFlex)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(20),
  //marginTop: theme.spacing(5),
  paddingTop: theme.spacing(0), // lottie animation taking lots of space
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(1),
    width: '100%',
  },
}));

const Checkbox = styled(WBCheckbox)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  padding: theme.spacing(0.25),
  marginLeft: theme.spacing(0.75),
  marginRight: theme.spacing(0.75),
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.secondary.light,
    padding: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
  },
}));

const CheckboxContainer = styled(WBBox)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  borderWidth: '1px',
  borderColor: theme.palette.primary.main,
  borderStyle: 'solid',
  width: '100%',
  padding: theme.spacing(2),
  color: theme.palette.common.black,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.secondary.light,
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    width: '100%',
  },
}));

const CheckboxMBWrapper = styled(WBBox)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const DescriptionContainer = styled(WBBox)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
