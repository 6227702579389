import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useTasks } from '../../hooks/useTasks/useTasks';
import { useTaskCreationContext } from '../../pages/TaskCreation/TaskCreation';
import { OnboardingMessage } from './OnboardingMessage';
import { Bill, PayBillCreateForm } from './PayBillCreateForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Task } from '@admiin-com/ds-graphql';

export const PayBill = () => {
  const { client } = useTaskCreationContext();
  const { tasks } = useTasks({
    entityId: client?.id,
  });
  const { t } = useTranslation();

  const [isOnboarding, setIsOnboarding] = React.useState(true);

  React.useEffect(() => {
    if (tasks.length === 0) {
      setIsOnboarding(true);
    } else setIsOnboarding(false);
  }, [tasks]);
  const { entity } = useSelectedEntity();

  const showOnboarding = isOnboarding && !(client ?? entity)?.termsOcrAccepted;

  const { task } = useTaskCreationContext();

  const [draftTask, setDraftTask] = React.useState<Task | null>(null);

  React.useEffect(() => {
    if (task) {
      setDraftTask(task);
    } else {
      setDraftTask(null);
    }
  }, [task]);

  return showOnboarding ? (
    <OnboardingMessage
      onGetStarted={() => setIsOnboarding(false)}
      title={t('payBillTitle', { ns: 'taskbox' })}
      description={[
        t('payBillMessage1', { ns: 'taskbox' }),
        t('payBillMessage2', { ns: 'taskbox' }),
        t('payBillMessage3', { ns: 'taskbox' }),
      ]}
      policy={t('payBillPolicyMessage', { ns: 'taskbox' })}
      buttonTitle={t('getStarted', { ns: 'taskbox' })}
    />
  ) : (
    <PayBillCreateForm draftTask={draftTask} />
  );
};
