import {
  DocumentAnalysis,
  DocumentAnalysisLineItemInput,
  PaymentFrequency,
  TaxType,
} from '@admiin-com/ds-graphql';
import { Bill } from '../PayBillCreateForm';

export const transformDocumentAnalysisToBill = (
  documentAnalysis: DocumentAnalysis
): Bill => {
  const from =
    documentAnalysis.matchedContact ??
    documentAnalysis.matchedEntity ??
    documentAnalysis.newContact ??
    undefined;
  return {
    checked: true,
    document: documentAnalysis.documents?.[0] ?? null,
    documentAnalysis: documentAnalysis,
    from: from,
    to: null,
    amount: (documentAnalysis.task?.amount ?? 0) / 100,
    dueAt: documentAnalysis.task?.dueAt ?? '',
    issuedAt: documentAnalysis.task?.issuedAt ?? '',
    lineItems: (
      documentAnalysis.task?.lineItems?.filter((item) => item !== null) ?? []
    ).map((item) => ({
      ...item,
      taxType:
        (from as any)?.metadata?.subCategory === 'TAX'
          ? TaxType.NO_GST
          : item?.taxType ?? TaxType.NO_GST,
      unitPrice: (item?.unitPrice ?? 0) / 100,
      price: (item?.price ?? 0) / 100,
    })) as DocumentAnalysisLineItemInput[],
    reference: documentAnalysis.task?.reference ?? '',
    bpay: documentAnalysis.matchedContact?.bpay ?? documentAnalysis.bpay,
    bank:
      (documentAnalysis.matchedContact?.bank
        ? {
            ...documentAnalysis.matchedContact.bank,
            __typename: 'DocumentAnalysisBankAccount',
          }
        : null) ??
      documentAnalysis.bank ??
      null,
    paymentFrequency: PaymentFrequency.ONCE,
  };
};
