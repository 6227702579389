import React, { useEffect, useState } from 'react';
import { init } from '@airwallex/components-sdk';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import {
  generateCodeChallengeFromVerifier,
  generateCodeVerifier,
} from '../../helpers/generateCodeChallenge';
import { gql, useMutation } from '@apollo/client';
import {
  AccountStatus,
  AuthCodeType,
  Entity,
  getAirwallexAuthCode as GET_AUTH_CODE,
} from '@admiin-com/ds-graphql';
import AirwallexKYC from './AirwallexKYC';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '@admiin-com/ds-web';
import AirwallexKYCRFI from './AirwallexKYCRFI';
const { VITE_AIRWALLEX_ENV, VITE_AIRWALLEX_CLIENT_ID } = import.meta.env;

const AirwallexOnboarding: React.FC<{
  entity: Entity | null | undefined;
  onClose: () => void;
  onSuccess: (entity: Entity | null | undefined) => void;
}> = ({ entity, onSuccess, onClose }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [initialized, setInitialized] = useState(false);
  const showSnackbar = useSnackbar();
  const [getAirwallexAuthCode] = useMutation(gql(GET_AUTH_CODE));
  const { t } = useTranslation();

  useEffect(() => {
    const initializeSDK = async () => {
      const codeVerifier = generateCodeVerifier();
      const codeChallenge = await generateCodeChallengeFromVerifier(
        codeVerifier
      );
      try {
        const { data } = await getAirwallexAuthCode({
          variables: {
            input: {
              codeChallenge,
              entityId: entity?.id,
              type:
                entity?.accountStatus === AccountStatus.ACTION_REQUIRED
                  ? AuthCodeType.KYCRFI
                  : AuthCodeType.KYC,
            },
          },
          fetchPolicy: 'network-only',
        });
        const authCode =
          data.getAirwallexAuthCode &&
          JSON.parse(data.getAirwallexAuthCode).authorization_code;

        // initialize AirwallexOnboarding instance on window
        await init({
          authCode,
          codeVerifier,
          env: VITE_AIRWALLEX_ENV,
          clientId: VITE_AIRWALLEX_CLIENT_ID as string,
        });
        setInitialized(true);
      } catch (e) {
        console.error(e);
        showSnackbar({
          message: t('initError', { ns: 'airwallex' }),
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
        onClose();
      }
      setLoading(false);
    };

    initializeSDK();
  }, []);

  if (!initialized) {
    return (
      <Stack spacing={2}>
        {loading && (
          <>
            <Typography variant="h4" sx={{ textAlign: 'center' }}>
              {t('loadingVerificationForm', {
                ns: 'common',
              })}
            </Typography>
            <LinearProgress color="primary" />
          </>
        )}
      </Stack>
    );
  }

  if (entity?.accountStatus === AccountStatus.ACTION_REQUIRED)
    return <AirwallexKYCRFI onSuccess={onSuccess} onClose={onClose} />;

  return <AirwallexKYC onSuccess={onSuccess} onClose={onClose} />;
};

export default AirwallexOnboarding;
