import { useContactSelection } from '../../hooks/useContactSelection/useContactSelection';

import { TaskDirection } from '@admiin-com/ds-graphql';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import React, { useState } from 'react';
import { AutoCompleteDataType } from '../../components/AutoCompleteLookup/AutoCompleteLookup';
import { useTaskCreationContext } from './TaskCreation';
import { useFormContext } from 'react-hook-form';

export const useDefaultTaskDirection = () => {
  const [fromType, setFromType] = useState<AutoCompleteDataType>('Entity');
  const [toType, setToType] = useState<AutoCompleteDataType>('Entity');
  const { selectedContact } = useContactSelection();
  const { entity } = useSelectedEntity();
  const { taskDirection } = useTaskCreationContext();
  const { setValue, watch } = useFormContext();

  React.useEffect(() => {
    if (taskDirection === TaskDirection.SENDING) {
      // setValue('to', null);
      // setValue('from', null);
      if (entity) {
        setDefaultValueFromValue(entity);
      }
    } else if (taskDirection === TaskDirection.RECEIVING) {
      // setValue('from', null);
      if (selectedContact) {
        setDefaultValueFromValue(selectedContact);
      }
      if (entity) {
        setDefaultValueToValue(entity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskDirection, entity, selectedContact]);

  const setDefaultValueToValue = (value: any) => {
    if (!watch('to') && value) {
      setValue('to', value);
    }
  };

  const setDefaultValueFromValue = (value: any) => {
    if (!watch('from') && value) {
      setValue('from', value);
    }
  };

  React.useEffect(() => {
    switch (taskDirection) {
      case TaskDirection.SENDING:
        setFromType('Entity');
        setToType('Contact');
        break;
      case TaskDirection.RECEIVING:
        setFromType('ContactsAndVerifiedEntity');
        setToType('Entity');
        break;
    }
  }, [taskDirection]);
  return { fromType, toType, setToType, setFromType };
};
