import React from 'react';
import { WBButton, WBSvgIcon, WBBox, WBTypography } from '@admiin-com/ds-web';
import XeroLogo from '../../../assets/icons/xero-logo.svg';
import { useTranslation } from 'react-i18next';
import { useXeroConnect } from './useXeroConnect';
import { XeroScopeSet } from '@admiin-com/ds-graphql';

const ConnectButton: React.FC<{
  scopeSet?: XeroScopeSet;
}> = ({ scopeSet }) => {
  const { t } = useTranslation();
  const onSignUpXero = useXeroConnect(scopeSet);

  const [loading, setLoading] = React.useState(false);
  const handleXeroConnect = async () => {
    setLoading(true);
    await onSignUpXero();
    setLoading(false);
  };

  return (
    <WBButton
      sx={{
        borderRadius: '999px',
        backgroundColor: '#13B5EA',
        '&:hover': {
          backgroundColor: '#088cb9',
        },
        color: 'white',
        padding: 2,
        display: 'flex',
        justifyContent: 'start',
      }}
      loading={loading}
      onClick={handleXeroConnect}
    >
      <WBSvgIcon
        viewBox="0 0 6 6"
        fontSize="large"
        component={'div'}
        sx={{ backgroundColor: `rgba(255,255,255,0)` }}
      >
        <XeroLogo />
      </WBSvgIcon>
      <WBBox ml={1}>
        <WBTypography color={'inherit'} fontWeight={'medium'}>
          {t('connectWithXero', { ns: 'contacts' })}
        </WBTypography>
        <WBTypography variant="body2" color={'inherit'} fontWeight={'regular'}>
          {t('synchronizeXeroContacts', { ns: 'contacts' })}
        </WBTypography>
      </WBBox>
    </WBButton>
  );
};

export default ConnectButton;
