import * as React from 'react';
import {
  useCurrentEntityId,
  useSelectedEntity,
} from '../../../hooks/useSelectedEntity/useSelectedEntity';
import { useSubscription, OnDataOptions, gql } from '@apollo/client';
import {
  DocumentAnalysis,
  onUpdateDocumentAnalysis as ON_UPDATE_DOCUMENT_ANALYSIS,
  OnUpdateDocumentAnalysisSubscription,
} from '@admiin-com/ds-graphql';
import { Bill } from '../PayBillCreateForm';
import { transformDocumentAnalysisToBill } from '../Upload/transformer';
import { isBillLoading } from '../../../helpers/tasks';

interface Props {
  bills: Bill[];
  setBills: (bills: Bill[]) => void;
  setScanning: (scanning: boolean) => void;
}
export const useScanning = ({ bills, setBills, setScanning }: Props) => {
  const entityId = useCurrentEntityId();
  const [rows, setRows] = React.useState<DocumentAnalysis[]>([]);

  useSubscription(gql(ON_UPDATE_DOCUMENT_ANALYSIS), {
    variables: {
      entityId: entityId,
    },
    skip: !entityId || !bills.length,
    onData: (data: OnDataOptions<OnUpdateDocumentAnalysisSubscription>) => {
      const updatedData = data.data.data;
      console.log('onData', updatedData);
      const isExist = rows.find(
        (row) => row.id === updatedData?.onUpdateDocumentAnalysis?.id
      );
      if (isExist && updatedData?.onUpdateDocumentAnalysis) {
        setRows((prevRows) =>
          prevRows.map((row) =>
            row?.id === updatedData.onUpdateDocumentAnalysis?.id
              ? { ...updatedData.onUpdateDocumentAnalysis }
              : row
          )
        );
      }
      if (!isExist && updatedData && updatedData.onUpdateDocumentAnalysis) {
        setRows((prevRows: DocumentAnalysis[]) => {
          if (updatedData.onUpdateDocumentAnalysis) {
            return prevRows.concat(updatedData.onUpdateDocumentAnalysis);
          }
          return prevRows;
        });
      }
    },
    onError: (err) => {
      console.error('onError', err);
      setScanning(false);
    },
    shouldResubscribe: true,
  });

  React.useEffect(() => {
    if (bills.every((bill) => !isBillLoading(bill.documentAnalysis))) {
      setScanning(false);
    }
  }, [bills]);

  const { entity } = useSelectedEntity();
  React.useEffect(() => {
    setBills(
      bills.map((bill: Bill) => {
        const newDocumentAnalysis =
          rows.find((row) => row.documents?.[0]?.key === bill.document?.key) ??
          bill.documentAnalysis;
        return {
          ...bill,
          documentAnalysis: newDocumentAnalysis,
          ...(newDocumentAnalysis
            ? {
                ...transformDocumentAnalysisToBill(newDocumentAnalysis),
                to: entity,
              }
            : {}),
        };
      })
    );
  }, [rows]);
};
