export const COUNTRY_LIST = [
  {
    language: 'en',
    options: [
      { label: 'Australia', value: 'AU' },
      { label: 'Switzerland', value: 'CH' },
      { label: 'United States', value: 'US' },
      { label: 'Afghanistan', value: 'AF' },
      { label: 'Aland Islands', value: 'AX' },
      { label: 'Albania', value: 'AL' },
      { label: 'Algeria', value: 'DZ' },
      { label: 'American Samoa', value: 'AS' },
      { label: 'Andorra', value: 'AD' },
      { label: 'Angola', value: 'AO' },
      { label: 'Anguilla', value: 'AI' },
      { label: 'Antarctica', value: 'AQ' },
      { label: 'Antigua and Barbuda', value: 'AG' },
      { label: 'Argentina', value: 'AR' },
      { label: 'Armenia', value: 'AM' },
      { label: 'Aruba', value: 'AW' },
      { label: 'Austria', value: 'AT' },
      { label: 'Azerbaijan', value: 'AZ' },
      { label: 'Bahamas', value: 'BS' },
      { label: 'Bahrain', value: 'BH' },
      { label: 'Bangladesh', value: 'BD' },
      { label: 'Barbados', value: 'BB' },
      { label: 'Belarus', value: 'BY' },
      { label: 'Belgium', value: 'BE' },
      { label: 'Belize', value: 'BZ' },
      { label: 'Benin', value: 'BJ' },
      { label: 'Bermuda', value: 'BM' },
      { label: 'Bhutan', value: 'BT' },
      { label: 'Bolivia', value: 'BO' },
      { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
      { label: 'Bosnia and Herzegovina', value: 'BA' },
      { label: 'Botswana', value: 'BW' },
      { label: 'Bouvet Island', value: 'BV' },
      { label: 'Brazil', value: 'BR' },
      { label: 'British Indian Ocean Territory', value: 'IO' },
      { label: 'Brunei Darussalam', value: 'BN' },
      { label: 'Bulgaria', value: 'BG' },
      { label: 'Burkina Faso', value: 'BF' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Cambodia', value: 'KH' },
      { label: 'Cameroon', value: 'CM' },
      { label: 'Canada', value: 'CA' },
      { label: 'Cape Verde', value: 'CV' },
      { label: 'Cayman Islands', value: 'KY' },
      { label: 'Central African Republic', value: 'CF' },
      { label: 'Chad', value: 'TD' },
      { label: 'Chile', value: 'CL' },
      { label: 'China', value: 'CN' },
      { label: 'Christmas Island', value: 'CX' },
      { label: 'Cocos (Keeling) Islands', value: 'CC' },
      { label: 'Colombia', value: 'CO' },
      { label: 'Comoros', value: 'KM' },
      { label: 'Congo', value: 'CG' },
      { label: 'Congo, Democratic Republic of the Congo', value: 'CD' },
      { label: 'Cook Islands', value: 'CK' },
      { label: 'Costa Rica', value: 'CR' },
      { label: "Cote D'Ivoire", value: 'CI' },
      { label: 'Croatia', value: 'HR' },
      { label: 'Cuba', value: 'CU' },
      { label: 'Curacao', value: 'CW' },
      { label: 'Cyprus', value: 'CY' },
      { label: 'Czech Republic', value: 'CZ' },
      { label: 'Denmark', value: 'DK' },
      { label: 'Djibouti', value: 'DJ' },
      { label: 'Dominica', value: 'DM' },
      { label: 'Dominican Republic', value: 'DO' },
      { label: 'Ecuador', value: 'EC' },
      { label: 'Egypt', value: 'EG' },
      { label: 'El Salvador', value: 'SV' },
      { label: 'Equatorial Guinea', value: 'GQ' },
      { label: 'Eritrea', value: 'ER' },
      { label: 'Estonia', value: 'EE' },
      { label: 'Ethiopia', value: 'ET' },
      { label: 'Falkland Islands (Malvinas)', value: 'FK' },
      { label: 'Faroe Islands', value: 'FO' },
      { label: 'Fiji', value: 'FJ' },
      { label: 'Finland', value: 'FI' },
      { label: 'France', value: 'FR' },
      { label: 'French Guiana', value: 'GF' },
      { label: 'French Polynesia', value: 'PF' },
      { label: 'French Southern Territories', value: 'TF' },
      { label: 'Gabon', value: 'GA' },
      { label: 'Gambia', value: 'GM' },
      { label: 'Georgia', value: 'GE' },
      { label: 'Germany', value: 'DE' },
      { label: 'Ghana', value: 'GH' },
      { label: 'Gibraltar', value: 'GI' },
      { label: 'Greece', value: 'GR' },
      { label: 'Greenland', value: 'GL' },
      { label: 'Grenada', value: 'GD' },
      { label: 'Guadeloupe', value: 'GP' },
      { label: 'Guam', value: 'GU' },
      { label: 'Guatemala', value: 'GT' },
      { label: 'Guernsey', value: 'GG' },
      { label: 'Guinea', value: 'GN' },
      { label: 'Guinea-Bissau', value: 'GW' },
      { label: 'Guyana', value: 'GY' },
      { label: 'Haiti', value: 'HT' },
      { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
      { label: 'Holy See (Vatican City State)', value: 'VA' },
      { label: 'Honduras', value: 'HN' },
      { label: 'Hong Kong', value: 'HK' },
      { label: 'Hungary', value: 'HU' },
      { label: 'Iceland', value: 'IS' },
      { label: 'India', value: 'IN' },
      { label: 'Indonesia', value: 'ID' },
      { label: 'Iran, Islamic Republic of', value: 'IR' },
      { label: 'Iraq', value: 'IQ' },
      { label: 'Ireland', value: 'IE' },
      { label: 'Isle of Man', value: 'IM' },
      { label: 'Israel', value: 'IL' },
      { label: 'Italy', value: 'IT' },
      { label: 'Jamaica', value: 'JM' },
      { label: 'Japan', value: 'JP' },
      { label: 'Jersey', value: 'JE' },
      { label: 'Jordan', value: 'JO' },
      { label: 'Kazakhstan', value: 'KZ' },
      { label: 'Kenya', value: 'KE' },
      { label: 'Kiribati', value: 'KI' },
      { label: "Korea, Democratic People's Republic of", value: 'KP' },
      { label: 'Korea, Republic of', value: 'KR' },
      { label: 'Kosovo', value: 'XK' },
      { label: 'Kuwait', value: 'KW' },
      { label: 'Kyrgyzstan', value: 'KG' },
      { label: "Lao People's Democratic Republic", value: 'LA' },
      { label: 'Latvia', value: 'LV' },
      { label: 'Lebanon', value: 'LB' },
      { label: 'Lesotho', value: 'LS' },
      { label: 'Liberia', value: 'LR' },
      { label: 'Libyan Arab Jamahiriya', value: 'LY' },
      { label: 'Liechtenstein', value: 'LI' },
      { label: 'Lithuania', value: 'LT' },
      { label: 'Luxembourg', value: 'LU' },
      { label: 'Macao', value: 'MO' },
      { label: 'Macedonia, the Former Yugoslav Republic of', value: 'MK' },
      { label: 'Madagascar', value: 'MG' },
      { label: 'Malawi', value: 'MW' },
      { label: 'Malaysia', value: 'MY' },
      { label: 'Maldives', value: 'MV' },
      { label: 'Mali', value: 'ML' },
      { label: 'Malta', value: 'MT' },
      { label: 'Marshall Islands', value: 'MH' },
      { label: 'Martinique', value: 'MQ' },
      { label: 'Mauritania', value: 'MR' },
      { label: 'Mauritius', value: 'MU' },
      { label: 'Mayotte', value: 'YT' },
      { label: 'Mexico', value: 'MX' },
      { label: 'Micronesia, Federated States of', value: 'FM' },
      { label: 'Moldova, Republic of', value: 'MD' },
      { label: 'Monaco', value: 'MC' },
      { label: 'Mongolia', value: 'MN' },
      { label: 'Montenegro', value: 'ME' },
      { label: 'Montserrat', value: 'MS' },
      { label: 'Morocco', value: 'MA' },
      { label: 'Mozambique', value: 'MZ' },
      { label: 'Myanmar', value: 'MM' },
      { label: 'Namibia', value: 'NA' },
      { label: 'Nauru', value: 'NR' },
      { label: 'Nepal', value: 'NP' },
      { label: 'Netherlands', value: 'NL' },
      { label: 'Netherlands Antilles', value: 'AN' },
      { label: 'New Caledonia', value: 'NC' },
      { label: 'New Zealand', value: 'NZ' },
      { label: 'Nicaragua', value: 'NI' },
      { label: 'Niger', value: 'NE' },
      { label: 'Nigeria', value: 'NG' },
      { label: 'Niue', value: 'NU' },
      { label: 'Norfolk Island', value: 'NF' },
      { label: 'Northern Mariana Islands', value: 'MP' },
      { label: 'Norway', value: 'NO' },
      { label: 'Oman', value: 'OM' },
      { label: 'Pakistan', value: 'PK' },
      { label: 'Palau', value: 'PW' },
      { label: 'Palestinian Territory, Occupied', value: 'PS' },
      { label: 'Panama', value: 'PA' },
      { label: 'Papua New Guinea', value: 'PG' },
      { label: 'Paraguay', value: 'PY' },
      { label: 'Peru', value: 'PE' },
      { label: 'Philippines', value: 'PH' },
      { label: 'Pitcairn', value: 'PN' },
      { label: 'Poland', value: 'PL' },
      { label: 'Portugal', value: 'PT' },
      { label: 'Puerto Rico', value: 'PR' },
      { label: 'Qatar', value: 'QA' },
      { label: 'Reunion', value: 'RE' },
      { label: 'Romania', value: 'RO' },
      { label: 'Russian Federation', value: 'RU' },
      { label: 'Rwanda', value: 'RW' },
      { label: 'Saint Barthelemy', value: 'BL' },
      { label: 'Saint Helena', value: 'SH' },
      { label: 'Saint Kitts and Nevis', value: 'KN' },
      { label: 'Saint Lucia', value: 'LC' },
      { label: 'Saint Martin', value: 'MF' },
      { label: 'Saint Pierre and Miquelon', value: 'PM' },
      { label: 'Saint Vincent and the Grenadines', value: 'VC' },
      { label: 'Samoa', value: 'WS' },
      { label: 'San Marino', value: 'SM' },
      { label: 'Sao Tome and Principe', value: 'ST' },
      { label: 'Saudi Arabia', value: 'SA' },
      { label: 'Senegal', value: 'SN' },
      { label: 'Serbia', value: 'RS' },
      { label: 'Serbia and Montenegro', value: 'CS' },
      { label: 'Seychelles', value: 'SC' },
      { label: 'Sierra Leone', value: 'SL' },
      { label: 'Singapore', value: 'SG' },
      { label: 'Sint Maarten', value: 'SX' },
      { label: 'Slovakia', value: 'SK' },
      { label: 'Slovenia', value: 'SI' },
      { label: 'Solomon Islands', value: 'SB' },
      { label: 'Somalia', value: 'SO' },
      { label: 'South Africa', value: 'ZA' },
      { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
      { label: 'South Sudan', value: 'SS' },
      { label: 'Spain', value: 'ES' },
      { label: 'Sri Lanka', value: 'LK' },
      { label: 'Sudan', value: 'SD' },
      { label: 'Suriname', value: 'SR' },
      { label: 'Svalbard and Jan Mayen', value: 'SJ' },
      { label: 'Swaziland', value: 'SZ' },
      { label: 'Sweden', value: 'SE' },
      { label: 'Syrian Arab Republic', value: 'SY' },
      { label: 'Taiwan, Province of China', value: 'TW' },
      { label: 'Tajikistan', value: 'TJ' },
      { label: 'Tanzania, United Republic of', value: 'TZ' },
      { label: 'Thailand', value: 'TH' },
      { label: 'Timor-Leste', value: 'TL' },
      { label: 'Togo', value: 'TG' },
      { label: 'Tokelau', value: 'TK' },
      { label: 'Tonga', value: 'TO' },
      { label: 'Trinidad and Tobago', value: 'TT' },
      { label: 'Tunisia', value: 'TN' },
      { label: 'Turkey', value: 'TR' },
      { label: 'Turkmenistan', value: 'TM' },
      { label: 'Turks and Caicos Islands', value: 'TC' },
      { label: 'Tuvalu', value: 'TV' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Ukraine', value: 'UA' },
      { label: 'United Arab Emirates', value: 'AE' },
      { label: 'United Kingdom', value: 'GB' },
      { label: 'United States', value: 'US' },
      { label: 'United States Minor Outlying Islands', value: 'UM' },
      { label: 'Uruguay', value: 'UY' },
      { label: 'Uzbekistan', value: 'UZ' },
      { label: 'Vanuatu', value: 'VU' },
      { label: 'Venezuela', value: 'VE' },
      { label: 'Viet Nam', value: 'VN' },
      { label: 'Virgin Islands, British', value: 'VG' },
      { label: 'Virgin Islands, U.s.', value: 'VI' },
      { label: 'Wallis and Futuna', value: 'WF' },
      { label: 'Western Sahara', value: 'EH' },
      { label: 'Yemen', value: 'YE' },
      { label: 'Zambia', value: 'ZM' },
      { label: 'Zimbabwe', value: 'ZW' },
    ],
  },
  {
    language: 'de',
    options: [
      { label: 'Australien', value: 'AU' },
      { label: 'Schweiz', value: 'CH' },
      { label: 'Vereinigte Staaten', value: 'US' },
      { label: 'Afghanistan', value: 'AF' },
      { label: 'Aland Islands', value: 'AX' },
      { label: 'Albanien', value: 'AL' },
      { label: 'Algerien', value: 'DZ' },
      { label: 'Amerikanischen Samoa-Inseln', value: 'AS' },
      { label: 'Andorra', value: 'AD' },
      { label: 'Angola', value: 'AO' },
      { label: 'Anguilla', value: 'AI' },
      { label: 'Antarktis', value: 'AQ' },
      { label: 'Antigua und Barbuda', value: 'AG' },
      { label: 'Argentinien', value: 'AR' },
      { label: 'Armenien', value: 'AM' },
      { label: 'Aruba', value: 'AW' },
      { label: 'Österreich', value: 'AT' },
      { label: 'Aserbaidschan', value: 'AZ' },
      { label: 'Bahamas', value: 'BS' },
      { label: 'Bahrain', value: 'BH' },
      { label: 'Bangladesch', value: 'BD' },
      { label: 'Barbados', value: 'BB' },
      { label: 'Weißrussland', value: 'BY' },
      { label: 'Belgien', value: 'BE' },
      { label: 'Belize', value: 'BZ' },
      { label: 'Benin', value: 'BJ' },
      { label: 'Bermuda', value: 'BM' },
      { label: 'Bhutan', value: 'BT' },
      { label: 'Bolivien', value: 'BO' },
      { label: 'Bonaire, Sint Eustatius und Saba', value: 'BQ' },
      { label: 'Bosnien und Herzegowina', value: 'BA' },
      { label: 'Botswana', value: 'BW' },
      { label: 'Bouvet Island', value: 'BV' },
      { label: 'Brasilien', value: 'BR' },
      { label: 'Britisches Territorium des Indischen Ozeans', value: 'IO' },
      { label: 'Brunei Darussalam', value: 'BN' },
      { label: 'Bulgarien', value: 'BG' },
      { label: 'Burkina Faso', value: 'BF' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Kambodscha', value: 'KH' },
      { label: 'Kamerun', value: 'CM' },
      { label: 'Kanada', value: 'CA' },
      { label: 'Kap Verde', value: 'CV' },
      { label: 'Cayman Inseln', value: 'KY' },
      { label: 'Zentralafrikanische Republik', value: 'CF' },
      { label: 'Tschad', value: 'TD' },
      { label: 'Chile', value: 'CL' },
      { label: 'China', value: 'CN' },
      { label: 'Weihnachtsinsel', value: 'CX' },
      { label: 'Kokosinseln (Keelinginseln)', value: 'CC' },
      { label: 'Kolumbien', value: 'CO' },
      { label: 'Komoren', value: 'KM' },
      { label: 'Kongo', value: 'CG' },
      { label: 'Kongo, Demokratische Republik Kongo', value: 'CD' },
      { label: 'Cookinseln', value: 'CK' },
      { label: 'Costa Rica', value: 'CR' },
      { label: 'Elfenbeinküste', value: 'CI' },
      { label: 'Kroatien', value: 'HR' },
      { label: 'Kuba', value: 'CU' },
      { label: 'Curacao', value: 'CW' },
      { label: 'Zypern', value: 'CY' },
      { label: 'Tschechien', value: 'CZ' },
      { label: 'Dänemark', value: 'DK' },
      { label: 'Dschibuti', value: 'DJ' },
      { label: 'Dominica', value: 'DM' },
      { label: 'Dominikanische Republik', value: 'DO' },
      { label: 'Ecuador', value: 'EC' },
      { label: 'Ägypten', value: 'EG' },
      { label: 'El Salvador', value: 'SV' },
      { label: 'Äquatorialguinea', value: 'GQ' },
      { label: 'Eritrea', value: 'ER' },
      { label: 'Estland', value: 'EE' },
      { label: 'Äthiopien', value: 'ET' },
      { label: 'Falklandinseln (Malvinas)', value: 'FK' },
      { label: 'Färöer Inseln', value: 'FO' },
      { label: 'Fidschi', value: 'FJ' },
      { label: 'Finnland', value: 'FI' },
      { label: 'Frankreich', value: 'FR' },
      { label: 'Französisch-Guayana', value: 'GF' },
      { label: 'Französisch Polynesien', value: 'PF' },
      { label: 'Südfranzösische Territorien', value: 'TF' },
      { label: 'Gabun', value: 'GA' },
      { label: 'Gambia', value: 'GM' },
      { label: 'Georgia', value: 'GE' },
      { label: 'Deutschland', value: 'DE' },
      { label: 'Ghana', value: 'GH' },
      { label: 'Gibraltar', value: 'GI' },
      { label: 'Griechenland', value: 'GR' },
      { label: 'Grönland', value: 'GL' },
      { label: 'Grenada', value: 'GD' },
      { label: 'Guadeloupe', value: 'GP' },
      { label: 'Guam', value: 'GU' },
      { label: 'Guatemala', value: 'GT' },
      { label: 'Guernsey', value: 'GG' },
      { label: 'Guinea', value: 'GN' },
      { label: 'Guinea-Bissau', value: 'GW' },
      { label: 'Guyana', value: 'GY' },
      { label: 'Haiti', value: 'HT' },
      { label: 'Heard Island und McDonald Islands', value: 'HM' },
      { label: 'Heiliger Stuhl (Staat der Vatikanstadt)', value: 'VA' },
      { label: 'Honduras', value: 'HN' },
      { label: 'Hongkong', value: 'HK' },
      { label: 'Ungarn', value: 'HU' },
      { label: 'Island', value: 'IS' },
      { label: 'Indien', value: 'IN' },
      { label: 'Indonesien', value: 'ID' },
      { label: 'Iran, Islamische Republik', value: 'IR' },
      { label: 'Irak', value: 'IQ' },
      { label: 'Irland', value: 'IE' },
      { label: 'Isle of Man', value: 'IM' },
      { label: 'Israel', value: 'IL' },
      { label: 'Italien', value: 'IT' },
      { label: 'Jamaika', value: 'JM' },
      { label: 'Japan', value: 'JP' },
      { label: 'Jersey', value: 'JE' },
      { label: 'Jordanien', value: 'JO' },
      { label: 'Kasachstan', value: 'KZ' },
      { label: 'Kenia', value: 'KE' },
      { label: 'Kiribati', value: 'KI' },
      { label: 'Korea, Demokratische Volksrepublik', value: 'KP' },
      { label: 'Korea, Republik von', value: 'KR' },
      { label: 'Kosovo', value: 'XK' },
      { label: 'Kuwait', value: 'KW' },
      { label: 'Kirgisistan', value: 'KG' },
      { label: 'Demokratische Volksrepublik Laos', value: 'LA' },
      { label: 'Lettland', value: 'LV' },
      { label: 'Libanon', value: 'LB' },
      { label: 'Lesotho', value: 'LS' },
      { label: 'Liberia', value: 'LR' },
      { label: 'Libyscher arabischer Jamahiriya', value: 'LY' },
      { label: 'Liechtenstein', value: 'LI' },
      { label: 'Litauen', value: 'LT' },
      { label: 'Luxemburg', value: 'LU' },
      { label: 'Macao', value: 'MO' },
      {
        label: 'Mazedonien, die ehemalige jugoslawische Republik',
        value: 'MK',
      },
      { label: 'Madagaskar', value: 'MG' },
      { label: 'Malawi', value: 'MW' },
      { label: 'Malaysia', value: 'MY' },
      { label: 'Malediven', value: 'MV' },
      { label: 'Mali', value: 'ML' },
      { label: 'Malta', value: 'MT' },
      { label: 'Marshallinseln', value: 'MH' },
      { label: 'Martinique', value: 'MQ' },
      { label: 'Mauretanien', value: 'MR' },
      { label: 'Mauritius', value: 'MU' },
      { label: 'Mayotte', value: 'YT' },
      { label: 'Mexiko', value: 'MX' },
      { label: 'Mikronesien, Föderierte Staaten von', value: 'FM' },
      { label: 'Moldawien, Republik', value: 'MD' },
      { label: 'Monaco', value: 'MC' },
      { label: 'Mongolei', value: 'MN' },
      { label: 'Montenegro', value: 'ME' },
      { label: 'Montserrat', value: 'MS' },
      { label: 'Marokko', value: 'MA' },
      { label: 'Mosambik', value: 'MZ' },
      { label: 'Myanmar', value: 'MM' },
      { label: 'Namibia', value: 'NA' },
      { label: 'Nauru', value: 'NR' },
      { label: 'Nepal', value: 'NP' },
      { label: 'Niederlande', value: 'NL' },
      { label: 'Niederländische Antillen', value: 'AN' },
      { label: 'Neu-Kaledonien', value: 'NC' },
      { label: 'Neuseeland', value: 'NZ' },
      { label: 'Nicaragua', value: 'NI' },
      { label: 'Niger', value: 'NE' },
      { label: 'Nigeria', value: 'NG' },
      { label: 'Niue', value: 'NU' },
      { label: 'Norfolkinsel', value: 'NF' },
      { label: 'Nördliche Marianneninseln', value: 'MP' },
      { label: 'Norwegen', value: 'NO' },
      { label: 'Oman', value: 'OM' },
      { label: 'Pakistan', value: 'PK' },
      { label: 'Palau', value: 'PW' },
      { label: 'Besetzte palästinensische Gebiete', value: 'PS' },
      { label: 'Panama', value: 'PA' },
      { label: 'Papua Neu-Guinea', value: 'PG' },
      { label: 'Paraguay', value: 'PY' },
      { label: 'Peru', value: 'PE' },
      { label: 'Philippinen', value: 'PH' },
      { label: 'Pitcairn', value: 'PN' },
      { label: 'Polen', value: 'PL' },
      { label: 'Portugal', value: 'PT' },
      { label: 'Puerto Rico', value: 'PR' },
      { label: 'Katar', value: 'QA' },
      { label: 'Wiedervereinigung', value: 'RE' },
      { label: 'Rumänien', value: 'RO' },
      { label: 'Russische Föderation', value: 'RU' },
      { label: 'Ruanda', value: 'RW' },
      { label: 'Heiliger Barthelemy', value: 'BL' },
      { label: 'Heilige Helena', value: 'SH' },
      { label: 'St. Kitts und Nevis', value: 'KN' },
      { label: 'St. Lucia', value: 'LC' },
      { label: 'Sankt Martin', value: 'MF' },
      { label: 'Saint Pierre und Miquelon', value: 'PM' },
      { label: 'St. Vincent und die Grenadinen', value: 'VC' },
      { label: 'Samoa', value: 'WS' },
      { label: 'San Marino', value: 'SM' },
      { label: 'Sao Tome und Principe', value: 'ST' },
      { label: 'Saudi-Arabien', value: 'SA' },
      { label: 'Senegal', value: 'SN' },
      { label: 'Serbien', value: 'RS' },
      { label: 'Serbien und Montenegro', value: 'CS' },
      { label: 'Seychellen', value: 'SC' },
      { label: 'Sierra Leone', value: 'SL' },
      { label: 'Singapur', value: 'SG' },
      { label: 'St. Martin', value: 'SX' },
      { label: 'Slowakei', value: 'SK' },
      { label: 'Slowenien', value: 'SI' },
      { label: 'Salomon-Inseln', value: 'SB' },
      { label: 'Somalia', value: 'SO' },
      { label: 'Südafrika', value: 'ZA' },
      { label: 'Süd-Georgien und die südlichen Sandwich-Inseln', value: 'GS' },
      { label: 'Südsudan', value: 'SS' },
      { label: 'Spanien', value: 'ES' },
      { label: 'Sri Lanka', value: 'LK' },
      { label: 'Sudan', value: 'SD' },
      { label: 'Suriname', value: 'SR' },
      { label: 'Spitzbergen und Jan Mayen', value: 'SJ' },
      { label: 'Swasiland', value: 'SZ' },
      { label: 'Schweden', value: 'SE' },
      { label: 'Syrische Arabische Republik', value: 'SY' },
      { label: 'Taiwan, Provinz Chinas', value: 'TW' },
      { label: 'Tadschikistan', value: 'TJ' },
      { label: 'Tansania, Vereinigte Republik', value: 'TZ' },
      { label: 'Thailand', value: 'TH' },
      { label: 'Timor-Leste', value: 'TL' },
      { label: 'Gehen', value: 'TG' },
      { label: 'Tokelau', value: 'TK' },
      { label: 'Tonga', value: 'TO' },
      { label: 'Trinidad und Tobago', value: 'TT' },
      { label: 'Tunesien', value: 'TN' },
      { label: 'Truthahn', value: 'TR' },
      { label: 'Turkmenistan', value: 'TM' },
      { label: 'Turks- und Caicosinseln', value: 'TC' },
      { label: 'Tuvalu', value: 'TV' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Ukraine', value: 'UA' },
      { label: 'Vereinigte Arabische Emirate', value: 'AE' },
      { label: 'Vereinigtes Königreich', value: 'GB' },
      {
        label: 'Kleinere abgelegene Inseln der Vereinigten Staaten',
        value: 'UM',
      },
      { label: 'Uruguay', value: 'UY' },
      { label: 'Usbekistan', value: 'UZ' },
      { label: 'Vanuatu', value: 'VU' },
      { label: 'Venezuela', value: 'VE' },
      { label: 'Vietnam', value: 'VN' },
      { label: 'Virgin Inseln, Britisch', value: 'VG' },
      { label: 'Jungferninseln, USA', value: 'VI' },
      { label: 'Wallis und Futuna', value: 'WF' },
      { label: 'Westsahara', value: 'EH' },
      { label: 'Jemen', value: 'YE' },
      { label: 'Sambia', value: 'ZM' },
      { label: 'Zimbabwe', value: 'ZW' },
    ],
  },
];

export const FILE_TYPES = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  BMP: 'image/bmp',
  GIF: 'image/gif',
  WEBP: 'image/webp',
  MP4: 'video/mp4',
  AVI: 'video/x-msvideo',
  WEBM: 'video/webm',
  OGV: 'video/ogg',
  MOV: 'video/quicktime',
  PDF: 'application/pdf',
  DOC: 'application/msword',
  DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
  PPT: 'application/vnd.ms-powerpoint',
  PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  TEXT: 'text/plain',
  ZIP: 'application/zip',
  XZIP: 'application/x-zip-compressed',
};

export const IMAGE_EXTENSIONS = [
  FILE_TYPES.JPEG,
  FILE_TYPES.PNG,
  FILE_TYPES.WEBP,
];
export const FILES_EXTENSIONS = [
  FILE_TYPES.PDF,
  FILE_TYPES.DOC,
  FILE_TYPES.XLS,
  FILE_TYPES.XLSX,
  FILE_TYPES.CSV,
  FILE_TYPES.PPT,
  FILE_TYPES.PPTX,
  FILE_TYPES.TEXT,
];

export const PDF_FILE_EXTENSIONS = [
  FILE_TYPES.PDF,
  FILE_TYPES.JPEG,
  FILE_TYPES.PNG,
];

export const STREET_TYPES_AUSTRALIA: string[] = [
  'Avenue',
  'Boulevard',
  'Drive',
  'Esplanade',
  'Freeway',
  'Gardens',
  'Grove',
  'Hill',
  'Lane',
  'Loop',
  'Mall',
  'Parade',
  'Parkway',
  'Place',
  'Plaza',
  'Promenade',
  'Road',
  'Square',
  'Street',
  'Terrace',
  'Track',
  'Way',
  'Circuit',
  'Court',
  'Crescent',
  'Highway',
];
export const STATES_AUSTRALIA = [
  { code: 'NSW', name: 'New South Wales' },
  { code: 'VIC', name: 'Victoria' },
  { code: 'QLD', name: 'Queensland' },
  { code: 'SA', name: 'South Australia' },
  { code: 'WA', name: 'Western Australia' },
  { code: 'TAS', name: 'Tasmania' },
  { code: 'ACT', name: 'Australian Capital Territory' },
  { code: 'NT', name: 'Northern Territory' },
];
