import { WBBox, WBButton, WBFlex } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { TaskUpload } from './TaskUpload';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bill } from '../PayBillCreateForm';
import { DATE_FORMATS, S3Upload } from '@admiin-com/ds-common';
import { useSelectedEntity } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import { transformDocumentAnalysisToBill } from './transformer';
import { DateTime } from 'luxon';
interface Props {
  onNext: (newBills: Bill[]) => void;
  onEnter: () => void;
  setBills: (bills: Bill[]) => void;
  bills: Bill[];
}

export const UploadBills: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [uploadedDocuments, setUploadedDocuments] = React.useState<S3Upload[]>(
    []
  );
  const { entity } = useSelectedEntity();
  const uploadedBills: Bill[] = uploadedDocuments.map((document) => ({
    document,
    to: entity,
    amount: 0,
    dueAt: '',
    issuedAt: DateTime.now().toFormat(DATE_FORMATS.BACKEND_DATE),
    lineItems: [],
    reference: '',
    checked: true,
    documentSize: document.size,
    documentName: document.name,
  }));
  const handleUploaded = () => {
    const newBills = props.bills.concat(uploadedBills);
    props.onNext(newBills);
  };
  return (
    <Container>
      <UploadContainer>
        <TaskUpload
          // onUploadStarted={() => props.setBills([])}
          bills={props.bills}
          setBills={props.setBills}
          onChange={(documents) => {
            setUploadedDocuments(documents);
          }}
          onAdd={(documentAnalysis) => {
            props.setBills(
              props.bills.concat(
                documentAnalysis.map((documentAnalysisRecord) => ({
                  ...transformDocumentAnalysisToBill(documentAnalysisRecord),
                  to: entity,
                }))
              )
            );
          }}
        />
      </UploadContainer>
      <ButtonContainer>
        <WBButton
          variant="outlined"
          onClick={props.onEnter}
          sx={{ mt: 1, mr: 1 }}
          fullWidth
          type="button"
        >
          {t('enterManually', { ns: 'taskbox' })}
        </WBButton>
        <WBButton
          onClick={handleUploaded}
          sx={{ mt: 1, mr: 1 }}
          disabled={props.bills.length === 0 && uploadedBills.length === 0}
          fullWidth
          type="button"
        >
          {t('next', { ns: 'taskbox' })}
        </WBButton>
      </ButtonContainer>
    </Container>
  );
};
const ButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '50%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
  },
}));
const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'end',
  padding: theme.spacing(0),
  margin: theme.spacing(0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
    width: '100%',
    innerWidth: '100%',
  },
}));

const UploadContainer = styled(WBBox)(({ theme }) => ({
  background: theme.palette.background.default,
  flex: 1,
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
    width: '100%',
    innerWidth: '100%',
  },
}));
