import {
  PaymentGuest,
  PaymentStatus,
  TaskDirection,
  TaskGuest,
  TaskStatus,
} from '@admiin-com/ds-graphql';
import GuestLayout from './GuestLayout';
import { WBBox, WBContainer, WBFlex, WBIcon } from '@admiin-com/ds-web';
import PdfViewer from '../PdfViewer/PdfViewer';
import { styled } from '@mui/material';
import React, { useRef } from 'react';
import { useDocumentGuestUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import AirwallexPaymentMethod from '../AirwallexOnboarding/AirwallexPaymentMethod';
import { t } from 'i18next';
import OnboardingMessage from '../InvoiceCreateForm/OnboardingMessage';
import { useNavigate } from 'react-router-dom';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
  paymentGuest?: PaymentGuest;
  paymentId?: string;
}
export const InvoicePay = (props: Props) => {
  const pdfInstanceRef = useRef<any>(null);
  const { taskGuest, paymentGuest, paymentId } = props;
  const { documentUrl } = useDocumentGuestUrl(props.token);
  const [paid, setIsPaid] = React.useState<boolean | null>(null);
  const navigate = useNavigate();
  const submitInvoice = async () => {
    setIsPaid(true);
    console.log('SDK sending success: waiting for webhook resp');
  };
  React.useEffect(() => {
    if (taskGuest || paymentGuest) {
      if (
        taskGuest?.status === TaskStatus.COMPLETED ||
        paymentGuest?.status === PaymentStatus.SUCCEEDED
      ) {
        setIsPaid(true);
      } else {
        setIsPaid(false);
      }
    }
  }, [taskGuest, paymentGuest]);
  if (paid && taskGuest.automaticGenerateInvoice) {
    return (
      <GuestLayout taskGuest={props.taskGuest} token={props.token}>
        <OnboardingMessage
          lottiePath={'/tick.lottie'}
          title={t('invoicePaidTitle', { ns: 'taskbox' })}
          description={t('invoicePaidSubTitle', {
            ns: 'taskbox',
            entity: props.taskGuest.to?.name,
          })}
          onClose={() => {
            setIsPaid(null);
          }}
          onGetStarted={() => navigate('/sign-up')}
          buttonTitle="Create account for Admiin"
          closeButtonText="Close this screen"
        />
      </GuestLayout>
    );
  }

  return (
    <GuestLayout taskGuest={props.taskGuest} token={props.token}>
      <WBContainer
        maxWidth="xl"
        sx={{
          px: 4,
          pb: 4,
          width: '100%',
          height: '100%',
          overflow: 'auto',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <PdfContainer maxWidth="md">
          <PdfViewer ref={pdfInstanceRef} documentUrl={documentUrl} />
        </PdfContainer>

        <PayForm>
          <WBBox
            p={5}
            sx={{
              bgcolor: 'background.default',
            }}
            width={'100%'}
          >
            {taskGuest?.fromEntity &&
            (!paymentId || (paymentId && paymentGuest?.id)) &&
            paid !== null &&
            !paid ? (
              <AirwallexPaymentMethod
                isGuest={
                  taskGuest.direction === TaskDirection.SENDING ? true : false
                }
                entityId={taskGuest.entityId ?? ''}
                taskId={taskGuest.id ?? ''}
                intentId={paymentGuest?.paymentIntentId || ''}
                paymentId={paymentId}
                onSuccess={submitInvoice}
                onClose={() => console.log('CLOSED')}
              />
            ) : (
              ''
            )}
            {paid || paid === null ? (
              <WBBox
                sx={{
                  mt: 7,
                  borderRadius: '50px',
                  padding: 2,
                  paddingX: 6,
                  color: 'common.black',
                  bgcolor: 'success.main',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                <WBIcon
                  name="Checkmark"
                  color={'common.black'}
                  size={'small'}
                ></WBIcon>
                {t('invoicePaid', { ns: 'payment' })}
              </WBBox>
            ) : null}
          </WBBox>
        </PayForm>
      </WBContainer>
    </GuestLayout>
  );
};
const PayForm = styled(WBFlex)(({ theme }) => ({ flex: 1 }));

const PdfContainer = styled(WBContainer)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  flexDirection: 'row',
  display: 'flex',
  flex: 1,
  paddingTop: theme.spacing(5),
  height: `calc(100% - ${theme.spacing(2)})`,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1),
    minHeight: `calc(100% - ${theme.spacing(20)})`,
  },
}));
