import {
  useSnackbar,
  WBBox,
  WBButton,
  WBDialog,
  WBDialogContent,
  WBFlex,
  WBLink,
  WBTypography,
} from '@admiin-com/ds-web';
import { DialogActions, DialogTitle, styled } from '@mui/material';
import { t } from 'i18next';
import LoadSvgIcon from '../../../component/LoadSvgIcon/LoadSvgIcon';
import RefreshIcon from '../../../../assets/icons/refresh.svg';
import { useSelectedEntity } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import { DocumentAnalysis } from '@admiin-com/ds-graphql';
import ForwardBillsList from './ForwardBillsList';
import { useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

interface Props {
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  documentAnalysis: DocumentAnalysis[];
  onAdd: (documentAnalysis: DocumentAnalysis[]) => void;
}

export const ForwardBills = (props: Props) => {
  const { entity } = useSelectedEntity();
  const forwardEmail = entity?.ocrEmail;
  console.log(props.documentAnalysis);

  const lastUpdated = '24 Oct 2024, 11:30:23';
  const refreshButton = (
    <StyledButton onClick={props.onRefresh}>
      <LoadSvgIcon component={RefreshIcon} width={24} height={24} />
      <WBTypography color={'primary.main'}>
        {t('refresh', { ns: 'common' })}
      </WBTypography>
    </StyledButton>
  );

  const [checkedItems, setCheckedItems] = useState<DocumentAnalysis[]>([]);

  const handleAdd = () => {
    props.onAdd(checkedItems);
    props.onClose();
  };

  const [, copy] = useCopyToClipboard();

  const showSnackbar = useSnackbar();
  const handleCopy = async () => {
    await copy(forwardEmail ?? '');
    showSnackbar({
      message: 'Email copied to clipboard',
      severity: 'success' as any,
      horizontal: 'right',
      vertical: 'bottom',
    });
  };

  return (
    <WBDialog open={props.open} maxWidth="md" onClose={props.onClose} fullWidth>
      <DialogTitle>
        <TitleContainer>
          <WBBox>
            <WBTypography variant="h3" mb={0}>
              {t('forwardedBillsTitle', { ns: 'taskbox' })}
            </WBTypography>
            <WBTypography variant="body2" color={'text.secondary'}>
              {t('lastUpdated', { ns: 'taskbox', time: lastUpdated })}
            </WBTypography>
          </WBBox>
          {refreshButton}
        </TitleContainer>
        <InfoBox mt={2}>
          <StyledDescription variant="body1" mb={0}>
            {t('billforwardDesc1', { ns: 'taskbox' })}
            <WBLink onClick={handleCopy} sx={{ wordBreak: 'break-word' }}>
              {forwardEmail}
            </WBLink>{' '}
            &nbsp;
            {t('billforwardDesc2', { ns: 'taskbox' })}
          </StyledDescription>
        </InfoBox>
      </DialogTitle>
      <WBDialogContent>
        <WBTypography variant="h5" mb={0}>
          {t('forwardedBillsSingle', {
            ns: 'taskbox',
            count: props.documentAnalysis.length,
          })}
        </WBTypography>
        <ForwardBillsList
          documentAnalysis={props.documentAnalysis}
          setCheckedItems={setCheckedItems}
        />
      </WBDialogContent>
      <DialogActions>
        <ButtonContainer>
          <WBButton variant="outlined" onClick={props.onClose} fullWidth>
            {t('cancel', { ns: 'common' })}
          </WBButton>
          <WBButton
            variant="contained"
            fullWidth
            onClick={handleAdd}
            disabled={checkedItems.length === 0}
          >
            {t('add', { ns: 'common' })}
          </WBButton>
        </ButtonContainer>
      </DialogActions>
    </WBDialog>
  );
};
const ButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '50%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
  gap: theme.spacing(1),
}));

const TitleContainer = styled(WBFlex)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}));

const StyledDescription = styled(WBTypography)(({ theme }) => ({
  a: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'none',
  },
}));

const StyledButton = styled(WBFlex)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const InfoBox = styled(WBBox)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#f4f1fb' : '#404040',
  padding: theme.spacing(1, 2),
  border: `1px solid ${
    theme.palette.mode === 'light'
      ? theme.palette.primary.dark
      : theme.palette.primary.main
  }`,
  [theme.breakpoints.down('sm')]: {
    gap: 0,
  },
}));
