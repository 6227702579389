import {
  useSnackbar,
  WBButton,
  WBFlex,
  WBTextField,
  WBTypography,
} from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import SimpleDrawDlg from '../../components/SimpleDrawDlg/SimpleDrawDlg';
import { DialogContent, DialogTitle, InputAdornment } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import {
  AccountDirection,
  AdmiinAccount,
  amountTransfer as AMOUNT_TRANSFER,
  PaymentMethod,
  PaymentMethodStatus,
  PaymentMethodType,
  SubscriptionTier,
} from '@admiin-com/ds-graphql';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import BankAccountsList from '../../components/BankAccountsList/BankAccountsList';
import { Controller, useForm } from 'react-hook-form';
import { gql, useMutation } from '@apollo/client';
import AddPaymentMethodModal from '../../components/AddPaymentMethodModal/AddPaymentMethodModal';
import { FEE_TIERS } from '../../constants/config';

export interface WithdrawAccountModalProps {
  open: boolean;
  admiinAccount: AdmiinAccount;
  onClose: () => void;
  onSuccess: () => void;
}

export function WithdrawAccountModal({
  open,
  onClose,
  admiinAccount,
  onSuccess,
}: WithdrawAccountModalProps) {
  const { t } = useTranslation();
  const { entity, loading } = useSelectedEntity();
  const [selectedBankAccount, setSelectedBankAccount] =
    React.useState<PaymentMethod | null>(null);
  const [showNewBankAccountForm, setShowNewBankAccountForm] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const showSnackbar = useSnackbar();
  const [amountTransfer] = useMutation(gql(AMOUNT_TRANSFER));

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      amount: '',
    },
  });

  const fee = FEE_TIERS[entity?.subscriptionTier ?? SubscriptionTier.PRO]?.BANK;

  const withdrawLimit = useMemo(() => {
    // balance less tier bank fee
    return (
      ((admiinAccount?.currentBalance?.availableAmount || 0) - fee * 100) / 100
    );
  }, [admiinAccount?.currentBalance?.availableAmount, fee]);

  const amount = watch('amount');
  const [error, setError] = React.useState<string | null>(null);

  const receivingAccounts = loading
    ? null
    : entity?.paymentMethods?.items
    ? entity?.paymentMethods?.items
        ?.filter(
          (paymentMethod: null | PaymentMethod) =>
            paymentMethod &&
            paymentMethod?.paymentMethodType === PaymentMethodType.BANK &&
            paymentMethod.accountDirection === AccountDirection.DISBURSEMENT &&
            paymentMethod.status === PaymentMethodStatus.ACTIVE
        )
        .filter((method: PaymentMethod | null) => method)
    : [];

  useEffect(() => {
    if (receivingAccounts?.length === 0) {
      setShowNewBankAccountForm(true);
    }

    if (
      receivingAccounts &&
      receivingAccounts?.length > 0 &&
      !selectedBankAccount
    ) {
      setSelectedBankAccount(receivingAccounts[0]);
    }
  }, [receivingAccounts]);

  const handleAmountChange = (value: string) => {
    const numericValue = parseFloat(value);

    if (isNaN(numericValue) || numericValue < 0) {
      setError(t('invalidAmount', { ns: 'admiinAccount' }));
    } else if (numericValue > withdrawLimit) {
      setError(
        t('exceedsBalance', {
          ns: 'admiinAccount',
          balance: withdrawLimit?.toFixed(2),
        })
      );
    } else {
      setError(null);
    }

    setValue('amount', value);
  };

  const getTitle = () => {
    if (selectedBankAccount) {
      return t('withdrawAccount', { ns: 'admiinAccount' });
    }

    return t('withdrawAccount', { ns: 'admiinAccount' });
  };

  const getDescription = () => {
    if (selectedBankAccount) {
      return `${t('withdrawAccountEnterFundsDescription', {
        ns: 'admiinAccount',
      })}`;
    }

    if (showNewBankAccountForm) {
      return t('withdrawAccountAddBankAccountDescription', {
        ns: 'admiinAccount',
      });
    }

    return t('withdrawAccountDescription', { ns: 'admiinAccount' });
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      await amountTransfer({
        variables: {
          input: {
            entityId: entity?.id,
            amount: data.amount * 100,
          },
        },
      });
      setIsLoading(false);
      onSuccess();
      onClose();
    } catch (error: any) {
      showSnackbar({
        title: t('amountTransferError', { ns: 'admiinAccount' }),
        message: error?.message || '',
        severity: 'error',
        horizontal: 'center',
        vertical: 'bottom',
      });
      setIsLoading(false);
    }
  };

  return (
    <SimpleDrawDlg open={open} handleClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle variant="h3" fontWeight={'bold'} color="text.primary">
        {getTitle()}
        <WBTypography variant="body1" mt={1}>
          {getDescription()}
        </WBTypography>
      </DialogTitle>
      <DialogContent>
        {!showNewBankAccountForm &&
          receivingAccounts &&
          receivingAccounts.length > 0 && (
            <BankAccountsList
              bankAccounts={receivingAccounts}
              accountDirection={AccountDirection.DISBURSEMENT}
              selected={selectedBankAccount?.id}
              onSelect={(bank) => setSelectedBankAccount(bank)}
            />
          )}

        {!showNewBankAccountForm && selectedBankAccount && (
          <Controller
            name="amount"
            control={control}
            render={({ field }) => (
              <WBTextField
                {...field}
                placeholder={t('amountWithdrawPlaceholder', {
                  ns: 'admiinAccount',
                })}
                value={amount}
                onChange={(e) => handleAmountChange(e.target.value)}
                error={!!error}
                helperText={error || ''}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                sx={{
                  mt: 3,
                }}
              />
            )}
          />
        )}

        <AddPaymentMethodModal
          open={showNewBankAccountForm}
          type="ReceivingAccount"
          handleClose={() => {
            setShowNewBankAccountForm(false);
            onClose();
          }}
          onSuccess={(e: any) => {
            console.log('Success : ', e);
            receivingAccounts?.concat(e);
          }}
          goBack={() => setShowNewBankAccountForm(false)}
        />

        <WBTypography variant="body1" mt={1}>
          {t('withdrawAccountEnterFundsDescription2', {
            ns: 'admiinAccount',
            fee: fee * 100,
          })}
        </WBTypography>

        <WBFlex flexDirection="column" justifyContent="center">
          <WBButton
            onClick={handleSubmit(onSubmit)}
            disabled={!selectedBankAccount || !!error}
            sx={{
              minWidth: '200px',
              mt: 3,
            }}
            loading={isLoading}
          >
            {t(!selectedBankAccount ? 'next' : 'transfer', {
              ns: 'admiinAccount',
            })}
          </WBButton>

          <WBButton
            variant="outlined"
            onClick={() => setShowNewBankAccountForm(true)}
            sx={{
              minWidth: '200px',
              mt: 1,
            }}
          >
            {t('addBankAccount', { ns: 'admiinAccount' })}
          </WBButton>
        </WBFlex>
      </DialogContent>
    </SimpleDrawDlg>
  );
}

export default WithdrawAccountModal;
