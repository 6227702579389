import { styled } from '@mui/material';
import React from 'react';
import { WBContainer, WBDialogContent, WBFlex } from '@admiin-com/ds-web';
import { Dialog as MUIDialog } from '@mui/material';
import { PageType } from './type';
import { InvoiceCreateFormContainer } from '../InvoiceCreateForm';
import { PayBill } from '../PayBill';
import ESignature from '../ESignature';
import TaskCreateHeader from './Header';

const StyledDialog = styled(MUIDialog)({
  '& .MuiDialogContent-root': {
    width: '100%',
  },
  '& .MuiPaper-root': {
    overflow: 'hidden',
  },
});
const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

interface Props {
  open: boolean;
  onClose: () => void;
  page: PageType;
  setPage: (page: PageType) => void;
  isEditing: boolean;
}

export function TaskCreateModal({
  onClose,
  page,
  setPage,
  open,
  isEditing,
}: Props) {
  const content = React.useMemo(() => {
    switch (page) {
      case 'INVOICE&QUOTES':
        return <InvoiceCreateFormContainer />;
      case 'PAY_BILL':
        return <PayBill />;
      case 'E_SIGNATURE':
        return <ESignature />;
    }
  }, [page]);
  return (
    <StyledDialog
      fullScreen
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="body"
    >
      <StyledWBFlex>
        <TaskCreateHeader
          open={open}
          onClose={onClose}
          isEditing={isEditing}
          setPage={setPage}
          page={page}
        />
        <WBDialogContent dividers>
          <ContentContainer
            maxWidth="xl"
            sx={{ height: '100%', px: [1, 2], py: [1, 3] }}
          >
            {content}
          </ContentContainer>
        </WBDialogContent>
      </StyledWBFlex>
    </StyledDialog>
  );
}

const ContentContainer = styled(WBContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}));
export default TaskCreateModal;
