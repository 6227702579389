import { REGEX } from '@admiin-com/ds-common';
import {
  AccountDirection,
  createAirwallexBeneficiary as CREATE_BENEFICIARY,
  CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID,
  PaymentMethod,
} from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBButton,
  WBFlex,
  WBForm,
  WBTextField,
} from '@admiin-com/ds-web';
import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import DirectDebitForm from '../DirectDebitForm/DirectDebitForm';
import ErrorHandler from '../ErrorHandler/ErrorHandler';

interface HostedFieldsFormProps {
  accountDirection?: AccountDirection;
  hasAgreement?: boolean;
  entityId?: string;
  onSuccess: (paymentMethod: PaymentMethod) => void;
  goBack?: () => void;
}

type BankAccountForm = {
  accountName: string;
  accountType: 'savings' | 'checking';
  accountNumber: string;
  routingNumber: string;
  holderType: 'personal' | 'business';
  country: 'AUS' | 'USA';
  paymentCurrency: 'AUD' | 'USD';
  currency: 'AUD' | 'USD';
};

export const BankForm: React.FC<HostedFieldsFormProps> = ({
  onSuccess,
  entityId: entityIdProps,
  hasAgreement,
  accountDirection,
  goBack,
}) => {
  console.log('goBack: ', goBack);
  const [viewMode] = React.useState<'BankForm' | 'AgreementForm'>(
    'BankForm' as const
  );
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<any>({});

  const { data: selectedEntityIdData } = useQuery(gql(GET_SELECTED_ENTITY_ID));
  const entityId =
    entityIdProps ?? (selectedEntityIdData?.selectedEntityId || '');
  const { entity, refetch } = useSelectedEntity();
  const inputs = React.useMemo(
    () => ({
      accountName: {
        label: t('accountName', { ns: 'settings' }),
        name: 'accountName' as const,
        type: 'text',
        placeholder: t('accountNamePlaceholder', { ns: 'settings' }),
        defaultValue: '',
        rules: { required: t('accountNameRequired', { ns: 'validation' }) },
      },
      routingNumber: {
        label: t('routingNumber', { ns: 'settings' }),
        name: 'routingNumber' as const,
        type: 'text',
        placeholder: t('routingNumberPlaceholder', { ns: 'settings' }),
        defaultValue: '',
        rules: {
          required: t('routingNumberRequired', { ns: 'validation' }),
          minLength: {
            value: 6,
            message: t('routingNumberLimit', { ns: 'validation' }),
          },
          maxLength: {
            value: 6,
            message: t('routingNumberLimit', { ns: 'validation' }),
          },
          pattern: {
            value: REGEX.NUMBER, // Regex pattern to ensure only digits are entered
            message: t('routingNumberInvalidFormat', { ns: 'validation' }), // Assuming a translation key for the error message
          },
        },
      },
      accountNumber: {
        label: t('accountNumber', { ns: 'settings' }),
        name: 'accountNumber' as const,
        type: 'accountNumber',
        defaultValue: '',
        placeholder: t('accountNumberPlaceholder', { ns: 'settings' }),
        rules: {
          required: t('accountNumberRequired', { ns: 'validation' }),
          minLength: {
            value: 6,
            message: t('accountNumberTooShort', { ns: 'validation' }), // Assuming a translation key for the error message
          },
          maxLength: {
            value: 10,
            message: t('accountNumberTooLong', { ns: 'validation' }), // Assuming a translation key for the error message
          },
          pattern: {
            value: REGEX.NUMBER, // Regex pattern to ensure only digits are entered
            message: t('accountNumberInvalidFormat', { ns: 'validation' }), // Assuming a translation key for the error message
          },
        },
      },
    }),
    [t]
  );

  const methods = useForm<BankAccountForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = methods;

  const [createBeneficiary] = useMutation(gql(CREATE_BENEFICIARY));

  const onSubmit = async (data: BankAccountForm) => {
    setError({});

    if (accountDirection === AccountDirection.DISBURSEMENT) {
      try {
        setIsSubmitting(true);
        const createBeneficiaryData = await createBeneficiary({
          variables: {
            input: {
              entityId,
              ...data,
            },
          },
        });
        console.log(createBeneficiaryData);
        setIsSubmitting(false);
        onSuccess(createBeneficiaryData.data.createAirwallexBeneficiary);
        refetch();
      } catch (err) {
        console.log(err);
        setIsSubmitting(false);
        //TODO: better error messaging
        setError({
          message: 'There was an error adding your bank account.',
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return viewMode === 'BankForm' ? (
    <WBForm onSubmit={handleSubmit(onSubmit)} id="add-payment-form" mt={3}>
      <WBBox>
        <Controller
          control={control}
          name="accountName"
          rules={inputs.accountName.rules}
          defaultValue={inputs.accountName.defaultValue}
          render={({ field }) => (
            <WBTextField
              {...field}
              label={t('accountName', { ns: 'settings' })}
              placeholder={inputs.accountName.placeholder}
              error={!!errors?.accountName}
              helperText={errors?.accountName?.message || ''}
            />
          )}
        />
      </WBBox>
      <WBFlex mt={3}>
        <WBBox flex={1} mr={3}>
          <Controller
            control={control}
            name="routingNumber"
            rules={inputs.routingNumber.rules}
            defaultValue={inputs.routingNumber.defaultValue}
            render={({ field }) => (
              <WBTextField
                {...field}
                label={t('routingNumber', { ns: 'settings' })}
                placeholder={inputs.routingNumber.placeholder}
                error={!!errors?.routingNumber}
                helperText={errors?.routingNumber?.message || ''}
              />
            )}
          />
        </WBBox>
        <WBBox flex={1}>
          <Controller
            control={control}
            name="accountNumber"
            rules={inputs.accountNumber.rules}
            defaultValue={inputs.accountNumber.defaultValue}
            render={({ field }) => (
              <WBTextField
                {...field}
                label={t('accountNumber', { ns: 'settings' })}
                placeholder={inputs.accountNumber.placeholder}
                error={!!errors?.accountNumber}
                helperText={errors?.accountNumber?.message || ''}
              />
            )}
          />
        </WBBox>
      </WBFlex>

      <WBFlex sx={{ mt: 7 }}>
        {goBack && (
          <WBButton
            variant="outlined"
            sx={{
              mr: 2,
              flex: 1,
            }}
            onClick={goBack}
            type="button"
          >
            {t('goBack', { ns: 'common' })}
          </WBButton>
        )}
        <WBButton
          type="submit"
          loading={isSubmitting}
          sx={{
            flex: 3,
          }}
        >
          {hasAgreement && accountDirection === AccountDirection.PAYMENT
            ? t('continue', { ns: 'common' })
            : t('addBankAccount', { ns: 'settings' })}
        </WBButton>
      </WBFlex>

      <ErrorHandler errorMessage={error?.message} />
    </WBForm>
  ) : (
    <DirectDebitForm entity={entity} onSuccess={onSuccess} />
  );
};
