import { WBBox, WBFlex, WBSwitch, WBTypography } from '@admiin-com/ds-web';
import { t } from 'i18next';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BillPayForm } from '../../PayBill/Review/BillPayForm';

export const SignaturePayForm = () => {
  const [showForm, setShowForm] = React.useState(false);
  const { setValue } = useFormContext();

  React.useEffect(() => {
    if (!showForm) setValue('futureTaskInput', undefined);
  }, [showForm]);

  return (
    <WBBox>
      <WBFlex mt={5} alignItems={'center'} justifyContent={'space-between'}>
        <WBBox>
          <WBTypography variant="h5">
            {t('signaturePayTitle', { ns: 'taskbox' })}
          </WBTypography>
          <WBTypography color={'text.secondary'}>
            {t('signaturePaySubTitle', { ns: 'taskbox' })}
          </WBTypography>
        </WBBox>
        <WBSwitch
          label=""
          inputProps={{ 'aria-label': 'controlled' }}
          checked={showForm}
          onChange={(e) => {
            setShowForm(e.target.checked);
          }}
        />
      </WBFlex>
      {showForm && <BillPayForm />}
    </WBBox>
  );
};
