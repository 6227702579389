import { useTheme, styled } from '@mui/material';
import React from 'react';
import { WBContainer, WBFlex } from '@admiin-com/ds-web';
import { PageType } from './type';
import { InvoiceCreateFormContainer } from '../InvoiceCreateForm';
import { PayBill } from '../PayBill';
import ESignature from '../ESignature';
import TaskCreateHeader from './Header';
const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));
interface Props {
  open: boolean;
  onClose: () => void;
  page: PageType;
  setPage: (page: PageType) => void;
  isEditing: boolean;
}
export function TaskCreateModal({
  onClose,
  page,
  setPage,
  open,
  isEditing,
}: Props) {
  const theme = useTheme();
  const content = React.useMemo(() => {
    switch (page) {
      case 'INVOICE&QUOTES':
        return <InvoiceCreateFormContainer />;
      case 'PAY_BILL':
        return <PayBill />;
      case 'E_SIGNATURE':
        return <ESignature />;
    }
  }, [page]);
  return (
    open && (
      <WBFlex
        position="fixed"
        top={0}
        left={0}
        right={0}
        role="dialog"
        bottom={0}
        sx={{ zIndex: 1200 }}
      >
        <StyledWBFlex>
          <TaskCreateHeader
            open={open}
            onClose={onClose}
            isEditing={isEditing}
            setPage={setPage}
            page={page}
          />
          <ContentContainer
            maxWidth="xl"
            sx={{
              height: '100%',
              px: [1, 2],
              py: [1, 3],
              overflow: 'auto',
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            {content}
          </ContentContainer>
        </StyledWBFlex>
      </WBFlex>
    )
  );
}
const ContentContainer = styled(WBContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0),
  },
}));
export default TaskCreateModal;
