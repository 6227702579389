import { WBBox, WBTypography } from '@admiin-com/ds-web';
import { TabContext, TabPanel } from '@mui/lab';
import { DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SimpleDrawDlg from '../SimpleDrawDlg/SimpleDrawDlg';
import { BankForm } from '../HostedFields/BankForm';
import {
  AccountDirection,
  PaymentMethod,
  PaymentMethodStatus,
} from '@admiin-com/ds-graphql';
import PageSelector from '../PageSelector/PageSelector';
import PayIdForm from '../PayIdForm/PayIdForm';
import AirwallexPaymentMethod from '../AirwallexOnboarding/AirwallexPaymentMethod';
import {
  useCurrentEntityId,
  useSelectedEntity,
} from '../../hooks/useSelectedEntity/useSelectedEntity';
import ConnectedAccount from '../ConnectedAccountForm/ConnectedAccount';
import { VerificationDlg } from '../../pages/VerificationDlg/VerificationDlg';
import { maskCreditCardNumberSimple } from '@admiin-com/ds-common';
import { v4 as uuidv4 } from 'uuid';

export interface AddPaymentMethodModalProps {
  open: boolean;
  handleClose: () => void;
  type?: ModalType;
  onSuccess?: (paymentMethod?: PaymentMethod) => void;
  goBack?: () => void;
}

type ModalType =
  | 'All'
  | 'CC'
  | 'BankAccount'
  | 'ReceivingAccount'
  | 'PAY_TO_VERIFY'
  | 'PAY_TO'
  | 'PAY_ID'
  | 'ConnectedAccount';

export function AddPaymentMethodModal({
  open,
  onSuccess,
  type = 'CC',
  handleClose,
  goBack,
}: AddPaymentMethodModalProps) {
  const entityId = useCurrentEntityId();
  const [modalType, setModalType] = React.useState<ModalType>(type);
  React.useEffect(() => {
    setModalType(type);
  }, [type]);
  const { t } = useTranslation();
  const { addPaymentMethodToCache } = useSelectedEntity();

  const [verificationModal, setVerificationModal] =
    React.useState<boolean>(false);
  // const [agreements, setAgreements] = React.useState<PayToAgreement[]>([]);

  const handleSuccess = (el?: any) => {
    const cardData: any = el?.[el.type] || {};
    if (modalType === 'CC') {
      if (el) {
        const paymentData = {
          id: el.id,
          paymentMethodType: 'CARD',
          accountDirection: 'PAYMENT',
          type: cardData?.brand || '',
          fullName: cardData?.name || '',
          number: `${cardData?.bin}XXXX${cardData?.last4}`,
          expMonth: cardData?.expiry_month,
          expYear: cardData?.expiry_year,
          status: PaymentMethodStatus.ACTIVE,
          createdAt: new Date().toISOString(),
          label: maskCreditCardNumberSimple(
            `${cardData?.bin}XXXX${cardData?.last4}`
          ),
          __typename: 'PaymentMethod' as const,
        };
        addPaymentMethodToCache(paymentData as PaymentMethod);
        onSuccess && onSuccess(paymentData as PaymentMethod);
      }
    } else {
      onSuccess && onSuccess(cardData);
    }
    handleClose();
  };

  const getTitleTranslation = (modalType: ModalType) => {
    switch (modalType) {
      case 'CC':
        return t('addCreditCard', { ns: 'settings' });
      case 'ConnectedAccount':
        return t('addConnectedAccount', { ns: 'settings' });
      case 'BankAccount':
        return t('addBankAccount', { ns: 'settings' });
      case 'ReceivingAccount':
        return t('addReceivingAccount', { ns: 'settings' });
      default:
        return t('addPaymentMethod', { ns: 'settings' });
    }
  };

  const getDescriptionTranslation = (modalType: ModalType) => {
    switch (modalType) {
      case 'CC':
        return t('addCardAccountForPayments', { ns: 'settings' });
      case 'ConnectedAccount':
        return t('addConnectedAccountDescription', { ns: 'settings' });
      case 'BankAccount':
        return t('addBankAccountForPayments', { ns: 'settings' });
      case 'ReceivingAccount':
        return t('addReceivingAccountDescription', { ns: 'settings' });
      default:
        return '';
    }
  };

  const { entity: currentEntity } = useSelectedEntity();

  return (
    <>
      <SimpleDrawDlg
        open={open}
        handleClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <PageSelector current={modalType}>
          <PageSelector.Page value="PAY_ID">
            <PayIdForm
              onSubmitted={() => {
                handleClose();
              }}
            />
          </PageSelector.Page>
          <PageSelector.Page>
            <DialogTitle variant="h3" fontWeight={'bold'} color="text.primary">
              {getTitleTranslation(modalType)}
              <WBTypography variant="body1" mt={1}>
                {getDescriptionTranslation(modalType)}
              </WBTypography>
            </DialogTitle>
            {(modalType === 'CC' ||
              modalType === 'BankAccount' ||
              modalType === 'ReceivingAccount' ||
              modalType === 'PAY_TO_VERIFY' ||
              modalType === 'ConnectedAccount') && (
              <DialogContent>
                <TabContext value={modalType}>
                  <WBBox
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                  ></WBBox>
                  <TabPanel value="BankAccount" sx={{ padding: 1 }}>
                    <BankForm
                      accountDirection={AccountDirection.PAYMENT}
                      hasAgreement
                      entityId={entityId}
                      onSuccess={handleSuccess}
                    />
                  </TabPanel>
                  <TabPanel value="ConnectedAccount" sx={{ padding: 1 }}>
                    <ConnectedAccount
                      //onSuccess={handleSuccess}
                      onSubmitted={() => {
                        handleClose();
                      }}
                      onOpenVerificationDialog={() => {
                        handleClose();
                        setVerificationModal(true);
                      }}
                    />
                  </TabPanel>

                  <TabPanel value="ReceivingAccount" sx={{ padding: 1 }}>
                    <BankForm
                      entityId={entityId}
                      accountDirection={AccountDirection.DISBURSEMENT}
                      onSuccess={handleSuccess}
                      goBack={goBack}
                    />
                  </TabPanel>
                  <TabPanel value="CC" sx={{ padding: 0, mt: 3 }}>
                    <AirwallexPaymentMethod
                      entityId={entityId}
                      isGuest={false}
                      onSuccess={handleSuccess}
                      onClose={handleClose}
                    />
                  </TabPanel>
                </TabContext>
              </DialogContent>
            )}
          </PageSelector.Page>
        </PageSelector>
      </SimpleDrawDlg>
      {currentEntity && (
        <VerificationDlg
          entity={currentEntity}
          onSuccess={() => {
            console.log('success');
          }}
          open={verificationModal}
          onClose={() => setVerificationModal(false)}
        />
      )}
    </>
  );
}

export default AddPaymentMethodModal;
