import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { createElement } from '@airwallex/components-sdk';
import type { ElementTypes } from '@airwallex/components-sdk';
import { useTranslation } from 'react-i18next';
import { Entity } from '@admiin-com/ds-graphql';
import { useSnackbar } from '@admiin-com/ds-web';

type Handler = (event?: any) => void;

const AirwallexKYCRFI: React.FC<{
  onSuccess: (entity: Entity | null | undefined) => void;
  onClose: () => void;
}> = ({ onSuccess, onClose }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();

  const handleCancel: Handler = () => {
    //window.alert('Cancel');

    if (onClose) {
      onClose();
    }
  };

  const handleSuccess: Handler = () => {
    onSuccess(null);
  };

  const handleError: Handler = (e) => {
    console.log('Airwallex: Error occured.', e);
    showSnackbar({
      message: t('kycError', { ns: 'airwallex' }),
      severity: 'error',
      horizontal: 'right',
      vertical: 'bottom',
    });
  };

  const handleReady: Handler = (event) => {
    if (event.type === 'consent') {
      setLoading(false);
    } else {
      if (event.kycStatus === 'SUCCESS') {
        console.log('Airwallex: Successfully done.');
        showSnackbar({
          message: t('kycSuccess', { ns: 'airwallex' }),
        });
        onSuccess(null);
      } else {
        console.log('Verification process has started!');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    let element: ElementTypes['kycRfi'] | null;
    const mountKycElement = async () => {
      // create kyc-rfi element
      element = await createElement('kycRfi', {
        hideHeader: true,
        hideNav: true,
        theme: {
          // TODO : Contact your Account Manager for details.
        },
      });

      // append to DOM
      await element?.mount('kyc-rfi');

      // subscribe element events
      element?.on('ready', (event: any) => {
        handleReady(event);
      });
      element?.on('cancel', (event: any) => {
        handleCancel(event);
      });
      element?.on('success', () => {
        handleSuccess();
      });
      element?.on('error', (event: any) => {
        handleError(event);
      });
    };
    mountKycElement();

    return () => element?.destroy();
  }, []);

  return (
    <>
      <div
        style={{ height: '80vh', display: loading ? 'none' : 'block' }}
        id="kyc-rfi"
      />
      {loading && (
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ textAlign: 'center' }}>
            {t('kycInit', { ns: 'common' })}
          </Typography>
          <LinearProgress color="primary" />
        </Stack>
      )}
    </>
  );
};

export default AirwallexKYCRFI;
