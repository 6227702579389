import { WBBox } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { Bill } from '../../../PayBillCreateForm';
import { ScanItemBillDetail } from './ScanItemBillDetail';
import React from 'react';
import { DocumentAnalysisLineItemInput } from '@admiin-com/ds-graphql';
import { ScanItemMobileLoading } from './ScanItemMobileLoading';
import { isBillLoading } from 'apps/react-app/src/app/helpers/tasks';
import { LineItemsMobile } from './LineItemsMobile';

interface ScanItemCardProps {
  bill: any;
  index: number;
  opened: number;
  setOpened: any;
  updateBill: (newBill: Bill) => void;
}
export const ScanItemCard = (props: ScanItemCardProps) => {
  const isLoading = isBillLoading(props.bill.documentAnalysis);
  if (isLoading) return <ScanItemMobileLoading />;
  const getAmountFromLineItems = (
    lineItems: DocumentAnalysisLineItemInput[]
  ) => {
    const amount = lineItems.reduce(
      (acc: number, item: DocumentAnalysisLineItemInput) =>
        acc + (item.price ?? 0),
      0
    );
    if (amount === 0)
      return (props.bill.documentAnalysis?.task?.amount || 0) * 100 || 0;
    return amount;
  };

  return (
    <Container>
      <ScanItemBillDetail
        bill={props.bill}
        index={props.index}
        opened={props.opened}
        setOpened={props.setOpened}
        updateBill={props.updateBill}
      />

      <LineItemsMobile
        isLoading={isBillLoading(props.bill.documentAnalysis)}
        index={props.index}
        opened={props.opened}
        setOpened={props.setOpened}
        lineItems={props.bill.lineItems || []}
        changeLineItems={(lineItems: DocumentAnalysisLineItemInput[]) => {
          props.updateBill({
            ...props.bill,
            lineItems,
            amount: getAmountFromLineItems(lineItems),
          });
        }}
      />
    </Container>
  );
};

const Container = styled(WBBox)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(0),
}));
