import { useEffect, useState } from 'react';
import Handlebars from 'handlebars';
import { generateInvoiceContent } from '@admiin-com/ds-common'; // Adjust the path as necessary
import { WBBox } from '@admiin-com/ds-web';
import { BillCreateFormData } from '../../pages/TaskCreation/TaskCreation';
import { t } from 'i18next';

// 'isEven' helper for handlebars
Handlebars.registerHelper('isEven', function (index) {
  return index % 2 === 0;
});

interface Props {
  values: BillCreateFormData;
  brandColor?: string;
}

const InvoiceRenderer = ({ values, brandColor }: Props) => {
  const [compiledHtml, setCompiledHtml] = useState('');

  useEffect(() => {
    generateInvoiceContent({
      ...values,
      lineItems: values.lineItems.map((lineitem) => ({
        ...lineitem,
        taxType: t(lineitem.taxType ?? '', { ns: 'taskbox' }),
      })),
      brandColor,
    }).then((html) => {
      setCompiledHtml(html);
    });
  }, [values, brandColor]);

  return (
    <WBBox
      sx={{
        backgroundColor: 'white',
        color: 'black',
        width: '100%',
      }}
    >
      <WBBox
        sx={{
          p: 2,
          backgroundColor: 'white',
          width: '100%',
          // Add these styles to create style isolation
          '& > div': {
            isolation: 'isolate', // Creates a new stacking context
          },
          // Scope all styles to only affect elements within the invoice
          '& .invoice-root *': {
            all: 'revert', // Reset all properties to their inherited values
          },
        }}
        dangerouslySetInnerHTML={{ __html: compiledHtml }}
      />
    </WBBox>
  );
};

export default InvoiceRenderer;
