import { gql, useQuery } from '@apollo/client';
import { useCurrentEntityId } from '../../../hooks/useSelectedEntity/useSelectedEntity';
import {
  DocumentAnalysis,
  DocumentAnalysisStatus,
} from '@admiin-com/ds-graphql';
import { ForwardBills } from './ForwardBills';

interface Props {
  onClose: () => void;
  open: boolean;
  onAdd: (documentAnalysis: DocumentAnalysis[]) => void;
  source: 'EMAIL' | 'XERO';
}
export const documentAnalysisByEntityStatus = /* GraphQL */ `
  query DocumentAnalysisByEntityStatus(
    $entityId: ID!
    $status: DocumentAnalysisStatus
  ) {
    documentAnalysisByEntityStatus(entityId: $entityId, status: $status) {
      items {
        id
        entityId
        fileKey
        documents {
          identityId
          key
          level
          type
        }
        status
        expenseStatus
        queryStatus
        matchedBiller {
          billerCode
          billerCategory
          shortName
          longName
          type
          industryCode
          industryCodeDescription
          createdAt
          updatedAt
        }
        matchedContactId
        matchedContact {
          id
          entityId
          xeroTenantId
          entityType
          firstName
          lastName
          email
          mobile
          phone
          taxNumber
          companyNumber
          name
          legalName
          searchName
          status
          createdAt
          updatedAt
          contactType
          bank {
            id
            accountName
            bankName
            accountNumber
            routingNumber
            holderType
            accountType
            country
          }
          bpay {
            billerCode
            referenceNumber
          }
          bulkUploadFileKey
          owner
          createdBy
          beneficiaryBankId
          beneficiaryBpayId
          address {
            addressId
            placeId
            contactName
            contactNumber
            addressFull
            address1
            address2
            unitNumber
            streetNumber
            streetName
            streetAddress
            streetType
            city
            suburb
            country
            countryCode
            state
            stateCode
            postalCode
          }
        }
        matchedEntityId
        matchedEntity {
          id
          type
          taxNumber
          companyNumber
          billerCode
          name
          legalName
          searchName
          address {
            addressId
            placeId
            contactName
            contactNumber
            addressFull
            address1
            address2
            unitNumber
            streetNumber
            streetName
            streetAddress
            streetType
            city
            suburb
            country
            countryCode
            state
            stateCode
            postalCode
          }
          logo {
            alt
            identityId
            key
            level
            type
          }
          brandColour
          website
          entityBeneficialOwners {
            items {
              entityId
              beneficialOwnerId
              beneficialOwner {
                id
                firstName
                lastName
                name
                providerEntityId
                verificationStatus
                verificationAttempt
                createdBy
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
              owner
            }
            nextToken
          }
          entityUsers {
            items {
              id
              entityId
              userId
              firmId
              email
              invitedEmail
              referredBy
              invitedEntityId
              firmEntityId
              firstName
              lastName
              role
              paymentsEnabled
              termsInvoiceAccepted
              termsOcrAccepted
              entitySearchName
              entity {
                id
                type
                taxNumber
                companyNumber
                billerCode
                name
                legalName
                searchName
                address {
                  addressId
                  placeId
                  contactName
                  contactNumber
                  addressFull
                  address1
                  address2
                  unitNumber
                  streetNumber
                  streetName
                  streetAddress
                  streetType
                  city
                  suburb
                  country
                  countryCode
                  state
                  stateCode
                  postalCode
                }
                logo {
                  alt
                  identityId
                  key
                  level
                  type
                }
                brandColour
                website
                entityBeneficialOwners {
                  nextToken
                }
                entityUsers {
                  nextToken
                }
                gstRegistered
                providerEntityId
                providerCompanyId
                providerBillUserCompanyId
                providerBankAccountId
                providerDigitalWalletId
                providerBpayCrn
                contact {
                  firstName
                  lastName
                  email
                  phone
                  role
                }
                paymentMethods {
                  nextToken
                }
                paymentMethodId
                paymentUserId
                disbursementMethodId
                receivingAccounts {
                  nextToken
                }
                ubosCreated
                numUbosCreated
                subCategory
                clientsStatus
                ocrEmail
                termsInvoiceAccepted
                termsOcrAccepted
                owner
                referredBy
                connectedAccount
                accountStatus
                customerId
                xeroSyncStatus
                xeroLastContactSyncAt
                xeroContactSyncStatus
                xeroInvoiceSyncStatus
                xeroLastInvoiceSyncAt
                xeroTenantId
                absorbFees
                subscriptionTier
                createdBy
                createdByEntityId
                createdAt
                updatedAt
              }
              searchName
              createdBy
              createdAt
              updatedAt
              status
            }
            nextToken
          }
          gstRegistered
          providerEntityId
          providerCompanyId
          providerBillUserCompanyId
          providerBankAccountId
          providerDigitalWalletId
          providerBpayCrn
          contact {
            firstName
            lastName
            email
            phone
            role
          }
          paymentMethods {
            items {
              id
              paymentMethodType
              type
              fullName
              number
              expMonth
              expYear
              accountName
              bankName
              accountNumber
              routingNumber
              holderType
              accountType
              status
              accountDirection
              expiresAt
              createdAt
              updatedAt
              consentId
              consentStatus
              card
              googlepay
              applepay
            }
            nextToken
          }
          paymentMethodId
          paymentUserId
          disbursementMethodId
          receivingAccounts {
            items {
              id
              paymentMethodType
              type
              fullName
              number
              expMonth
              expYear
              accountName
              bankName
              accountNumber
              routingNumber
              holderType
              accountType
              status
              accountDirection
              expiresAt
              createdAt
              updatedAt
              consentId
              consentStatus
              card
              googlepay
              applepay
            }
            nextToken
          }
          ubosCreated
          numUbosCreated
          subCategory
          clientsStatus
          ocrEmail
          termsInvoiceAccepted
          termsOcrAccepted
          owner
          referredBy
          connectedAccount
          accountStatus
          customerId
          xeroSyncStatus
          xeroLastContactSyncAt
          xeroContactSyncStatus
          xeroInvoiceSyncStatus
          xeroLastInvoiceSyncAt
          xeroTenantId
          absorbFees
          subscriptionTier
          createdBy
          createdByEntityId
          createdAt
          updatedAt
        }
        newContact {
          firstName
          lastName
          email
          mobile
          phone
          name
          taxNumber
          address {
            addressId
            placeId
            contactName
            contactNumber
            addressFull
            address1
            address2
            unitNumber
            streetNumber
            streetName
            streetAddress
            streetType
            city
            suburb
            country
            countryCode
            state
            stateCode
            postalCode
          }
        }
        task {
          reference
          dueAt
          issuedAt
          gstInclusive
          notes
          amount
          gstAmount
          taxAmount
          lineItems {
            description
            quantity
            unitPrice
            price
            productCode
            taxType
          }
          numberOfPayments
          paymentFrequency
          shippingAmount
        }
        bpay {
          billerCode
          referenceNumber
        }
        bank {
          accountNumber
          routingNumber
        }
        source
        fromEmail
        textContent
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const ForwardBillsContainer = (props: Props) => {
  const entityId = useCurrentEntityId();
  const { data, refetch } = useQuery(gql(documentAnalysisByEntityStatus), {
    variables: {
      entityId,
      status: DocumentAnalysisStatus.SCANNED,
    },
    skip: !props.open || !entityId,
  });

  const documentAnalysis =
    data?.documentAnalysisByEntityStatus?.items
      ?.filter((item: DocumentAnalysis) => item.source === props.source)
      .sort(
        (a: DocumentAnalysis, b: DocumentAnalysis) =>
          (b.createdAt ?? '').localeCompare(a.createdAt ?? '') // Reversed comparison order
      ) ?? [];
  return (
    <ForwardBills
      onRefresh={() =>
        refetch({
          fetchPolicy: 'no-cache',
        })
      }
      open={props.open}
      onClose={props.onClose}
      documentAnalysis={documentAnalysis}
      onAdd={props.onAdd}
    />
  );
};
