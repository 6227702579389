import {
  CreateTaskStatus,
  FromToType,
  PaymentFrequency,
  PaymentType,
  TaskDirection,
  TaskSettlementStatus,
  TaskType,
} from '@admiin-com/ds-graphql';
import { z } from 'zod';
// Create schemas for nested objects
const S3UploadSchema = z.object({
  key: z.string(),
  bucket: z.string(),
  region: z.string(),
});

const SignerSchema = z.object({
  userId: z.string(),
  order: z.number().optional(),
});

const OtherRecipientSchema = z.object({
  email: z.string().email(),
  name: z.string().optional(),
});
export const BankSchema = z.object({
  routingNumber: z.string().min(1, 'Routing number is required'),
  accountNumber: z.string().min(1, 'Account number is required'),
});

export const BpaySchema = z.object({
  billerCode: z.number().optional().nullable(),
  // .min(1, 'Biller code is required')
  // .refine(
  //   (val) => /^\d+$/.test(String(val)),
  //   'Biller code must contain only numbers'
  // )
  referenceNumber: z
    .string()
    .min(1, 'Reference number is required')
    .refine(
      (val) => /^\d{2,20}$/.test(val),
      'Reference number must be between 2 and 20 digits'
    ),
});

const LineItemSchema = z.object({
  description: z.string(),
  quantity: z.number(),
  unitPrice: z.number(),
  price: z.number(),
});
export const BillDraftSchema = z.object({
  from: z.object({}).passthrough().required(),
  dueAt: z.string().min(1, 'DueAt is required'),
});
export const BillSchema = z
  .object({
    from: z.object({}).passthrough().required(),
    to: z.object({}).passthrough().required(),
    reference: z.string().min(1, 'Invoice ID is required'),
    dueAt: z.string().min(1, 'DueAt is required'),
    bpay: BpaySchema.optional().nullable(),
    bank: z.any().optional().nullable(),
    amount: z.number().positive(),
    // bpay is optional, but if present must have both fields
  })
  .refine(
    (data) => {
      // Validate bank only if bpay is null or undefined
      if (!data.bpay || !data.bpay.billerCode) {
        return BankSchema.safeParse(data.bank).success; // Ensure bank exists and passes validation
      }
      return true; // Skip bank validation if bpay exists
    },
    {
      message: 'Bank information is required if Bpay is not provided.',
      path: ['bank'], // Points to the 'bank' field
    }
  );

// Main CreateTaskInput validation schema
export const CreateTaskSchema = z.object({
  // Required fields
  fromId: z.string().min(1, 'FromId is required'),
  fromType: z.nativeEnum(FromToType),
  type: z.nativeEnum(TaskType),
  direction: z.nativeEnum(TaskDirection),
  reference: z.string().min(1, 'Reference is required'),
  dueAt: z.string().min(1, 'DueAt is required'),

  // Optional fields
  documentAnalysisId: z.string().nullable().optional(),
  toId: z.string().nullable().optional(),
  toType: z.nativeEnum(FromToType).nullable().optional(),
  invoiceStatus: z.nativeEnum(FromToType).nullable().optional(),
  documents: z.array(S3UploadSchema.nullable()).nullable().optional(),
  numberOfPayments: z.number().nullable().optional(),
  paymentFrequency: z.nativeEnum(PaymentFrequency).nullable().optional(),
  paymentTypes: z
    .array(z.nativeEnum(PaymentType).nullable())
    .nullable()
    .optional(),
  bpayReferenceNumber: z.string().nullable().optional(),
  amount: z.number().nullable().optional(),
  taxAmount: z.number().nullable().optional(),
  gstAmount: z.number().nullable().optional(),
  notes: z.string().nullable().optional(),
  settlementStatus: z.nativeEnum(TaskSettlementStatus).nullable().optional(),
  signers: z.array(SignerSchema.nullable()).nullable().optional(),
  otherRecipients: z
    .array(OtherRecipientSchema.nullable())
    .nullable()
    .optional(),
  annotations: z.string().nullable().optional(),
  issuedAt: z.string().nullable().optional(),
  paymentAt: z.string().nullable().optional(),
  lodgementAt: z.string().nullable().optional(),
  status: z.nativeEnum(CreateTaskStatus).nullable().optional(),
  gstInclusive: z.boolean().nullable().optional(),
  lineItems: z.array(LineItemSchema.nullable()).nullable().optional(),
  sendSMS: z.boolean().nullable().optional(),
});
