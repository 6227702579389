import {
  createTask as CREATE_TASK,
  TaskDirection,
} from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';
import {
  tasksByEntityFrom as TASKS_BY_ENTITY_FROM,
  tasksByEntityTo as TASKS_BY_ENTITY_TO,
  tasksByFirmByIdContactId as TASKS_BY_FIRM_BY_ID_CONTACT_ID,
  tasksByEntityByIdContactId as TASKS_BY_ENTITY_BY_CONTACT_ID,
} from '@admiin-com/ds-graphql';
import {
  MutationTuple,
  OperationVariables,
  DefaultContext,
  ApolloCache,
} from '@apollo/client';
import useSelectedFirm from '../useSelectedFirm/useSelectedFirm';

export type UseMutationTuple = MutationTuple<
  any,
  OperationVariables,
  DefaultContext,
  ApolloCache<any>
>;

export function useCreateTask(): [
  UseMutationTuple[0],
  { error: UseMutationTuple[1]['error'] }
] {
  const { selectedFirm } = useSelectedFirm();
  const [createTask, { error }] = useMutation(gql(CREATE_TASK), {
    update: (cache, { data: { createTask } }) => {
      const variables = {
        entityId: createTask.fromId,
        status:
          createTask?.status === 'DRAFT' ? 'INCOMPLETE' : createTask?.status,
        limit: 20,
      };

      // Update tasksByEntityFrom if direction is SENDING
      if (createTask.direction === TaskDirection.SENDING) {
        const existingData: any = cache.readQuery({
          query: gql(TASKS_BY_ENTITY_FROM),
          variables,
        });

        if (existingData) {
          cache.writeQuery({
            query: gql(TASKS_BY_ENTITY_FROM),
            variables,
            data: {
              tasksByEntityFrom: {
                ...existingData.tasksByEntityFrom,
                items: [createTask, ...existingData.tasksByEntityFrom.items],
              },
            },
          });
        }
      }

      // Update tasksByEntityTo if direction is RECEIVING
      if (createTask.direction === TaskDirection.RECEIVING) {
        variables.entityId = createTask.toId;
        const existingData: any = cache.readQuery({
          query: gql(TASKS_BY_ENTITY_TO),
          variables,
        });

        if (existingData) {
          cache.writeQuery({
            query: gql(TASKS_BY_ENTITY_TO),
            variables,
            data: {
              tasksByEntityTo: {
                ...existingData.tasksByEntityTo,
                items: [createTask, ...existingData.tasksByEntityTo.items],
              },
            },
          });
        }
      }

      if (createTask.contactId) {
        const existingData: any = cache.readQuery({
          query: gql(TASKS_BY_ENTITY_BY_CONTACT_ID),
          variables: {
            contactId: createTask.contactId,
            entityIdBy: createTask.entityIdBy,
          },
        });
        if (existingData) {
          cache.writeQuery({
            query: gql(TASKS_BY_ENTITY_BY_CONTACT_ID),
            variables: {
              contactId: createTask.contactId,
              entityIdBy: createTask.entityIdBy,
            },
            data: {
              tasksByEntityByIdContactId: {
                ...existingData.tasksByEntityByIdContactId,
                items: [
                  createTask,
                  ...existingData.tasksByEntityByIdContactId.items,
                ],
              },
            },
          });
        }

        const existingTasksData: any = cache.readQuery({
          query: gql(TASKS_BY_FIRM_BY_ID_CONTACT_ID),
          variables: {
            contactId: createTask.entityId,
            entityId: createTask.entityIdBy,
            firmIdBy: selectedFirm?.id,
            limit: 20,
          },
        });
        console.log(
          'updating cache after creating task',
          createTask,
          existingTasksData,
          {
            contactId: createTask.entityId,
            entityId: createTask.entityIdBy,
            firmIdBy: selectedFirm?.id,
            limit: 20,
          }
        );
        if (existingData) {
          cache.writeQuery({
            query: gql(TASKS_BY_FIRM_BY_ID_CONTACT_ID),
            variables: {
              contactId: createTask.entityId,
              entityId: createTask.entityIdBy,
              firmIdBy: selectedFirm?.id,
              limit: 20,
            },
            data: {
              tasksByFirmByIdContactId: {
                ...(existingTasksData?.tasksByFirmByIdContactId ?? []),
                items: [
                  createTask,
                  ...(existingTasksData?.tasksByFirmByIdContactId?.items ?? []),
                ],
              },
            },
          });
        }
      }
    },
    awaitRefetchQueries: true,
  });

  return [createTask, { error }];
}
