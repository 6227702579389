import { ScanItemLineItems } from './ScanItemLineItems';
import React from 'react';
import { DocumentAnalysisLineItemInput } from '@admiin-com/ds-graphql';
import LineItemsCreateModal from '../../../BillLineItems/PaybillLineItemsCreateModal';
import { ScanItemMobileLoading } from './ScanItemMobileLoading';

interface ScanItemCardProps {
  index: number;
  opened: number;
  setOpened: any;
  isManaual?: boolean;
  lineItems: DocumentAnalysisLineItemInput[];
  isLoading?: boolean;
  changeLineItems: (lineItems: DocumentAnalysisLineItemInput[]) => void;
}
export const LineItemsMobile = (props: ScanItemCardProps) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleEditLineItem = (
    item: DocumentAnalysisLineItemInput | null,
    index: number
  ) => {
    setEditItemIndex(index);
    setEditItem(item);
    setModalOpen(true);
  };
  const [editItem, setEditItem] =
    React.useState<DocumentAnalysisLineItemInput | null>(null);
  const [editItemIndex, setEditItemIndex] = React.useState<number | null>(null);
  const clearLineItems = () => {
    props.changeLineItems([]);
  };
  const handleDeleteLineItem = (deleteIndex: number) => {
    const newRows = props.lineItems.filter(
      (row: DocumentAnalysisLineItemInput, index: number) =>
        index !== deleteIndex
    );
    props.changeLineItems(newRows);
  };
  console.log(props.lineItems);
  if (props.isLoading && props.isManaual) return <ScanItemMobileLoading />;
  return (
    <>
      <ScanItemLineItems
        lineItems={props.lineItems}
        index={props.index}
        opened={props.opened}
        setOpened={props.setOpened}
        handleEditLineItem={handleEditLineItem}
        clearLineItems={clearLineItems}
        handleDeleteLineItem={handleDeleteLineItem}
      />
      <LineItemsCreateModal
        open={modalOpen}
        item={editItem}
        onClose={() => setModalOpen(false)}
        key={modalOpen as any}
        onAdd={(data) => {
          if (!editItem) {
            const newLineItems = [...props.lineItems, { ...data }];
            props.changeLineItems(newLineItems);
          } else {
            const newLineItems = props.lineItems.map(
              (row: DocumentAnalysisLineItemInput, index: number) => {
                if (index === editItemIndex) {
                  return { ...data };
                }
                return row;
              }
            );
            props.changeLineItems(newLineItems);
          }
          setModalOpen(false);
        }}
      />
    </>
  );
};
