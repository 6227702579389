import { WBFlex, WBTypography } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getName } from '../../helpers/contacts';
import { DateTime } from 'luxon';
import { getDueDateFromTask } from '../../helpers/tasks';
import { TextFormField } from '../ESignature/SignatureForm';
import { TaskGuest } from '@admiin-com/ds-graphql';

interface Props {
  taskGuest: TaskGuest;
  isQuote: boolean;
  mobile?: boolean;
}

export const HeaderSection = ({ taskGuest, isQuote, mobile }: Props) => {
  const { t } = useTranslation();

  if (mobile) {
    return (
      <HeaderMobile>
        <WBTypography variant="body2">
          <b>{t('from', { ns: 'taskbox' })}</b>&nbsp;
          {getName(taskGuest.from) ||
            getName(taskGuest.fromEntity) ||
            getName(taskGuest.fromContact) ||
            ''}
        </WBTypography>

        <WBTypography variant="body2">
          <b>{t('to', { ns: 'taskbox' })}</b>&nbsp;
          {getName(taskGuest.toContact) || getName(taskGuest?.to) || ''}
        </WBTypography>

        <WBTypography variant="body2">
          <b>{t(isQuote ? 'Quote valid until' : 'dueAt', { ns: 'taskbox' })}</b>
          &nbsp;
          {DateTime.fromISO(
            getDueDateFromTask(taskGuest as any) ?? ''
          ).toLocaleString(DateTime.DATE_SHORT)}
        </WBTypography>
      </HeaderMobile>
    );
  }

  return (
    <Header>
      <TextFormField
        label={t('from', { ns: 'taskbox' })}
        value={
          getName(taskGuest.from) ||
          getName(taskGuest.fromEntity) ||
          getName(taskGuest.fromContact) ||
          ''
        }
      />

      <TextFormField
        label={t('to', { ns: 'taskbox' })}
        value={getName(taskGuest.toContact) || getName(taskGuest?.to) || ''}
      />
      <TextFormField
        label={
          isQuote
            ? t('Quote valid until', { ns: 'taskbox' })
            : t('dueAt', { ns: 'taskbox' })
        }
        value={DateTime.fromISO(
          getDueDateFromTask(taskGuest as any) ?? ''
        ).toLocaleString(DateTime.DATE_SHORT)}
      />
    </Header>
  );
};

const Header = styled(WBFlex)(({ theme }) => ({
  background: theme.palette.background.paper,
  width: '100%',
  padding: theme.spacing(3, 10),
  gap: theme.spacing(3),
}));

const HeaderMobile = styled(WBFlex)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  flexDirection: 'column',
  background: theme.palette.background.paper,
  gap: theme.spacing(1),
  ...theme.typography.body2,
}));
