import {
  S3UploadType,
  Task,
  TaskGuest,
  TaskStatus,
} from '@admiin-com/ds-graphql';

import { WBFlex, WBImage } from '@admiin-com/ds-web';
import { useTaskBoxContext } from '../../pages/TaskBox/TaskBox';
import PdfViewer from '../PdfViewer/PdfViewer';
import { useDocumentUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import { styled } from '@mui/material';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

/* eslint-disable-next-line */
export interface TaskPdfSignatureProps {
  task: Task | TaskGuest | null;
}

export function TaskPdfSignature({ task }: TaskPdfSignatureProps) {
  const context = useTaskBoxContext();
  const { pdfSignatureRef } = context ?? {};

  const { documentUrl, loading } = useDocumentUrl(task);
  console.log('loading', loading, documentUrl);

  const isImage = (task as Task)?.documents?.[0]?.type === S3UploadType.IMAGE;

  return (
    <WBFlex flex={1} sx={{ height: '100%', justifyContent: 'center' }}>
      {loading ? (
        <ImagePlaceholder>
          <LottieAnimation loop path="/lottie/loading-pdf.lottie" />
        </ImagePlaceholder>
      ) : documentUrl && task && task.documents?.[0]?.key ? (
        isImage ? (
          <WBImage src={documentUrl} responsive />
        ) : (
          <PdfViewer
            ref={pdfSignatureRef ?? null}
            annotations={
              task.status === TaskStatus.COMPLETED ? '' : task.annotations ?? ''
            }
            documentUrl={documentUrl}
          />
        )
      ) : null}
    </WBFlex>
  );
}
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  borderRadius: '100%',
  width: '30%',
  height: '30%',
  [theme.breakpoints.down('sm')]: {},
}));
export default TaskPdfSignature;
