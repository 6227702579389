import { WBButton, WBCollapse, WBFlex, WBTypography } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import React from 'react';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

interface OCRSanningProps {
  isIn: boolean;
  loop?: boolean;
  lottiePath?: string;
  title: string;
  message: string;
  btnText?: string;
  onClick?: () => void;
}

export default function LottieAlert({
  isIn,
  lottiePath,
  loop = false,
  title,
  message,
  btnText,
  onClick,
}: OCRSanningProps) {
  return (
    <WBCollapse in={isIn}>
      <EditViewWrapper>
        <ImageWrapper>
          <ImagePlaceholder>
            {lottiePath && <LottieAnimation path={lottiePath} loop={loop} />}
          </ImagePlaceholder>
          <TextWrapper>
            <Title textAlign={'left'}>{title}</Title>
            <Description textAlign={'left'}>{message}</Description>
          </TextWrapper>
        </ImageWrapper>
        {onClick && (
          <WBButton variant="outlined" onClick={onClick}>
            {btnText}
          </WBButton>
        )}
      </EditViewWrapper>
    </WBCollapse>
  );
}

const EditViewWrapper = styled(WBFlex)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2E3B4E' : '#E6F6FF',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  width: '100%',
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.mode === 'dark' ? '#1C2A3A' : '#BCE0F4',
  color: theme.palette.text.primary,
  borderWidth: '1px',
  borderStyle: 'solid',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    borderRadius: theme.spacing(1),
    borderWidth: '1px',
    borderStyle: 'solid',
  },
}));
const ImageWrapper = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  //backgroundColor: theme.palette.grey[300],
  width: '72x',
  height: '72px',
  borderRadius: '100%',
  [theme.breakpoints.down('sm')]: {
    //backgroundColor: theme.palette.grey[300],
    width: '64px',
    height: '64px',
    borderRadius: '100%',
  },
}));
const TextWrapper = styled(WBFlex)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
const Title = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.h5,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h6,
    marginBottom: theme.spacing(0),
  },
}));
const Description = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body2,
    marginBottom: theme.spacing(1),
  },
}));
