import { WBFlex, WBTypography } from '@admiin-com/ds-web';
import { PageContainer } from '../../../components';
import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { getEntity } from '@admiin-com/ds-graphql';
import { useOnboardingProcess } from '../../../components/OnboardingContainer/OnboadringContainer';
import VerificationScreen from '../../VerificationScreen';
import { VerificationStep } from '../../VerificationDlg/VerificationDlg';

const OnboardingBusinessVerfication = () => {
  const [loading, setLoading] = useState(false);
  const { finishOnboarding, onboardingEntityId } = useOnboardingProcess();

  const [verificationStep, setVerficationStep] =
    React.useState<VerificationStep>('Start');

  const { data } = useQuery(gql(getEntity), {
    variables: {
      id: onboardingEntityId,
    },
  });

  const entity = data?.getEntity;

  const handleSuccess = async () => {
    if (loading) return;
    if (verificationStep === 'Start') {
      setVerficationStep('Business');
    } else if (verificationStep === 'Business') {
      setVerficationStep('Complete');
    } else if (verificationStep === 'Complete') {
      await handleClose();
    }
  };

  const handleClose = async () => {
    try {
      setLoading(true);
      await finishOnboarding();
      setLoading(false);
    } catch (err: any) {
      console.log('ERROR updating entity: ', err);
      setLoading(false);
    }
  };
  return (
    <PageContainer
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        paddingY: 6,
        height: '100%',
      }}
    >
      <WBTypography mb={10} />
      <WBFlex
        flexDirection="column"
        alignItems="center"
        width={{
          xs: '100%',
          sm: '80%',
          md: '60%',
          lg: '40%',
        }}
        height={['100%', 'auto']}
      >
        {entity && (
          <VerificationScreen
            entity={entity}
            verificationStep={verificationStep}
            onClose={handleClose}
            isOnboarding
            onSuccess={handleSuccess}
          />
        )}
      </WBFlex>
    </PageContainer>
  );
};
export default OnboardingBusinessVerfication;
