import React from 'react';
import { styled } from '@mui/material';
import {
  WBFlex,
  WBSvgIcon,
  WBTypography,
  WBButton,
  WBIcon,
  WBBox,
} from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import {
  FILE_TYPES,
  PDF_FILE_EXTENSIONS,
  S3Upload,
} from '@admiin-com/ds-common';
import { S3MediaDragDrop } from 'libs/amplify-web/src/lib/components/S3MediaDragDrop/S3MediaDragDrop';
import UploadIcon from '../../../../assets/icons/upload.svg';
import { useTheme } from '@mui/material';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags/useFeatureFlags';
import { ForwardBillsContainer } from '../ForwardBills';
import { DocumentAnalysis } from '@admiin-com/ds-graphql';
import { Bill } from '../PayBillCreateForm';
import { getVendorName } from '../../../helpers/tasks';
import { XeroButton } from './XeroButton';

interface TaskUploadProps {
  bills: Bill[];
  setBills: (bills: Bill[]) => void;
  onChange: (images: S3Upload[]) => void;
  setLoading?: (loading: boolean) => void;
  label?: string;
  onUploadStarted?: () => void;
  onAdd?: (documentAnalysis: DocumentAnalysis[]) => void;
}

export const TaskUpload = React.forwardRef<any, TaskUploadProps>(
  (
    { label, onChange, setLoading, onUploadStarted, onAdd, bills, setBills },
    ref
  ) => {
    const { t } = useTranslation();
    const { featureFlags } = useFeatureFlags();
    const extensions = Object.entries(FILE_TYPES)
      .filter(([key, value]) => PDF_FILE_EXTENSIONS.includes(value))
      .map(([key, value]) => key)
      .join(', ');
    const theme = useTheme();
    const [forwardBillsOpen, setForwardBillsOpen] = React.useState(false);
    const [forwardBillSource, setForwardBillSource] = React.useState<
      'EMAIL' | 'XERO'
    >('EMAIL');

    const UploadMessage = (
      <>
        <WBFlex gap={1}>
          <WBSvgIcon fontSize="small" color={theme.palette.primary.main}>
            <UploadIcon />
          </WBSvgIcon>
          <WBTypography
            textAlign={'center'}
            fontWeight={'bold'}
            variant="body2"
          >
            {t('uploadBill', { ns: 'taskbox' })}
          </WBTypography>
        </WBFlex>
        <WBTypography
          color={'text.secondary'}
          textAlign={'center'}
          variant="body2"
          mt={1}
        >
          {`${t('supportedFiles', { ns: 'taskbox' })} ${extensions}`}
        </WBTypography>
        <WBTypography
          color={'text.secondary'}
          textAlign={'center'}
          variant="body2"
        >
          {`${t('maxSize', { ns: 'taskbox', size: '10MB' })}`}
        </WBTypography>
        <DeviderContainer>
          <Devider flex={1} />
          <WBTypography textAlign={'center'} variant="body2">
            {t('or', { ns: 'common' })}
          </WBTypography>
          <Devider flex={1} />
        </DeviderContainer>
        <FormButtonContainer>
          {featureFlags?.xero && (
            <XeroButton
              onSucces={() => {
                setForwardBillsOpen(true);
                setForwardBillSource('XERO');
              }}
            />
          )}
          <FormButton
            startIcon={
              <WBIcon name="Mail" size="small" library="ioniconSolid" />
            }
            onClick={(e: any) => {
              e.stopPropagation();
              setForwardBillsOpen(true);
              setForwardBillSource('EMAIL');
            }}
            type="button"
          >
            {t('forwardedEmails', { ns: 'authentication' })}
          </FormButton>
        </FormButtonContainer>
      </>
    );

    return (
      <WBFlex flexDirection={'column'} gap={2} ref={ref}>
        <S3MediaDragDrop
          height={'300px'}
          uploadBtnText=""
          maxFiles={10}
          maxSizeMB={10}
          onRemove={(file: S3Upload) => {
            setBills(bills.filter((bill) => bill.document?.key !== file.key));
          }}
          files={bills
            .filter((document) => document !== null)
            .map((bill: Bill) => ({
              key: bill.document?.key ?? '',
              identityId: bill.document?.identityId ?? '',
              level: 'private',
              type: bill.document?.type ?? 'PDF',
              name:
                bill.documentName ?? getVendorName(bill.documentAnalysis) ?? '',
              size: bill.documentSize ?? bill.documentAnalysis?.fromEmail ?? '',
            }))}
          validFileTypes={PDF_FILE_EXTENSIONS}
          inputAccept={PDF_FILE_EXTENSIONS.join(', ')}
          level="private"
          onUploaded={(images: S3Upload[]) => {
            onChange(images);
            if (setLoading) setLoading(false);
          }}
          onDropped={(files: FileList) => {
            if (setLoading) setLoading(true);
            if (onUploadStarted) onUploadStarted();
          }}
          uploadMessage={UploadMessage}
          uploadBtnTextProps={{
            variant: 'body2',
            fontWeight: 'bold',
            color: 'text.primary',
          }}
          removeConfirmationTitle={t('removeConfirmationTitle', {
            ns: 'taskbox',
          })}
        />
        <ForwardBillsContainer
          source={forwardBillSource}
          open={forwardBillsOpen}
          onClose={() => setForwardBillsOpen(false)}
          onAdd={(documentAnalysis: DocumentAnalysis[]) => {
            setForwardBillsOpen(false);
            onAdd && onAdd(documentAnalysis);
          }}
        />
      </WBFlex>
    );
  }
);

const Devider = styled(WBBox)(({ theme }) => ({
  backgroundColor: theme.palette.text.primary,
  height: '1px',
  width: theme.spacing(25),
  margin: theme.spacing(4, 0),
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.text.primary,
    width: theme.spacing(10),
    height: '1px',
  },
}));

export const FormButton = styled(WBButton)(({ theme }) => ({
  display: 'border-box',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderColor: theme.palette.secondary.main,
  borderWidth: '1px',
  borderStyle: 'solid',
  '&:hover': {
    borderWidth: '1px',
  },
  [theme.breakpoints.down('sm')]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    outerWidth: '100%',
  },
}));

const FormButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(4),
  backgroundColor: 'none',
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'none',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
  },
}));

const DeviderContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(4),
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    flexDirection: 'row',
    gap: theme.spacing(4),
    width: '100%',
  },
}));
