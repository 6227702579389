import React from 'react';
import { WBButton, WBSvgIcon, WBBox, WBTypography } from '@admiin-com/ds-web';
import XeroLogo from '../../../assets/icons/xero-logo.svg';
import { useTranslation } from 'react-i18next';
import { useXeroConnectStatus } from './useXeroConnectStatus';
import ConnectButton from './ConnectButton';
import { XeroScopeSet } from '@admiin-com/ds-graphql';

interface XeroSyncButtonProps {
  onClick: () => void;
  loading: boolean;
}

const XeroSyncButton: React.FC<XeroSyncButtonProps> = ({
  onClick,
  loading,
}) => {
  const { t } = useTranslation();

  const { loading: loadingGetConnection, isXeroConnected } =
    useXeroConnectStatus();
  if (loadingGetConnection && !isXeroConnected) {
    return null;
  }
  if (!isXeroConnected) {
    return <ConnectButton scopeSet={XeroScopeSet.CONTACTS} />;
  }
  return (
    <WBButton
      sx={{
        borderRadius: '999px',
        backgroundColor: '#13B5EA',
        '&:hover': {
          backgroundColor: '#088cb9',
        },
        color: 'white',
        padding: 2,
        display: 'flex',
        justifyContent: 'start',
      }}
      disabled={loading}
      onClick={onClick}
    >
      <WBSvgIcon
        viewBox="0 0 6 6"
        fontSize="large"
        component={'div'}
        sx={{ backgroundColor: `rgba(255,255,255,0)` }}
      >
        <XeroLogo />
      </WBSvgIcon>
      <WBBox ml={1}>
        <WBTypography color={'inherit'} fontWeight={'medium'}>
          {t('syncWithXero', { ns: 'contacts' })}
        </WBTypography>
        <WBTypography variant="body2" color={'inherit'} fontWeight={'regular'}>
          {t('synchronizeXeroContacts', { ns: 'contacts' })}
        </WBTypography>
      </WBBox>
    </WBButton>
  );
};

export default XeroSyncButton;
