import SimpleDrawDlg from '../../components/SimpleDrawDlg/SimpleDrawDlg';
import { DialogContent, DialogTitle } from '@mui/material';
import {
  WBButton,
  WBFlex,
  WBSkeleton,
  WBSvgIcon,
  WBTypography,
} from '@admiin-com/ds-web';
import ConnectedAccountDetails from '../../components/ConnectedAccountDetails/ConnectedAccountDetails';
import { numberToCurrency } from '@admiin-com/ds-common';
import DownIcon from '../../../assets/icons/down-arrow.svg';
import UpIcon from '../../../assets/icons/up-arrow.svg';
import AdmiinAccountIcon from '../../../assets/icons/admiin-account.svg';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/system';
//import DepositAccountModal from '../DepositAccountModal/DepositAccountModal';
import WithdrawAccountModal from '../WithdrawAccountModal/WithdrawAccountModal';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { PaymentSubmittedModal } from '../../components/PaymentSubmittedModal/PaymentSubmitedModal';
import { useNavigate } from 'react-router-dom';

interface AdmiinAccountWallet {
  admiinAccount: any;
  isVerified: boolean;
  loading: boolean;
  refetchAccount: () => void;
}

export const AdmiinAccountWallet = ({
  admiinAccount,
  isVerified,
  loading,
  refetchAccount,
}: AdmiinAccountWallet) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { entity } = useSelectedEntity();
  const [showDetails, setShowDetails] = React.useState(false);
  //const [showDeposit, setShowDeposit] = React.useState(false);  //disabled for now
  const [showWithdraw, setShowWithdraw] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const navigate = useNavigate();

  const onSuccess = () => {
    setShowWithdraw(false);
    setSuccess(true);
    refetchAccount();
  };

  const onClose = () => {
    setShowWithdraw(false);
    setSuccess(false);
  };

  return (
    <>
      <PaymentSubmittedModal
        open={success}
        onClose={onClose}
        title="Withdraw Submitted"
        description="Your funds will arrive into your bank account within 1 - 2 business days"
        closeButtonText="Close"
      />
      {showDetails && (
        <SimpleDrawDlg
          open={showDetails}
          handleClose={() => setShowDetails(false)}
        >
          <DialogTitle variant="h3" fontWeight={'bold'} color="text.primary">
            {t('admiinAccountDetails', { ns: 'admiinAccount' })}
            <WBTypography variant="body1" mt={1}>
              {t('addConnectedAccountDescription', { ns: 'settings' })}
            </WBTypography>
          </DialogTitle>
          <DialogContent>
            <ConnectedAccountDetails admiinAccount={admiinAccount} />
          </DialogContent>
        </SimpleDrawDlg>
      )}

      {/*{showDeposit && (*/}
      {/*  <DepositAccountModal*/}
      {/*    open={showDeposit}*/}
      {/*    entityId={entity?.id}*/}
      {/*    onClose={() => setShowDeposit(false)}*/}
      {/*    admiinAccount={admiinAccount}*/}
      {/*  />*/}
      {/*)}*/}

      {showWithdraw && (
        <WithdrawAccountModal
          open={showWithdraw}
          onClose={() => setShowWithdraw(false)}
          admiinAccount={admiinAccount}
          onSuccess={onSuccess}
        />
      )}

      <WBFlex flexDirection="column">
        <WBFlex flexDirection="column">
          <WBFlex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent={['center', 'center', 'left']}
          >
            {admiinAccount ? (
              <WBTypography variant="h2" margin={0}>
                {numberToCurrency(
                  (admiinAccount?.currentBalance.availableAmount || 0) / 100,
                  admiinAccount?.currency ?? undefined
                )}{' '}
                AUD
              </WBTypography>
            ) : loading ? (
              <WBSkeleton width={285} height={50} />
            ) : (
              <WBTypography variant="h2" margin={0}>
                {numberToCurrency(0)} AUD
              </WBTypography>
            )}
          </WBFlex>
        </WBFlex>
        <WBFlex mt={1} justifyContent={['center', 'center', 'left']}>
          <WBButton
            disabled={!isVerified}
            color="success"
            sx={{ color: '#000', paddingRight: 4 }}
            onClick={() => setShowDetails(true)}
          >
            <WBSvgIcon sx={{ mr: 1 }}>
              <UpIcon />
            </WBSvgIcon>
            {t('deposit', { ns: 'admiinAccount' })}
          </WBButton>
          <WBButton
            disabled={
              !isVerified ||
              admiinAccount?.currentBalance?.availableAmount === 0
            }
            color="inherit"
            sx={{ ml: 1, paddingRight: 4 }}
            onClick={() => setShowWithdraw(true)}
          >
            <WBSvgIcon sx={{ mr: 1 }}>
              <DownIcon />
            </WBSvgIcon>
            {t('withdraw', { ns: 'admiinAccount' })}
          </WBButton>
        </WBFlex>
        {admiinAccount?.accountNumber && isVerified && (
          <WBFlex justifyContent={['center', 'center', 'flex-start']}>
            <WBButton
              variant="text"
              onClick={() => setShowDetails(true)}
              sx={{ ml: 1, mt: 2 }}
            >
              <WBSvgIcon
                sx={{ mr: '2px' }}
                color={theme.palette.primary.main}
                fontSize="small"
              >
                <AdmiinAccountIcon />
              </WBSvgIcon>
              {admiinAccount?.accountNumber}
            </WBButton>
          </WBFlex>
        )}
      </WBFlex>
      {loading && !admiinAccount?.accountNumber && (
        <WBFlex justifyContent={['center', 'center', 'flex-start']} mt={2}>
          <WBSkeleton width="120px" height="38px" />
        </WBFlex>
      )}
    </>
  );
};
