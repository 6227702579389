import { WBBox, WBFlex } from '@admiin-com/ds-web';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Bill } from '../PayBillCreateForm';
import { BillViewer } from './BillViewer';
import SimpleUpload from './SimpleUpload';
import { BillForm } from './BillForm';

interface Props {
  bill: Bill;
  onUpdateBill?: (bill: Bill) => void;
  index?: number;
  isManualBillInput?: boolean;
}

export const BillDetail = ({ bill, onUpdateBill, index }: Props) => {
  const theme = useTheme();
  // const [drawerOpen, setDrawerOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container>
      {!isMobile && index !== undefined && onUpdateBill && (
        <PDFPreviewContainer>
          {bill && bill.document ? (
            <BillViewer bill={bill} />
          ) : (
            <WBFlex
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <SimpleUpload
                maxFiles={1}
                onChange={(documents) => {
                  onUpdateBill({
                    ...bill,
                    document: documents[0],
                  });
                }}
              />
            </WBFlex>
          )}
        </PDFPreviewContainer>
      )}
      <FormContainer>
        <BillForm bill={bill} index={index} />
      </FormContainer>
    </Container>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  width: '100%',
  alignItems: 'start',
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'transparent',
  },
}));
const PDFPreviewContainer = styled(WBFlex)(({ theme }) => ({
  minHeight: '750px',
  width: '45%',
  backgroundColor: theme.palette.background.paper,
}));

const FormContainer = styled(WBBox)(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(5),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  width: '55%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
  },
}));
