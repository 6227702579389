import { Entity } from '@admiin-com/ds-graphql';
import React from 'react';
import AirwallexOnboarding from '../../components/AirwallexOnboarding/AirwallexOnboarding';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';

export interface VerificationBusinessProps {
  onSuccess: (entity: Entity | null | undefined) => void;
  onClose: () => void;
  entity?: Entity | null;
}

export function VerificationBusiness({
  onSuccess,
  onClose,
  entity,
}: VerificationBusinessProps) {
  const [business, setBusiness] = React.useState<Entity | null | undefined>(
    undefined
  );

  const { entity: selectedEntity } = useSelectedEntity();

  const defaultEntity = entity ?? selectedEntity;
  React.useEffect(() => {
    if (defaultEntity && business === undefined) {
      setBusiness(defaultEntity);
    }
  }, [business, defaultEntity]);

  return (
    <AirwallexOnboarding
      entity={entity}
      onSuccess={onSuccess}
      onClose={onClose}
    />
  );
}

export default VerificationBusiness;
