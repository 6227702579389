import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  WBBox,
  WBFlex,
  WBIcon,
  WBIconButton,
  WBLinkButton,
  WBTextField,
} from '@admiin-com/ds-web';
import { REGEX } from '@admiin-com/ds-common';
import { OtherRecipientInput } from '@admiin-com/ds-graphql';

const EmailField = React.memo(
  ({
    index,
    control,
    errors,
    onDelete,
  }: {
    index: number;
    control: any;
    errors: any;
    onDelete?: () => void;
  }) => {
    const { t } = useTranslation();
    return (
      <Controller
        control={control}
        name={`otherRecipients.${index}.email`}
        rules={{
          required: t('emailRequired', { ns: 'common' }),
          pattern: {
            value: REGEX.EMAIL,
            message: t('invalidEmail', { ns: 'common' }),
          },
        }}
        render={({ field }) => (
          <WBBox flex={1} width="100%">
            <WBTextField
              {...field}
              fullWidth
              label={`${t('email', { ns: 'contacts' })}*`}
              placeholder={t('emailPlaceholder', { ns: 'contacts' })}
              error={!!errors?.otherRecipients?.[index]?.email}
              helperText={
                errors?.otherRecipients?.[index]?.email?.message || ''
              }
              rightIcon={
                onDelete ? (
                  <WBIconButton onClick={onDelete}>
                    <WBIcon name="Close" />
                  </WBIconButton>
                ) : undefined
              }
              margin="dense"
            />
          </WBBox>
        )}
      />
    );
  }
);

export const OtherRecipientAddForm = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<{ otherRecipients: OtherRecipientInput[] }>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'otherRecipients',
  });
  const { t } = useTranslation();

  const handleAdd = () => {
    if (fields.length < 5)
      append({
        email: '',
        sendOnCompletion: true,
      });
  };

  return (
    <>
      {fields.map((field, index) => (
        <React.Fragment key={field.id}>
          <WBFlex
            sx={{ display: ['none', 'flex'] }}
            alignItems={'center'}
            gap={5}
          >
            <EmailField
              index={index}
              control={control}
              errors={errors}
              onDelete={() => remove(index)}
            />
            {/* <Controller
              control={control}
              name={`otherRecipients.${index}.sendOnCompletion`}
              render={({ field }) => (
                <WBBox flex={1}>
                  <WBCheckbox
                    {...field}
                    label={t('sendOnCompletion', { ns: 'taskbox' })}
                  />
                </WBBox>
              )}
            /> */}
          </WBFlex>
          <WBFlex
            my={1}
            sx={{ bgcolor: 'background.paper', display: ['flex', 'none'] }}
            gap={1}
            alignItems={'start'}
            flexDirection={'column'}
          >
            <WBFlex
              flex={1}
              width={'100%'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <EmailField
                index={index}
                control={control}
                errors={errors}
                onDelete={() => remove(index)}
              />
            </WBFlex>
            {/* <Controller
              control={control}
              name={`otherRecipients.${index}.sendOnCompletion`}
              render={({ field }) => (
                <WBCheckbox
                  {...field}
                  label={t('sendOnCompletion', { ns: 'taskbox' })}
                />
              )}
            /> */}
          </WBFlex>
        </React.Fragment>
      ))}
      {fields.length <= 5 && (
        <WBFlex justifyContent={'start'} mt={3} color={'primary.main'}>
          <WBLinkButton onClick={() => handleAdd()}>
            {t('addOtherRecipient', { ns: 'taskbox' })}
          </WBLinkButton>
        </WBFlex>
      )}
    </>
  );
};
