import {
  WBBox,
  WBButton,
  WBCheckbox,
  WBFlex,
  WBSelect,
  WBTypography,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SMSNumber } from './SMSNumber';
import InvoiceRenderer from '../InvoiceRenderer';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { PaymentTerms } from '@admiin-com/ds-graphql';
import { useFeatureFlags } from '../../hooks/useFeatureFlags/useFeatureFlags';

interface Props {
  onDraft: () => Promise<void>;
  onSend: () => Promise<void>;
  isDraft?: boolean;
}

export default function InvoiceConfirm(props: Props) {
  const { t } = useTranslation();
  const [draftLoading, setDraftLoading] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const { setValue, control } = useFormContext();
  const { featureFlags } = useFeatureFlags();
  const quoteSignPayEnabled = Boolean(featureFlags?.quoteSignPay);

  const handleConfirm = async () => {
    if (draftLoading || confirmLoading) return;
    setConfirmLoading(true);
    try {
      await props.onSend();
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleDraft = async () => {
    if (draftLoading || confirmLoading) return;
    setDraftLoading(true);
    try {
      await props.onDraft();
    } finally {
      setDraftLoading(false);
    }
  };

  const invoiceStatus = useWatch({
    name: 'invoiceStatus',
    control,
    defaultValue: 'QUOTE',
  });
  const isQuote = invoiceStatus === 'QUOTE';
  const to = useWatch({ name: 'to', control });
  const sendSMS = useWatch({ name: 'sendSMS', control });

  const automaticGenerateInvoice = useWatch({
    name: 'automaticGenerateInvoice',
  });

  console.log(quoteSignPayEnabled, isQuote);
  return (
    <Container>
      <PreviewConatiner flex={7} px={[0, 0, 8, 12]}>
        <InvoiceRenderer />
      </PreviewConatiner>
      <ButtonsContainer flex={5}>
        <WBBox>
          <SMSNumber
            onAdd={() => {
              props.onSend();
            }}
            checked={sendSMS}
            contact={to}
            onCheck={(value) => {
              setValue('sendSMS', value);
            }}
          />
        </WBBox>

        {quoteSignPayEnabled && isQuote && (
          <BoxContainer>
            <WBBox sx={{ width: '100%' }}>
              <WBFlex>
                {
                  <Controller
                    name="automaticGenerateInvoice"
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <WBCheckbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                }
                <WBBox flex={1}>
                  <WBTypography fontWeight={'bold'}>
                    {t('allowReceiverToPay', { ns: 'taskbox' })}
                  </WBTypography>
                  <WBTypography color={'text.secondary'}>
                    {t('allowReceiverToPayDescription', { ns: 'taskbox' })}
                  </WBTypography>
                </WBBox>
              </WBFlex>
              {automaticGenerateInvoice && (
                <WBBox>
                  <Controller
                    name="automaticInvoicePaymentTerms"
                    control={control}
                    defaultValue={PaymentTerms.IMMEDIATE}
                    render={({ field }) => (
                      <WBSelect
                        {...field}
                        options={Object.values(PaymentTerms).map((value) => ({
                          label: t(value, { ns: 'taskbox' }),
                          value,
                        }))}
                        label={t('dueDate', { ns: 'taskbox' })}
                        fullWidth
                      />
                    )}
                  />
                </WBBox>
              )}
            </WBBox>
          </BoxContainer>
        )}

        {quoteSignPayEnabled && isQuote && (
          <BoxContainer>
            {
              <Controller
                name="quoteRequiresSignature"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <WBCheckbox
                    {...field}
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            }
            <WBBox flex={1}>
              <WBTypography fontWeight={'bold'}>
                {t('requestSignature', { ns: 'taskbox' })}
              </WBTypography>
            </WBBox>
          </BoxContainer>
        )}
        <WBButton onClick={handleConfirm} loading={confirmLoading} fullWidth>
          {t('confirmSend', { ns: 'taskbox' })}
        </WBButton>
        <WBButton
          variant="outlined"
          onClick={handleDraft}
          loading={draftLoading}
          fullWidth
          disabled={props.isDraft}
          type="button"
        >
          {t('saveAsDraft', { ns: 'taskbox' })}
        </WBButton>
      </ButtonsContainer>
    </Container>
  );
}
const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  flex: 1,
  width: '100%',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const PreviewConatiner = styled(WBFlex)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
}));

const ButtonsContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
const BoxContainer = styled(WBFlex)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(2),
  alignItems: 'center',
}));
