import { WBBox } from '@admiin-com/ds-web';
import { useTheme } from '@mui/system';
import { ColorResult, TwitterPicker } from 'react-color';

interface ColorPickerProps {
  color: string;
  onChange: (color: ColorResult) => void;
}

export const ColorPicker = ({ color, onChange }: ColorPickerProps) => {
  const theme = useTheme();
  return (
    <WBBox mt={1}>
      <TwitterPicker
        styles={{
          default: {
            card: {
              backgroundColor: theme?.palette?.background?.paper ?? '',
              boxShadow: 'none',
            },
          },
        }}
        color={color}
        triangle={'hide'}
        onChange={onChange}
      />
    </WBBox>
  );
};
