import * as React from 'react';
import { WBBox } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LottieAlert from '../../LottieAlert/LottieAlert';
import { ScanItemsTable } from './Scanning/Desktop/ScanItemsTable';
import { ScanItemsMobile } from './Scanning/Mobile/ScanItemsMobile';
import { Bill } from '../PayBillCreateForm';

interface Props {
  bills: Bill[];
  setBills: (bills: Bill[]) => void;
  isLoading: boolean;
  openedRow: number;
  setOpenedRow: (row: number) => void;
}

export const ReviewForm = ({
  bills,
  setBills,
  openedRow,
  setOpenedRow,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Content>
      <LottieAlert
        lottiePath="/lottie/document-scanning.lottie"
        loop={isLoading}
        title={t(
          isLoading ? 'scanningInProgressTitle' : 'scanningCompleteTitle',
          { ns: 'taskbox' }
        )}
        message={t(
          isLoading ? 'scanningInProgressMessage' : 'scanningCompleteMessage',
          { ns: 'taskbox' }
        )}
        isIn={isLoading}
      />
      <WBBox sx={{ display: ['block', 'none'] }}>
        <ScanItemsMobile
          opened={openedRow}
          setOpened={setOpenedRow}
          bills={bills}
          setBills={setBills}
        />
      </WBBox>
      <WBBox sx={{ display: ['none', 'block'] }}>
        <ScanItemsTable
          opened={openedRow}
          setOpened={setOpenedRow}
          bills={bills}
          setBills={setBills}
        />
      </WBBox>
    </Content>
  );
};
const Content = styled(WBBox)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));
