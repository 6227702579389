import { Outlet, useParams } from 'react-router-dom';
import { selectedEntityIdInVar } from '@admiin-com/ds-graphql';
import React from 'react';

export function EntityTaskbox() {
  const { entityId } = useParams();

  React.useEffect(() => {
    console.log('entityId: ', entityId);
    if (entityId) localStorage.setItem('selectedEntityId', entityId);

    selectedEntityIdInVar(entityId);
  }, [entityId]);
  return <Outlet />;
}

export default EntityTaskbox;
