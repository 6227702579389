import React from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

const LottieAnimation = ({
  path,
  loop = false,
  segment,
}: {
  path: string;
  loop?: boolean;
  segment?: [number, number];
}) => {
  return (
    <div>
      <DotLottieReact src={path} loop={loop} autoplay segment={segment} />
    </div>
  );
};

export default LottieAnimation;
