import { useSnackbar, WBAlert, WBLinkButton } from '@admiin-com/ds-web';
import React, { useEffect } from 'react';
import VerificationDlg from '../../pages/VerificationDlg/VerificationDlg';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';

export interface ErrorHandlerProps {
  errorMessage?: string;
  isDialog?: boolean;
  onOpenNewDialog?: () => void;
}

export const ErrorHandler = ({
  errorMessage,
  onOpenNewDialog,
  isDialog,
}: ErrorHandlerProps) => {
  const showSnackbar = useSnackbar();

  useEffect(() => {
    if (isDialog && errorMessage) {
      showSnackbar({
        message: errorMessage,
        severity: 'error',
      });
    }
  }, [errorMessage, isDialog, showSnackbar]);

  return (
    errorMessage &&
    !isDialog && (
      <WBAlert title={errorMessage} severity="error" sx={{ my: 2 }}>
        {/* {errorMessage} */}
        {errorMessage === 'ENTITY_NOT_VERIFIED' && (
          <WBLinkButton
            onClick={() => {
              if (onOpenNewDialog) {
                onOpenNewDialog();
              }
            }}
            sx={{
              textDecoration: 'underline',
            }}
          >
            Verify Now
          </WBLinkButton>
        )}
      </WBAlert>
    )
  );
};

export default ErrorHandler;
