import ConnectButton from './ConnectButton';
import DisconnectButton from './DisconnectButton';
import { useXeroConnectStatus } from './useXeroConnectStatus';
import { useDisconnect } from './useDisconnect';
import { useContactSync } from './useContactSync';
import { useInvoiceSync } from './useInvoiceSync';
import { XeroScopeSet } from '@admiin-com/ds-graphql';

export const ConnectDisconnectXero = () => {
  const { loading, isXeroConnected } = useXeroConnectStatus();

  const { startXeroSync: startContactSync } = useContactSync({});
  const { startXeroSync: startInvoiceSync } = useInvoiceSync({});
  const onDisconnect = useDisconnect();

  return loading ? null : !isXeroConnected ? (
    <ConnectButton scopeSet={XeroScopeSet.PROFILE} />
  ) : (
    <DisconnectButton
      //@ts-ignore
      onXeroInvoiceSync={startInvoiceSync}
      //@ts-ignore
      onXeroContactSync={startContactSync}
      onDisconnect={onDisconnect}
    />
  );
};
