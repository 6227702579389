import {
  WBBox,
  WBFlex,
  WBTypography,
  WBIconButton,
  WBLink,
} from '@admiin-com/ds-web';
import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadSvgIcon from '../../../../../component/LoadSvgIcon/LoadSvgIcon';
import BPay from '../../../../../../assets/icons/bpay.svg';
import Bank from '../../../../../../assets/icons/bank.svg';
import File from '../../../../../../assets/icons/file.svg';
import PenIcon from '../../../../../../assets/icons/pen-icon.svg';

import { formatDateWithDaysDifference } from '../../../../../helpers/string';
import * as React from 'react';
import { CircleCheckbox } from '../Desktop/ScanItemsTableRow';
import { Bill } from '../../../PayBillCreateForm';
import { getFromName } from 'apps/react-app/src/app/helpers/tasks';
import BillModal from '../../BillModal';
import { MobileBillUpload } from './MobileBillUpload';

interface ScanItemBillDetailProps {
  bill: any;
  index: number;
  opened: number;
  setOpened: any;
  updateBill: (newBill: Bill) => void;
}
export const ScanItemBillDetail = (props: ScanItemBillDetailProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const checked = props.bill.checked;
  const handleCheck = (e: any) => {
    e.stopPropagation();
    const newChecked = e.target.checked;
    props.updateBill({ ...props.bill, checked: newChecked });
  };
  const [open, setOpen] = React.useState(false);
  const handleOk = (bill: Bill) => {
    console.log('bill', bill);
    props.updateBill({ ...props.bill, ...bill });
    setOpen(false);
  };

  const [pdfDrawerOpen, setPdfDrawerOpen] = React.useState(false);

  const paymentDetails = React.useMemo(() => {
    if (props.bill?.bpay && props.bill?.bpay?.billerCode) {
      return (
        <>
          <LoadSvgIcon component={BPay} width={40} height={40} />
          <Text textAlign={'left'}>
            {`${props.bill?.bpay.billerCode || t('missing')} / ${
              props.bill?.bpay.referenceNumber || t('missing')
            }`}
          </Text>
        </>
      );
    } else if (props.bill?.bank) {
      return (
        <>
          <LoadSvgIcon component={Bank} width={25} height={25} />
          <Text textAlign={'left'}>
            {`${props.bill?.bank.routingNumber || t('missing')} / ${
              props.bill?.bank.accountNumber || t('missing')
            }`}
          </Text>
        </>
      );
    }
    return (
      <Text sx={{ ml: 4 }} color={'action.disabled'} fontStyle={'italic'}>
        {t('noPaymentDetails', { ns: 'taskbox' })}
      </Text>
    );
  }, [props.bill]);

  const opend = props.opened === props.index;
  return (
    <Container>
      <WBFlex justifyContent={'space-between'} alignItems={'center'}>
        <VendorContainer>
          <LoadSvgIcon component={File} width={25} height={25} />
          {getFromName(props.bill.from) ? (
            <Text textAlign={'left'}>{getFromName(props.bill.from)}</Text>
          ) : (
            <Text color={'action.disabled'} fontStyle={'italic'}>
              {t('noVendorName', { ns: 'taskbox' })}
            </Text>
          )}
        </VendorContainer>
        <CircleCheckbox onClick={handleCheck} checked={checked} />
      </WBFlex>
      <PaymentDetailContainer>
        <WBFlex alignItems={'center'} gap={1}>
          {paymentDetails}
        </WBFlex>
        <WBIconButton
          size="small"
          sx={{ mr: 1 }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <LoadSvgIcon component={PenIcon} />
        </WBIconButton>
      </PaymentDetailContainer>
      <DueDateContainer
        sx={{
          borderBottom: opend ? `1px solid ${theme.palette.grey[300]}` : 'none',
        }}
      >
        <Text textAlign={'left'}>
          {`${formatDateWithDaysDifference(props.bill?.dueAt)}`}
        </Text>
        <WBLink onClick={() => setPdfDrawerOpen(true)}>
          {t('viewDocument', { ns: 'taskbox' })}
        </WBLink>
      </DueDateContainer>
      <BillModal
        item={props.bill}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOk={handleOk}
      />
      <MobileBillUpload
        bill={props.bill}
        updateBill={props.updateBill}
        open={pdfDrawerOpen}
        setOpen={setPdfDrawerOpen}
      />
    </Container>
  );
};

const Container = styled(WBBox)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
}));
const Text = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
}));
const VendorContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));
const PaymentDetailContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
}));
const DueDateContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  justifyContent: 'space-between',
}));
