import React, { useState } from 'react';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useAdmiinAccount } from '../../hooks/useAdmiinAccount/useAdmiinAccount';
import TransactionList from '../../components/TransactionList/TransactionList';
import { WBDivider, WBFlex, WBTypography } from '@admiin-com/ds-web';
import { useTranslation } from 'react-i18next';
import { AdmiinAccountWallet } from './AdmiinAccountWallet';
import { SidebarLayout } from '../../components/SidebarLayout/SidebarLayout';
import { AccountStatus } from '@admiin-com/ds-graphql';
import LottieAlert from '../../components/LottieAlert/LottieAlert';
import VerificationDlg from '../VerificationDlg/VerificationDlg';

export function AdmiinAccount() {
  const { t } = useTranslation();
  const { entity: currentEntity } = useSelectedEntity();
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const {
    admiinAccount,
    balanceHistory,
    loadingBalanceHistory,
    loadingAdmiinAccount,
    refetchAll,
  } = useAdmiinAccount();

  return (
    <WBFlex p={3} flexDirection="column">
      {currentEntity?.accountStatus === AccountStatus.CREATED ||
      currentEntity?.accountStatus === AccountStatus.ACTION_REQUIRED ? (
        <VerificationDlg
          entity={currentEntity}
          onSuccess={() => {
            console.log('success');
          }}
          open={showVerify}
          onClose={() => setShowVerify(false)}
        />
      ) : null}
      <WBFlex
        sx={{ flex: 1 }}
        alignItems={'center'}
        width={'100%'}
        flexDirection={'row'}
        display={{ xs: 'flex', sm: 'none' }}
        mb={2}
        justifyContent="space-between"
      >
        <WBFlex
          sx={{ flex: 1 }}
          alignItems={'center'}
          width={'100%'}
          justifyContent="space-between"
        >
          <SidebarLayout.MenuButton />
        </WBFlex>
      </WBFlex>
      <AdmiinAccountWallet
        isVerified={currentEntity?.accountStatus === AccountStatus.ACTIVE}
        admiinAccount={admiinAccount}
        loading={loadingAdmiinAccount || loadingAdmiinAccount}
        refetchAccount={refetchAll}
      />

      <WBDivider sx={{ mt: 2, mb: 3 }} />
      <WBFlex alignItems="center" mb={4}>
        <WBFlex flexDirection="column" flex={1}>
          <WBTypography variant="h4">
            {t('transactions', { ns: 'admiinAccount' })}
          </WBTypography>
          <TransactionList
            balanceHistory={balanceHistory}
            loading={loadingBalanceHistory || loadingAdmiinAccount}
          />
        </WBFlex>
        <WBFlex flex={[0, 0, 1]} />
      </WBFlex>
      {!loadingBalanceHistory &&
        !loadingAdmiinAccount &&
        currentEntity &&
        currentEntity?.accountStatus !== AccountStatus.ACTIVE && (
          <LottieAlert
            lottiePath="/lottie/exclamation.lottie"
            title={t(`KYC${currentEntity.accountStatus}`, { ns: 'common' })}
            message={t(
              `ADMIIN_ACCOUNT_${currentEntity?.accountStatus}_Description`,
              {
                ns: 'common',
              }
            )}
            btnText={
              currentEntity?.accountStatus === AccountStatus.CREATED ||
              currentEntity?.accountStatus === AccountStatus.ACTION_REQUIRED
                ? t('completeVerification', { ns: 'common' })
                : undefined
            }
            onClick={
              currentEntity?.accountStatus === AccountStatus.CREATED ||
              currentEntity?.accountStatus === AccountStatus.ACTION_REQUIRED
                ? () => setShowVerify(true)
                : undefined
            }
            isIn={true}
          />
        )}
    </WBFlex>
  );
}

export default AdmiinAccount;
