import React, { useEffect } from 'react';

export const useFeatureFlags = () => {
  // const featureFlagsRef = React.useRef<Record<string, boolean> | null>(null);
  const [featureFlags, setFeatureFlags] = React.useState<Record<
    string,
    boolean
  > | null>(null);

  useEffect(() => {
    fetch('/featureFlags.json')
      .then((response) => response.json())
      .then((featureFlags: Record<string, boolean>) => {
        console.log('featureFlags: ', featureFlags);
        setFeatureFlags(featureFlags);
      })
      .catch((e) => console.log(e));
  }, []);

  return { featureFlags };
};
