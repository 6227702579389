import {
  xeroGetConnectionStatus,
  xeroGetEntityConnectionStatus,
} from '@admiin-com/ds-graphql';
import { gql, useQuery } from '@apollo/client';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';

export const useXeroConnectStatus = () => {
  const entityId = useCurrentEntityId();
  const { data, loading } = useQuery(gql(xeroGetEntityConnectionStatus), {
    variables: {
      input: { entityId },
    },
  });
  const isXeroConnected = data?.xeroGetEntityConnectionStatus?.isConnected;
  // const { data, loading } = useQuery(gql(xeroGetConnectionStatus));

  // const isXeroConnected = data?.xeroGetConnectionStatus?.isConnected;
  return { isXeroConnected, loading };
};
