import {
  ThemeOptions,
  WBDesignSystemProvider,
  WBSnackbarProvider,
} from '@admiin-com/ds-web';
import { darkTheme, theme } from '@admiin-com/ds-design-token';
import mixpanel from 'mixpanel-browser';
import { deepmerge } from 'deepmerge-ts';
import { LinkBehavior } from './components';
import { NavRoutes } from './navigation/NavRoutes';
import * as Sentry from '@sentry/react';
import './i18n';
import React from 'react';
import Gleap from 'gleap';

const { VITE_ENV, VITE_GLEAP_API_TOKEN, VITE_MIXPANEL_TOKEN, VITE_SENTRY_DSN } =
  import.meta.env;

if (VITE_ENV === 'prod') {
  Gleap.initialize(VITE_GLEAP_API_TOKEN);
  Gleap.setEnvironment(VITE_ENV === 'prod' ? 'prod' : 'dev');
}

const commonTheme = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
};

// TODO: (EDIT: I think fixed?) throwing ts error although behaviour working for component: LinkBehaviour for MuiLink
const appTheme: ThemeOptions = deepmerge(theme, commonTheme);
const appDarkTheme: ThemeOptions = deepmerge(darkTheme, commonTheme);
Sentry.init({
  dsn: VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: VITE_ENV === 'prod', //TODO: enable for production VITE_ENV === 'prod'
});

export function App() {
  mixpanel.init(VITE_MIXPANEL_TOKEN, {
    debug: VITE_ENV !== 'prod',
    ignore_dnt: true,
  });

  return (
    <WBDesignSystemProvider theme={appTheme} darkTheme={appDarkTheme}>
      <WBSnackbarProvider>
        <NavRoutes />
      </WBSnackbarProvider>
    </WBDesignSystemProvider>
  );
}

export default App;
