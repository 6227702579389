import {
  WBBox,
  WBFlex,
  WBIconButton,
  WBSkeleton,
  WBSvgIcon,
  WBTypography,
  useTheme,
  useSnackbar,
} from '@admiin-com/ds-web';
import { numberToCurrency } from '@admiin-com/ds-common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LinkIcon from '../../../assets/icons/link.svg';
import { useClipboard } from '@admiin-com/ds-hooks';
import { AdmiinAccount } from '@admiin-com/ds-graphql';

export interface ConnectedAccountDetailsProps {
  admiinAccount?: AdmiinAccount;
  transferAmount?: number;
  showTransfer?: boolean;
}

export function ConnectedAccountDetails({
  admiinAccount,
  transferAmount,
  showTransfer = false,
}: ConnectedAccountDetailsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [, copy] = useClipboard();
  const showSnackBar = useSnackbar();

  const onCopy = (text: string) => {
    copy(text);
    showSnackBar({
      message: t('copied', { ns: 'settings' }),
      severity: 'success',
      horizontal: 'right',
      vertical: 'bottom',
    });
  };

  return (
    <WBBox>
      <WBTypography variant="h5" mt={3}>
        {t('accountName', { ns: 'settings' })}
      </WBTypography>
      {admiinAccount ? (
        <WBFlex alignItems="center">
          <WBTypography mr={1}>{admiinAccount?.accountName}</WBTypography>
          <WBIconButton>
            <WBSvgIcon
              color={theme.palette.primary.main}
              fontSize="small"
              onClick={() => onCopy(admiinAccount?.accountName ?? '')}
            >
              <LinkIcon />
            </WBSvgIcon>
          </WBIconButton>
        </WBFlex>
      ) : (
        <WBSkeleton width={100} height={40} />
      )}
      <WBTypography variant="h5" mt={3}>
        {t('accountNumber', { ns: 'settings' })}
      </WBTypography>
      {admiinAccount ? (
        <WBFlex alignItems="center">
          <WBTypography>{admiinAccount.accountNumber}</WBTypography>
          <WBIconButton>
            <WBSvgIcon
              color={theme.palette.primary.main}
              fontSize="small"
              onClick={() => onCopy(admiinAccount?.accountNumber ?? '')}
            >
              <LinkIcon />
            </WBSvgIcon>
          </WBIconButton>
        </WBFlex>
      ) : (
        <WBSkeleton width={100} height={40} />
      )}
      <WBTypography variant="h5" mt={3}>
        {t('routingNumber', { ns: 'settings' })}
      </WBTypography>
      {admiinAccount ? (
        <WBFlex alignItems="center">
          <WBTypography>{admiinAccount.routingNumber}</WBTypography>
          <WBIconButton>
            <WBSvgIcon
              color={theme.palette.primary.main}
              fontSize="small"
              onClick={() => onCopy(admiinAccount?.routingNumber ?? '')}
            >
              <LinkIcon />
            </WBSvgIcon>
          </WBIconButton>
        </WBFlex>
      ) : (
        <WBSkeleton width={100} height={40} />
      )}
      {!showTransfer && transferAmount && (
        <>
          <WBTypography variant="h5" mt={3}>
            {t('amountToTransfer', { ns: 'settings' })}
          </WBTypography>
          {admiinAccount ? (
            <WBFlex alignItems="center">
              <WBTypography>
                {numberToCurrency(
                  transferAmount,
                  admiinAccount?.currency ?? undefined
                )}
              </WBTypography>
              <WBIconButton>
                <WBSvgIcon
                  color={theme.palette.primary.main}
                  fontSize="small"
                  onClick={() => onCopy(transferAmount?.toString() ?? '')}
                >
                  <LinkIcon />
                </WBSvgIcon>
              </WBIconButton>
            </WBFlex>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
        </>
      )}
      {!showTransfer && (
        <>
          <WBTypography variant="h5" mt={3}>
            {t('amount', { ns: 'settings' })}
          </WBTypography>
          {admiinAccount ? (
            <WBTypography>
              {t('amountYouWantTransfer', { ns: 'settings' })}
            </WBTypography>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
        </>
      )}
    </WBBox>
  );
}

export default ConnectedAccountDetails;
