import {
  TaskGuest,
  acceptQuoteGuest as ACCEPT_QUOTE_GUEST,
  createUploadTaskUrlGuest as CREATE_UPLOADTASK_URL_GUEST,
  UpdateTaskQuotePublicStatus,
  TaskSignatureStatus,
  getTaskPublic,
  TaskQuoteStatus,
} from '@admiin-com/ds-graphql';
import GuestLayout from './GuestLayout';
import PdfViewer from '../PdfViewer/PdfViewer';
import { useRef, useState } from 'react';
import { useDocumentGuestUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import { styled } from '@mui/material';
import {
  useSnackbar,
  WBButton,
  WBContainer,
  WBFlex,
  WBLink,
  WBTypography,
} from '@admiin-com/ds-web';
import { BottomDrawer } from '../ESignature/BottomDrawer';
import { t } from 'i18next';
import { gql, useMutation } from '@apollo/client';
import AddSignatureModal from '../AddSignatureModal/AddSignatureModal';
import PSPDFKit from 'pspdfkit';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import OnboardingMessage from '../InvoiceCreateForm/OnboardingMessage';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
}
const SupportEmail = 'support@admiin.com';
export const QuoteAccept = (props: Props) => {
  const pdfInstanceRef = useRef<any>(null);
  const navigate = useNavigate();
  const [showAddSignModal, setShowAddSignModal] = useState(false);

  const { documentUrl } = useDocumentGuestUrl(props.token);

  const [acceptQuoteGuest] = useMutation(gql(ACCEPT_QUOTE_GUEST));
  const [createUploadTaskUrlGuest] = useMutation(
    gql(CREATE_UPLOADTASK_URL_GUEST)
  );
  const isSignatureRequired =
    props.taskGuest.quoteRequiresSignature &&
    props.taskGuest.signatureStatus === TaskSignatureStatus.PENDING_SIGNATURE;

  const handleAcceptQuoteWithSignature = async () => {
    setShowAddSignModal(true);
  };

  const [loading, setLoading] = useState(false);

  const signatureAddHandler = async (signatureBlob?: string | Blob) => {
    try {
      setLoading(true);
      const signatureAttachmentId =
        await pdfInstanceRef.current.createAttachment(signatureBlob);

      const signatureAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
        pageIndex: 0,
        boundingBox: new PSPDFKit.Geometry.Rect({
          left: 10,
          top: 700,
          width: 100,
          height: 100,
        }),
        id: uuidv4(),
        lockedContents: true,
        fontSize: 12,
        customData: {
          status: 'ACTIONED',
          type: 'SIGNATURE',
          signerId: props.taskGuest?.to?.id,
          signerType: 'CONTACT',
          name: props.taskGuest?.to?.name,
        },
        horizontalAlign: 'left',
        verticalAlign: 'bottom',
        backgroundColor: PSPDFKit.Color.TRANSPARENT,
        readOnly: false,
        contentType: 'image/jpeg',
        imageAttachmentId: signatureAttachmentId,
      });

      const dateAnnotation = new PSPDFKit.Annotations.TextAnnotation({
        pageIndex: 0,
        boundingBox: new PSPDFKit.Geometry.Rect({
          left: 510,
          top: 765,
          width: 100,
          height: 20,
        }),
        id: uuidv4(),
        lockedContents: true,
        fontSize: 12,
        customData: {
          status: 'ACTIONED',
          type: 'DATE',
        },
        horizontalAlign: 'left',
        verticalAlign: 'center',
        backgroundColor: PSPDFKit.Color.TRANSPARENT,
        readOnly: false,
        text: {
          format: 'plain',
          value: new Date().toLocaleDateString() || '2024',
        },
      });

      await pdfInstanceRef.current.create(signatureAnnotation);
      await pdfInstanceRef.current.create(dateAnnotation);

      const uploadUrlData = await createUploadTaskUrlGuest({
        variables: {
          input: {
            token: props.token,
          },
        },
      });

      const uploadUrl = uploadUrlData.data.createUploadTaskUrlGuest.url;

      const pdf = await pdfInstanceRef?.current?.exportPDF({
        flatten: true,
      });

      const response = await fetch(uploadUrl, {
        method: 'PUT',
        body: pdf,
        headers: {
          'Content-Type': pdf.mimeType,
        },
      });
      if (!response.ok) {
        console.error('Error uploading file:', await response.text());
      } else {
        console.log('File uploaded successfully');
      }
    } catch (e: any) {
      showSnackbar({
        message: t(e.message, { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
      throw e;
    } finally {
      setLoading(false);
    }
    acceptQuote();
  };
  const showSnackbar = useSnackbar();

  const [quoteAccepted, setQuoteAccepted] = useState(false);
  const [newInvoiceToken, setNewInvoiceToken] = useState<string | null>(null);
  const pdfContentRef = useRef<any>(null);

  const acceptQuote = async () => {
    try {
      setLoading(true);
      const input = {
        token: props.token,
        quoteStatus: UpdateTaskQuotePublicStatus.ACCEPTED,
      };

      let annotationsString = '';
      if (isSignatureRequired) {
        const allAnnotations = await Promise.all(
          Array.from({ length: pdfInstanceRef.current?.totalPageCount }).map(
            (_, pageIndex) => pdfInstanceRef.current.getAnnotations(pageIndex)
          )
        );

        const flattenedAnnotations = allAnnotations.flat();
        const instantJSON = await pdfInstanceRef.current.exportInstantJSON(
          flattenedAnnotations
        );
        annotationsString = JSON.stringify(instantJSON);

        pdfContentRef.current = await pdfInstanceRef?.current?.exportPDF({
          flatten: true,
        });
      }

      const { data } = await acceptQuoteGuest({
        variables: {
          input: {
            ...input,
            annotations: isSignatureRequired ? annotationsString : undefined,
          },
        },
        refetchQueries: [
          {
            query: gql(getTaskPublic),
            variables: {
              token: props.token,
            },
          },
        ],
        awaitRefetchQueries: true,
      });
      console.log(data);
      setQuoteAccepted(true);
      const newToken = data?.acceptQuoteGuest?.token;
      if (newToken) {
        setNewInvoiceToken(newToken);
      }
    } catch (e: any) {
      showSnackbar({
        message: t(e.message, { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const handleAcceptQuote = async () => {
    if (isSignatureRequired) {
      handleAcceptQuoteWithSignature();
      return;
    }

    await acceptQuote();
  };

  const handleDownload = async () => {
    const blob = new Blob([pdfContentRef.current], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = props.taskGuest?.reference ?? 'document.pdf';
    a.click();
    URL.revokeObjectURL(url);
  };

  const isQuotePending =
    props.taskGuest.quoteStatus === TaskQuoteStatus.PENDING_ACCEPTANCE;
  if (
    quoteAccepted &&
    props.taskGuest.quoteRequiresSignature &&
    props.taskGuest.automaticGenerateInvoice &&
    newInvoiceToken
  ) {
    return (
      <GuestLayout taskGuest={props.taskGuest} token={props.token}>
        <OnboardingMessage
          lottiePath={'/tick.lottie'}
          title={t('quoteSignedTitle', { ns: 'taskbox' })}
          description={t('quoteSignedSubTitle', {
            ns: 'taskbox',
            entity: props.taskGuest.to?.name,
          })}
          onGetStarted={() => {
            handleDownload();
          }}
          onClose={() => {
            navigate(`/guest/pay-task?token=${newInvoiceToken}`);
          }}
          closeButtonText="Go to invoice"
          buttonTitle={t('downloadDocument', { ns: 'taskbox' })}
        />
      </GuestLayout>
    );
  }
  if (
    quoteAccepted &&
    props.taskGuest.quoteRequiresSignature &&
    !props.taskGuest.automaticGenerateInvoice
  ) {
    return (
      <GuestLayout taskGuest={props.taskGuest} token={props.token}>
        <OnboardingMessage
          lottiePath={'/tick.lottie'}
          title={t('quoteSignedTitle', { ns: 'taskbox' })}
          description={t('quoteSignedSubTitle', {
            ns: 'taskbox',
            entity: props.taskGuest.to?.name,
          })}
          onGetStarted={() => {
            handleDownload();
          }}
          onClose={() => {
            setQuoteAccepted(false);
          }}
          closeButtonText="Close this screen"
          buttonTitle={t('downloadDocument', { ns: 'taskbox' })}
        />
      </GuestLayout>
    );
  }

  if (
    quoteAccepted &&
    !props.taskGuest.quoteRequiresSignature &&
    !props.taskGuest.automaticGenerateInvoice
  ) {
    return (
      <GuestLayout taskGuest={props.taskGuest} token={props.token}>
        <OnboardingMessage
          lottiePath={'/tick.lottie'}
          title={t('quoteAccepted', { ns: 'taskbox' })}
          description={t('quoteAcceptedSubTitle', {
            ns: 'taskbox',
            entity: props.taskGuest.to?.name,
          })}
          onClose={() => {
            setQuoteAccepted(false);
          }}
          closeButtonText="Close this screen"
        />
      </GuestLayout>
    );
  }

  return (
    <GuestLayout taskGuest={props.taskGuest} token={props.token}>
      <WBFlex width={'100%'} px={4} flex={1}>
        <PdfContainer maxWidth="md">
          <PdfViewer ref={pdfInstanceRef} documentUrl={documentUrl} />
        </PdfContainer>
      </WBFlex>
      <BottomDrawer showAlways>
        <WBFlex
          flexDirection={['column']}
          p={1}
          sx={{ bgcolor: 'background.paper' }}
        >
          <WBFlex
            gap={[2, 3]}
            justifyContent={'space-between'}
            p={1}
            flexDirection={['column-reverse', 'row']}
            alignItems={['flex-start', 'center']}
            px={[1, 3]}
          >
            <WBFlex gap={1}>
              <WBTypography>
                {t('Need help? Contact', { ns: 'taskbox' })}
              </WBTypography>
              <WBLink href={`mailto:${SupportEmail}`}>
                {t(SupportEmail, { ns: 'taskbox' })}
              </WBLink>
            </WBFlex>
            <WBButton
              sx={{ width: ['100%', 'auto'] }}
              onClick={handleAcceptQuote}
              loading={loading}
              disabled={!isQuotePending}
            >
              {t(
                isQuotePending
                  ? 'Accept Quote'
                  : props.taskGuest.quoteStatus ?? 'ACCEPTED',
                { ns: 'taskbox' }
              )}
            </WBButton>
          </WBFlex>
        </WBFlex>
      </BottomDrawer>
      <AddSignatureModal
        open={showAddSignModal}
        handleClose={() => setShowAddSignModal(false)}
        handleSave={signatureAddHandler}
        isGuest
      />
    </GuestLayout>
  );
};
const PdfContainer = styled(WBContainer)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  flexDirection: 'row',
  display: 'flex',
  paddingTop: theme.spacing(5),
  height: `calc(100% - ${theme.spacing(10)})`,
  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(15)})`,
  },
}));
