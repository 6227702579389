import React, { useCallback, useRef } from 'react';
import {
  PaymentFrequency,
  TaskType,
  updateTask as UPDATE_TASK,
  CreateTaskInput,
  TaskDirection,
  S3UploadLevel,
  S3UploadInput,
  S3UploadType,
  PaymentType,
  Task,
  selectedEntityIdInVar,
  Contact,
  Entity,
  TaskSettlementStatus,
  CreateTaskStatus,
  UpdateTaskStatus,
  S3Upload,
  InvoiceStatus,
  FromToType,
  LineItemInput,
  OtherRecipientInput,
  PaymentTerms,
  FutureTaskInput,
} from '@admiin-com/ds-graphql';
import { FormProvider, useForm } from 'react-hook-form';
import { S3Upload as S3UploadCommon } from '@admiin-com/ds-common';

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { TaskSubmitButtons } from './TaskSubmitButton';
import { TaskCreationModal } from './TaskCreationModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isEntityOrContact } from '../../helpers/entities';
import VerificationDlg from '../VerificationDlg/VerificationDlg';
import {
  createTaskDocumentUrl as CREATE_TASK_DOCUMENT_URL,
  tasksByFirmByIdContactId as TASKS_BY_FIRM_BY_ID_CONTACT_ID,
} from '@admiin-com/ds-graphql';
import { useSnackbar } from '@admiin-com/ds-web';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { useTaskToName } from '../../hooks/useTaskToName/useTaskToName';
import AddPaymentMethodModal from '../../components/AddPaymentMethodModal/AddPaymentMethodModal';
import { ContactCreateModal } from '../ContactCreateModal/ContactCreateModal';
import { useTranslation } from 'react-i18next';
import TaskCreatModal from '../../components/TaskCreateModal';
import { getAmountFromLineItems } from '../../helpers/tasks';
import { useMediaQuery, useTheme } from '@mui/material';
import { isAllSigned } from '../../helpers/signature';
import { useSignedDocumentUpload } from '../../hooks/useSignedDocumentUpload';
import { useCreateTask } from '../../hooks/useCreateTask';
import useSelectedFirm from '../../hooks/useSelectedFirm/useSelectedFirm';
import { useFirmId } from '../../hooks/useClientWorkflow/useClientWorkflow';

interface TaskCreationProps {
  open: boolean;
  direction?: TaskDirection;
  contact?: Contact & {
    entity?: Entity | undefined;
  };
  client?: Entity;
  from?: Entity;
  task?: Task | undefined | null;
  to?: Contact;
  handleCloseModal: () => void;
}

export type settlementStatus = 'Payable' | 'Refundable';
type PageType = 'Add' | 'Sign' | 'Summary' | 'Direction';
export type Signer = {
  id?: string;
  email: string;
  name: string;
  signerType: 'CONTACT' | 'ENTITY_USER' | 'GUEST';
  entityId?: string;
  secondName?: string;
  // No Need to send backend
  color?: any;
  data?: any;
  noLookup?: boolean;
};
//TODO: is this correct for types?
// Then in your form data type
export type BillCreateFormData = {
  from: any; //TODO: types Entity or AutocompleteResult
  to: any; //TODO: types Contact or Entity
  type: TaskType;
  paymentFrequency: PaymentFrequency;
  amount: string;
  dueAt: string;
  reference: string;
  bpayReferenceNumber?: string;
  notes: string;
  lodgementAt: string;
  documents: (S3UploadCommon | null)[];
  annotations: any;
  gstInclusive: boolean;
  numberOfPayments: number;
  settlementStatus?: TaskSettlementStatus | null;
  paymentAt: string;
  paymentTypes?: PaymentType.PAY_NOW | PaymentType.INSTALLMENTS;
  allowExtendedTerms?: boolean;
  maximumInstallments?: number;
  firstPaymentAt: string;
  lineItems: LineItemInput[];
  invoiceStatus: InvoiceStatus;
  supportDocuments: S3UploadCommon[];
  signers: Signer[];
  sendSMS: boolean;
  otherRecipients: OtherRecipientInput[];
  sendCopyToContact: boolean;
  automaticGenerateInvoice: boolean;
  automaticInvoicePaymentAt: string;
  automaticInvoicePaymentTerms: PaymentTerms;
  quoteRequiresSignature: boolean;
  futureTaskInput?: {
    from: any;
    to: any;
    amount: number;
    dueAt: string;
    reference: string;
    issuedAt: string;
    bpayReferenceNumber: string;
    paymentFrequency: PaymentFrequency;
    lineItems: LineItemInput[];

    numberOfPayments?: number;
  };
};

export const BillCreationContext = React.createContext<any>(null);

export function TaskCreation({
  open,
  handleCloseModal,
  direction,
  contact,
  to,
  client,
  task,
  from,
}: TaskCreationProps) {
  const [page, setPage] = React.useState<PageType>(
    direction ? 'Add' : 'Direction'
  );
  const { selectedFirm } = useSelectedFirm();
  const [taskDirection, setTaskDirection] = React.useState<
    TaskDirection | undefined
  >(direction);
  const pdfSignatureRef = useRef(null);
  const [showUpload, setShowUpload] = React.useState<boolean>(false);
  const [showSignFields, setShowSignFields] = React.useState<boolean>(false);
  const [prevPage, setPrevPage] = React.useState<PageType | null>(null);
  const [recevingAccountModal, setRecevingAccountModal] =
    React.useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const taskProp = task;
  const methods = useForm<BillCreateFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      documents: [],
      lineItems: [],
      invoiceStatus: InvoiceStatus.INVOICE,
      signers: [],
      otherRecipients: [],
      sendCopyToContact: true,
    },

    values: taskProp
      ? {
          reference: taskProp?.reference ?? '',
          bpayReferenceNumber: taskProp?.bpayReferenceNumber ?? '',
          notes: taskProp?.notes ?? '',
          gstInclusive: false,
          paymentFrequency: taskProp.paymentFrequency ?? PaymentFrequency.ONCE,
          dueAt: taskProp?.dueAt ?? '',
          from: null,
          to: null,
          amount: taskProp?.amount?.toString() ?? '',
          lodgementAt: taskProp?.lodgementAt ?? '',
          paymentAt: taskProp?.paymentAt ?? '',
          documents: [],
          annotations: taskProp?.annotations ?? null,
          settlementStatus: taskProp?.settlementStatus ?? null,
          firstPaymentAt: taskProp?.paymentAt ?? '',
          numberOfPayments: taskProp?.numberOfPayments ?? 1,
          invoiceStatus: taskProp?.invoiceStatus ?? InvoiceStatus.INVOICE,
          lineItems: [],
          supportDocuments: [],
          type: taskProp?.type ?? TaskType.SIGN_ONLY,
          sendSMS: false,
          signers: [],
          sendCopyToContact: true,
          automaticGenerateInvoice: false,
          automaticInvoicePaymentAt: '',
          automaticInvoicePaymentTerms: PaymentTerms.FORTNIGHT,
          otherRecipients: [],
          quoteRequiresSignature: false,
        }
      : undefined,
  });

  const getTaskName = useTaskToName();
  const setFromTo = async () => {
    const { data: to } = await getTaskName(task?.toId, true);
    const { data: from } = await getTaskName(task?.fromId, true);
    if (to && from) {
      methods.setValue('to', to, { shouldValidate: true, shouldDirty: true });
      methods.setValue('from', from, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const setDocuments = async () => {
    if (taskProp && taskProp.documents && taskProp.documents.length > 0) {
      const documentUrlData = await createTaskDocumentUrl({
        variables: {
          input: {
            taskId: taskProp.id,
            entityId: taskProp.entityId,
          },
        },
      });
      const url = documentUrlData?.data?.createTaskDocumentUrl?.url;
      if (url)
        methods.setValue(
          'documents',
          taskProp.documents.map(
            (document: S3Upload | null): S3UploadCommon | null => ({
              identityId: document?.identityId,
              key: document?.key ?? '',
              src: url,
              level: document?.level as S3UploadLevel,
              type: document?.type as S3UploadType,
            })
          )
        );
    }
  };
  const [createTaskDocumentUrl] = useMutation(gql(CREATE_TASK_DOCUMENT_URL));

  React.useEffect(() => {
    if (taskProp) {
      if (taskProp.direction) setTaskDirection(taskProp.direction);
      setShowUpload(false);
      setShowSignFields(false);
      setPrevPage(null);
      if (taskProp.amount)
        methods.setValue('amount', taskProp.amount.toString());
      setFromTo();
      setDocuments();
      if (taskProp.lineItems)
        methods.setValue(
          'lineItems',
          taskProp.lineItems.map((lineItem, index) => ({
            ...lineItem,
            id: (index + 1).toString(),
          }))
        );
      if (taskProp.otherRecipients)
        methods.setValue(
          'otherRecipients',
          taskProp.otherRecipients.map((recipient) => ({
            email: recipient?.email ?? '',
            sendOnCompletion: recipient?.sendOnCompletion ?? true,
          }))
        );
    }
  }, [taskProp, open]);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (contact) {
      if (taskDirection === TaskDirection.RECEIVING) {
        methods.setValue('from', contact);
      } else if (taskDirection === TaskDirection.SENDING) {
        methods.setValue('to', contact);
      }
    }
    if (client) {
      if (taskDirection === TaskDirection.RECEIVING) {
        methods.setValue('to', client);
      } else if (taskDirection === TaskDirection.SENDING) {
        methods.setValue('from', client);
      }
    }
  }, [taskDirection, contact, contact]);

  React.useEffect(() => {
    if (from) {
      methods.setValue('from', from);
    }
  }, [from]);

  React.useEffect(() => {
    if (to) {
      methods.setValue('to', to);
    }
  }, [to]);

  const [saved, setSaved] = React.useState<boolean>(false);
  const [submitted, setSubmitted] = React.useState<boolean>(false);
  const handleSetPage = React.useCallback((newPage: PageType) => {
    const type = methods.watch('type');
    let prevPage: null | PageType;
    switch (newPage) {
      case 'Direction':
        prevPage = null;
        break;
      case 'Add':
        prevPage = 'Direction';
        break;
      case 'Summary':
        if (type === 'SIGN_ONLY' || type === 'SIGN_PAY') prevPage = 'Sign';
        else prevPage = 'Add';
        break;
      case 'Sign':
        prevPage = 'Add';
        break;
    }
    setPrevPage(prevPage);
    setPage(newPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [_, setSearchParams] = useSearchParams();
  const handleClose = React.useCallback(() => {
    handleCloseModal();
    setSearchParams({ paybillCreation: '' });
    setSaved(false);
    setSubmitted(false);
    setPage('Direction');
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [draftLoading, setDraftLoading] = React.useState(false);
  const [createTask] = useCreateTask();
  const [updateTask] = useMutation(gql(UPDATE_TASK));
  const navigate = useNavigate();
  const [createError, setCreateError] = React.useState<any>(null);
  const apolloClient = useApolloClient();

  const [openVerificationModal, setOpenVerificationModal] =
    React.useState<boolean>(false);
  const [entityToBeVerified, setEntityToBeVerified] = React.useState<
    Entity | undefined
  >();

  const currentEntityId = useCurrentEntityId();

  const { firmId } = useFirmId();
  const [verified, setVerified] = React.useState(false);
  const [updatingContact, setUpdatingContact] = React.useState<
    Contact | undefined
  >(undefined);
  const [openContactModal, setOpenContactModal] =
    React.useState<boolean>(false);

  const handleClosContactModal = () => {
    setOpenContactModal(false);
    setUpdatingContact(undefined);
  };

  const { uploadSignedDocumentFromS3 } = useSignedDocumentUpload();

  const createTaskWithDraft = async (isDraft = false) => {
    const values = methods.watch();
    console.log(values);
    setEntityToBeVerified(values.from);

    // if (!isDraft && !verified) {
    //   if (
    //     taskDirection === TaskDirection.SENDING &&
    //     values.type !== TaskType.SIGN_ONLY
    //   ) {
    //     if (values.from && !isVerifiedEntity(values.from)) {
    //       setOpenVerificationModal(true);
    //       throw new Error('ENTITY_NOT_VERIFIED');
    //     }
    //   }
    // }

    const documents: S3UploadInput[] = [];

    for (const document of values.documents) {
      const isSigned = isAllSigned(values.annotations);
      if (document && !isSigned)
        documents.push({
          level: document.level as S3UploadLevel,
          key: document.key,
          identityId: document.identityId,
          type: document.type as S3UploadType,
        });
      else if (document) {
        const newKey = await uploadSignedDocumentFromS3(
          {
            key: document.key,
            identityId: document.identityId,
            level: document.level as S3UploadLevel,
            type: document.type as S3UploadType,
          },
          values.annotations
        );
        documents.push({
          level: document.level as S3UploadLevel,
          key: newKey,
          identityId: document.identityId,
          type: document.type as S3UploadType,
        });
      }
    }

    const isTax = values.from?.metadata?.subCategory === 'TAX';

    let dueAt: string;
    if (isTax) {
      dueAt = values.paymentAt || values.lodgementAt || values.dueAt;
    } else if (
      values.paymentFrequency !== PaymentFrequency.ONCE &&
      values.firstPaymentAt
    ) {
      dueAt = values.firstPaymentAt;
    } else {
      dueAt = values.dueAt;
    }

    const payableInfo = {
      // amount: Math.round(Number(values?.amount || 1) * 100),
      lineItems: values.lineItems.map((lineItem) => ({
        description: lineItem.description,
        quantity: lineItem.quantity,
        unitAmount: lineItem.unitAmount,
        taxType: lineItem.taxType,
        lineAmount: lineItem.lineAmount,
        serviceId: lineItem.serviceId,
        newService: lineItem.newService,
      })),
      amount: Math.round(
        Number(getAmountFromLineItems(values.lineItems).total) * 100
      ),
      // tax: Math.round(Number(getAmountFromLineItems(values.lineItems).totalGST / 100) * 100),
      paymentFrequency:
        (values.type === TaskType.SIGN_ONLY
          ? undefined
          : values.paymentFrequency) ?? PaymentFrequency.ONCE,
      isUnlimitedRecurring: values.paymentFrequency !== PaymentFrequency.ONCE,
      gstInclusive: values.gstInclusive,
      // gstAmount: values.gstInclusive
      //   ? Math.round((Number(values.amount) * 100) / GST_RATE)
      //   : 0,
      paymentTypes: [
        PaymentType.PAY_NOW,
        PaymentType.SCHEDULED,
        ...(isTax ||
        (values.allowExtendedTerms &&
          values.paymentFrequency === PaymentFrequency.ONCE)
          ? [PaymentType.INSTALLMENTS]
          : []),
      ],
      maximumInstallments: !isTax && values.allowExtendedTerms ? 3 : null,
      paymentAt: values.paymentAt === '' ? undefined : values.paymentAt,
    };

    let otherRecipients = values.otherRecipients;
    if (values.sendCopyToContact && values.from?.email) {
      otherRecipients = [
        ...otherRecipients,
        {
          email: values.from?.email,
          sendOnCompletion: true,
        },
      ];
    }

    const signableInfo = {
      documents:
        documents.length > 0
          ? documents
          : [
              {
                level: S3UploadLevel.private,
                type: S3UploadType.PDF,
                key: 'blank',
                identityId: '',
              },
            ],
      annotations: values.annotations ? values.annotations : null,
      otherRecipients: otherRecipients,
    };
    const taxInfo = {
      lodgementAt: values.lodgementAt === '' ? undefined : values.lodgementAt,
      settlementStatus: isTax ? values.settlementStatus : undefined,
    };
    const payable = values.type !== TaskType.SIGN_ONLY;
    const signable = values.type !== TaskType.PAY_ONLY;
    const task: CreateTaskInput = {
      fromId: values.from?.id,
      fromType: isEntityOrContact(values.from) ?? FromToType.CONTACT,
      toId: values.to?.id,
      toType: isEntityOrContact(values.to) ?? FromToType.CONTACT,
      type:
        values.type ??
        (values.invoiceStatus ? TaskType.PAY_ONLY : TaskType.SIGN_ONLY),
      reference: values.reference,
      bpayReferenceNumber: values.bpayReferenceNumber ?? null,
      notes: values.notes ?? '',
      direction: taskDirection ?? TaskDirection.SENDING,
      sendSMS: values.sendSMS,
      dueAt,
      ...(isTax ? taxInfo : {}),
      ...(payable ? payableInfo : {}),
      ...(signable ? signableInfo : {}),
      paymentTypes: payable ? payableInfo.paymentTypes : [],
      documents: signableInfo.documents,
      //@ts-ignore
      supportDocuments: values.supportDocuments?.map(
        ({ identityId, key, level, type, name }) => ({
          identityId,
          key,
          level,
          type,
          name,
        })
      ),
      invoiceStatus: values.invoiceStatus ?? InvoiceStatus.INVOICE,
    };
    if (task.invoiceStatus === InvoiceStatus.QUOTE) {
      task.type = TaskType.QUOTE;
      // TODO: uncommenet below after schema is updated
      if (values.automaticGenerateInvoice) {
        task.automaticGenerateInvoice = values.automaticGenerateInvoice;
        task.automaticInvoicePaymentTerms = values.automaticInvoicePaymentTerms;
      }
      if (values.quoteRequiresSignature) {
        task.quoteRequiresSignature = values.quoteRequiresSignature;
      }
    }
    if (values.paymentFrequency !== PaymentFrequency.ONCE) {
      task.numberOfPayments = values.numberOfPayments;
    } else {
      task.numberOfPayments = 1;
    }

    if (isDraft) {
      task.status = CreateTaskStatus.DRAFT;
      if (!task.amount) task.amount = 1;
    }
    if (values.futureTaskInput) {
      task.type = TaskType.SIGN_PAY;
      task.futureLinkedTask = {
        fromId: values.futureTaskInput.from?.id,
        fromType:
          isEntityOrContact(values.futureTaskInput.from) ?? FromToType.CONTACT,
        toId: values.futureTaskInput.to?.id,
        toType:
          isEntityOrContact(values.futureTaskInput.to) ?? FromToType.CONTACT,
        lineItems: values.futureTaskInput.lineItems,
        bpayReferenceNumber: values.futureTaskInput.bpayReferenceNumber,
        numberOfPayments:
          values.futureTaskInput.paymentFrequency !== PaymentFrequency.ONCE
            ? values.futureTaskInput.numberOfPayments
            : 1,
        paymentFrequency: values.futureTaskInput.paymentFrequency,
        reference: values.futureTaskInput.reference,
        amount: values.futureTaskInput.amount,
        dueAt: values.futureTaskInput.dueAt,
      };
    }
    let createdTaskData;
    if (taskProp) {
      createdTaskData = await updateTask({
        variables: {
          input: {
            id: taskProp.id,
            ...task,
            entityId: currentEntityId,
            status: isDraft
              ? CreateTaskStatus.DRAFT
              : UpdateTaskStatus.INCOMPLETE,
            invoiceStatus: undefined,
          },
        },
      });
      const createdTask = createdTaskData?.data?.updateTask;
      return createdTask;
    } else {
      createdTaskData = await createTask({
        variables: {
          input: { ...task },
        },
        refetchQueries:
          firmId && selectedFirm?.id
            ? [
                {
                  query: gql(TASKS_BY_FIRM_BY_ID_CONTACT_ID),
                  variables: {
                    entityId: client?.id || currentEntityId,
                    contactId: client?.id || currentEntityId,
                    firmIdBy: selectedFirm?.id,
                    limit: 20,
                  },
                },
              ]
            : [],
      });

      const createdTask = createdTaskData?.data?.createTask;
      return createdTask;
    }
  };

  const submitESignature = async (redirect: boolean) => {
    methods.setValue('type', TaskType.SIGN_ONLY);
    if (taskDirection === TaskDirection.SENDING) methods.setValue('to', null);

    return handleSubmit(redirect);
  };

  const handleSubmit = useCallback(
    async (redirect = true) => {
      try {
        setLoading(true);
        const createdTask = await createTaskWithDraft(false);
        setSubmitted(true);
        if (redirect)
          setTimeout(() => {
            selectCreateTask(createdTask);
          }, 2500);
        else return createdTask;
      } catch (err: any) {
        console.log('error creating draft task: ', err);
        if (err.message !== 'ENTITY_FROM_NOT_VERIFIED') {
          if (err.message === 'ENTITY_FROM_MISSING_DISBURSEMENT_METHOD') {
            setRecevingAccountModal(true);
            throw new Error('RECEIVING_ACCOUNT_NOT_EXIST');
          } else if (
            err.message === 'CONTACT_FROM_MISSING_DISBURSEMENT_METHOD'
          ) {
            setUpdatingContact(methods.watch().from);
            setOpenContactModal(true);
            showSnackbar({
              message: t('contactFromMissingDisbursementMethod', {
                ns: 'contacts',
              }),
              severity: 'warning',
              horizontal: 'right',
              vertical: 'bottom',
            });
          } else {
            showSnackbar({
              message: err?.message,
              severity: 'error',
              horizontal: 'right',
              vertical: 'bottom',
            });
            setCreateError(err);
          }
        } else if (err.message === 'ENTITY_FROM_NOT_VERIFIED') {
          setEntityToBeVerified(methods.watch().from);
          setOpenVerificationModal(true);
        }
        console.log('error updating entity: ', err);
        throw err;
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [handleClose, taskDirection, verified, methods]
  );
  const showSnackbar = useSnackbar();
  const handleSave = async (redirect = true) => {
    // Hanlde create task with status of draft here and do not validate in this function
    if (draftLoading) return;
    if (!(await methods.trigger('from')) || !(await methods.trigger('to')))
      return;
    try {
      setDraftLoading(true);
      const createdTask = await createTaskWithDraft(true);
      setSaved(true);
      setDraftLoading(false);
      if (redirect)
        setTimeout(() => {
          selectCreateTask(createdTask);
        }, 2500);
      else return createdTask;
    } catch (err: any) {
      console.log('error updating entity: ', err);
      // setCreateError(err);
      showSnackbar({
        message: err?.message,
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
      setDraftLoading(false);
      throw err;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const selectCreateTask = (createdTask: Task) => {
    handleClose();
    if (client) return;
    selectedEntityIdInVar(createdTask.entityId);
    apolloClient.cache.evict({ fieldName: 'selectedEntityId' });
    apolloClient.cache.gc();
    localStorage.setItem('selectedEntityId', createdTask.entityId);
    if (!isMobile)
      navigate(
        `/taskbox/${createdTask?.id ?? ''}?direction=${
          createdTask.direction
        }&status=Due`
      );
    else navigate(`/taskbox?direction=${createdTask.direction}&status=Due`);
  };

  return (
    <BillCreationContext.Provider
      value={{
        pdfSignatureRef,
        saved,
        setPage: handleSetPage,
        client,
        handleSave,
        showUpload,
        showSignFields,
        loading,
        submitted,
        prevPage,
        page,
        open,
        handleClose,
        task: taskProp,
        createError,
        taskDirection,
        setTaskDirection,
        setShowUpload,
        setShowSignFields,
        handleSubmit,
        handleConfirm: handleSubmit,
        submitESignature,
        handleDraft: handleSave,
        selectCreateTask,
      }}
    >
      <FormProvider {...methods}>
        <TaskCreatModal />
      </FormProvider>
      {entityToBeVerified ? (
        <VerificationDlg
          entity={entityToBeVerified}
          onSuccess={() => {
            setVerified(true);
          }}
          open={openVerificationModal}
          onClose={() => setOpenVerificationModal(false)}
        />
      ) : null}
      <AddPaymentMethodModal
        open={recevingAccountModal}
        type="ReceivingAccount"
        onSuccess={() => {
          setTimeout(() => {
            setRecevingAccountModal(false);
            handleSubmit();
          }, 1000);
        }}
        handleClose={() => setRecevingAccountModal(false)}
      />
      <ContactCreateModal
        entityId={updatingContact?.entityId}
        selected={updatingContact}
        onSuccess={() => {
          setTimeout(() => {
            handleClosContactModal();
            handleSubmit();
          }, 1000);
        }}
        handleCloseModal={handleClosContactModal}
        open={openContactModal}
      />
    </BillCreationContext.Provider>
  );
}

TaskCreation.SubmitButtons = React.memo(TaskSubmitButtons);
TaskCreation.Modal = React.memo(TaskCreationModal);

export const useTaskCreationContext = () => {
  const context = React.useContext(BillCreationContext);
  if (!context) {
    throw new Error(
      'useTaskCreationContext must be used within a TaskCreationProvider'
    );
  }
  return context;
};
