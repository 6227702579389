import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
} from '@mui/x-data-grid';
import React from 'react';

export const useSingleClickTableParams = () => {
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      return {
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        ...(!(params.cellMode === 'edit' && params.field === 'description')
          ? {
              [params.id]: {
                ...Object.keys(prevModel[params.id] || {}).reduce(
                  (acc, field) => ({
                    ...acc,
                    [field]: { mode: GridCellModes.View },
                  }),
                  {}
                ),
                [params.field]: { mode: GridCellModes.Edit },
              },
            }
          : {}),
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback(
    (newModel: GridCellModesModel) => {
      setCellModesModel(newModel);
    },
    []
  );

  return {
    cellModesModel,
    onCellClick: handleCellClick,
    onCellModesModelChange: handleCellModesModelChange,
  };
};
