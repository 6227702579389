import {
  createUploadTaskUrlGuest as CREATE_UPLOADTASK_URL_GUEST,
  getTaskPublic as GET_TASK_PUBLIC,
  TaskGuest,
  updateTaskPublic as UPDATE_TASK_PUBLIC,
} from '@admiin-com/ds-graphql';
import { useSnackbar, WBBox, WBFlex, WBTypography } from '@admiin-com/ds-web';
import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GuestSignDesktop } from './GuestSignDesktop';
import { GuestSignMobile } from './GuestSignMobile';
import { PdfScrollProvider } from '../PdfSign/PdfScrollContext';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useSignedDocumentUpload } from '../../hooks/useSignedDocumentUpload';
import { useDocumentGuestUrl } from '../../hooks/useDocumentUrl/useDocumentUrl';
import { Annotation } from 'pspdfkit';
import React, { useState } from 'react';

interface Props {
  taskGuest: TaskGuest;
  token?: string;
}
export const GuestSignature = (props: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const headerColor = theme.palette.mode === 'dark' ? 'white' : 'white';
  const [updateTaskPublic] = useMutation(gql(UPDATE_TASK_PUBLIC));
  const showSnackbar = useSnackbar();
  const { documentUrl } = useDocumentGuestUrl(props.token);
  const [createUploadTaskUrlGuest] = useMutation(
    gql(CREATE_UPLOADTASK_URL_GUEST)
  );
  const [disableDownload, setDisableDownload] = React.useState(false);

  const [getTaskPublic] = useLazyQuery(gql(GET_TASK_PUBLIC));
  const { uploadSignedDocumentFromSignedUrl, downloadDocument } =
    useSignedDocumentUpload();

  const [postSignedAnnotations, setPostSignedAnnotations] = useState<
    string | null
  >(null);

  const updateAnnotations = async (annoations: string) => {
    try {
      const taskGuestData = await getTaskPublic({
        variables: { token: props.token },
        fetchPolicy: 'no-cache',
      });
      const taskGuest = taskGuestData?.data?.getTaskPublic;
      const preSignedAnnotations = JSON.parse(
        taskGuest.annotations
      )?.annotations;
      const newAnnotations = JSON.parse(annoations)?.annotations;
      const postSignedAnnotations = preSignedAnnotations.map(
        (annotation: any) => {
          const userAnnotation = newAnnotations.find(
            (newAnnotation: Annotation) =>
              newAnnotation?.customData?.id === annotation?.customData?.id
          );
          return userAnnotation ?? annotation;
        }
      );

      const postSignedAnnotationsString = JSON.stringify({
        ...JSON.parse(taskGuest.annotations),
        annotations: postSignedAnnotations,
        attachments: {
          ...JSON.parse(taskGuest.annotations).attachments,
          ...(JSON.parse(annoations).attachments ?? {}),
        },
      });

      setPostSignedAnnotations(postSignedAnnotationsString);
      const input: any = {
        token: props.token,
        annotations: postSignedAnnotationsString,
      };

      if (taskGuest.isFinalSigner) {
        const uploadUrlData = await createUploadTaskUrlGuest({
          variables: {
            input: {
              token: props.token,
            },
          },
        });

        console.log('postSignedAnnotations', postSignedAnnotations);

        const uploadUrl = uploadUrlData.data.createUploadTaskUrlGuest.url;

        await uploadSignedDocumentFromSignedUrl(
          documentUrl as string,
          uploadUrl,
          postSignedAnnotationsString
        );
        setDisableDownload(true);
        setTimeout(() => {
          setDisableDownload(false);
        }, 3000);

        await updateTaskPublic({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: gql(GET_TASK_PUBLIC),
              variables: {
                token: props.token,
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      } else {
        await updateTaskPublic({
          variables: {
            input,
          },
          refetchQueries: [
            {
              query: gql(GET_TASK_PUBLIC),
              variables: {
                token: props.token,
              },
            },
          ],
          awaitRefetchQueries: true,
        });
      }
    } catch (e: any) {
      showSnackbar({
        message: t(e.message, { ns: 'payment' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
      throw e;
    }
  };
  const downloadSigned = async () => {
    await downloadDocument(documentUrl, props.taskGuest, postSignedAnnotations);
  };

  return (
    <Container>
      <StyledWBFlex>
        <HeaderFlex>
          <CenteredTypography
            variant="h5"
            fontWeight="medium"
            color={headerColor}
          >
            {t('requestForSignature', { ns: 'taskbox' })}
          </CenteredTypography>
        </HeaderFlex>
        {props.taskGuest.annotations && (
          <PdfScrollProvider
            annotations={props.taskGuest.annotations}
            onSave={updateAnnotations}
            taskGuest={props.taskGuest}
          >
            <WBFlex
              flexDirection="column"
              display={['none', 'block']}
              width="100%"
              flex={1}
            >
              <GuestSignDesktop
                documentUrl={documentUrl}
                {...props}
                downloadDocument={downloadSigned}
                disabledDownload={disableDownload}
              />
            </WBFlex>
            <WBFlex
              flexDirection="column"
              display={['block', 'none']}
              width="100%"
              flex={1}
            >
              <GuestSignMobile
                documentUrl={documentUrl}
                {...props}
                downloadDocument={downloadSigned}
                disabledDownload={disableDownload}
              />
            </WBFlex>
          </PdfScrollProvider>
        )}
      </StyledWBFlex>
    </Container>
  );
};

const Container = styled(WBFlex)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100vh',
}));

const StyledWBFlex = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  alignItems: 'center',
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));

const HeaderFlex = styled(WBFlex)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  flexDirection: 'row',
  padding: theme.spacing(3, 8),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  backgroundColor: theme.palette.mode === 'dark' ? '#151515' : '#151515',
}));

const CenteredTypography = styled(WBTypography)(({ theme }) => ({
  flex: 1,
  textAlign: 'center',
  marginBottom: 0,
}));

export default GuestSignature;
