import { Task, TaskStatus, UpdateTaskStatus } from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBButton,
  WBFlex,
  WBLink,
  WBTextField,
  WBTypography,
  useSnackbar,
} from '@admiin-com/ds-web';
import { Paper, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTaskProperty } from '../../hooks/useTaskProperty/useTaskProperty';
import NoteTypeSelect, {
  NoteType,
} from '../../components/NoteTypeSelect/NoteTypeSelect';
import { TaskActivity } from './TaskActivity';
import { useTaskBoxContext } from '../TaskBox/TaskBox';
import { gql, useMutation } from '@apollo/client';
import { updateTask as UPDATE_TASK } from '@admiin-com/ds-graphql';
import { TaskCreation } from '../TaskCreation/TaskCreation';
import ConfirmationDlg from '../../components/ConfirmationDlg/ConfirmationDlg';
import {
  useCurrentUser,
  useCurrentyEntityUser,
} from '../../hooks/useCurrentUser/useCurrentUser';
interface TaskDraftProps {
  task: Task;
}
export const TaskDraft = ({ task }: TaskDraftProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [note, setNote] = React.useState<string>();
  const { setSelectedTask } = useTaskBoxContext();

  React.useEffect(() => {
    setNote(task?.notes ?? '');
    setIsEdit(false);
  }, [task]);

  React.useEffect(() => {
    setNote(task?.notes ?? '');
  }, [task]);

  const handleChangeNote = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleChangeEditMode = async () => {
    if (!isEdit) setIsEdit(true);
    else {
      // TODO: mutation task note
      try {
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              notes: note,
            },
          },
        });
      } catch (error: any) {
        showSnackbar({
          title: t('editNoteFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
      }
      setIsEdit(false);
    }
  };
  const showSnackbar = useSnackbar();
  const [isAchiving, setIsAchiving] = React.useState<boolean>(false);
  const [updateTask, { loading: archiving }] = useMutation(gql(UPDATE_TASK));
  const [open, setModalOpen] = React.useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };

  const handleArchiveTask = async () => {
    if (isAchiving) return;
    if (task) {
      try {
        setIsAchiving(true);
        await updateTask({
          variables: {
            input: {
              id: task.id,
              entityId: task.entityId,
              status: UpdateTaskStatus.ARCHIVED,
              dueAt: task.dueAt,
            },
          },
        });
        showSnackbar({
          message: t('taskArchived', { ns: 'taskbox' }),
          severity: 'success',
          horizontal: 'center',
          vertical: 'bottom',
        });
        setSelectedTask(null);
      } catch (error: any) {
        showSnackbar({
          title: t('taskArchivedFailed', { ns: 'taskbox' }),
          message: error?.message,
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
      } finally {
        setIsAchiving(false);
      }
    }
  };

  const handleEditTask = () => {
    // TODO: handle Edit task

    setModalOpen(true);
  };
  const handleRemoveDraft = () => {
    // TODO: handle Remove draft
    setConfirmationModalOpen(true);
  };

  const isDraft = task.status === TaskStatus.DRAFT;
  const user = useCurrentyEntityUser();
  const canEditNotes = user?.entityId === task.entityId;

  // if (task.status !== TaskStatus.DRAFT) return null;
  const editButton = (
    <WBButton
      sx={{
        fontSize: theme.typography.body2.fontSize,
        px: 8,
        width: { xs: '60%', sm: 'auto' },
      }}
      // disabled={abnQueryLoading}
      // loading={loading}
      type="button"
      onClick={handleEditTask}
    >
      {t('edit', { ns: 'taskbox' })}
    </WBButton>
  );
  const [confirmationModalOpen, setConfirmationModalOpen] =
    React.useState(false);

  return (
    <>
      <ConfirmationDlg
        open={confirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        title={t('archiveTask', { ns: 'taskbox' })}
        onOK={handleArchiveTask}
        loading={archiving}
      >
        <WBTypography variant="body1" mt={1}>
          {t('archiveTaskConfirmation', { ns: 'taskbox' })}
        </WBTypography>
      </ConfirmationDlg>
      <WBTypography
        fontWeight="bold"
        fontSize={{ xs: 'h5.fontSize', sm: 'h3.fontSize' }}
      >
        {t('notes', { ns: 'taskbox' })}
      </WBTypography>
      <Paper
        sx={{
          p: { xs: 2, md: 4 },
          mt: 2,
          mb: 5,
          fontWeight: 'bold',
          fontSize: 'body2.fontSize',
          boxShadow: '0 11px 19px -8.5px #636363',
          bgcolor: theme.palette.background.default,
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        {!isEdit ? (
          <WBTypography
            sx={{
              whiteSpace: 'pre-wrap',
              wordWrap: 'break-word',
            }}
            component={'pre'}
            maxWidth={['80%', '90%']}
          >
            {note}
          </WBTypography>
        ) : (
          <WBTextField
            variant="standard"
            value={note}
            multiline
            sx={{ flexGrow: 1 }}
            onChange={handleChangeNote}
          />
        )}

        <WBFlex mb={1} alignItems={'center'} justifyContent={'space-between'}>
          <WBFlex justifyContent={'flex-end'}>
            {(isDraft || canEditNotes) && (
              <WBLink onClick={handleChangeEditMode} component={'button'}>
                {t(!isEdit ? 'edit' : 'save', { ns: 'taskbox' })}
              </WBLink>
            )}
          </WBFlex>
        </WBFlex>
      </Paper>
      {isDraft ? (
        <WBFlex
          mx={{
            xs: -4,
            md: -6,
            lg: -8,
          }}
          sx={{
            position: 'sticky',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            flexGrow: 1,
            bottom: 0,
            left: 0,
          }}
        >
          <WBFlex
            sx={{
              my: 3,
              display: { xs: 'flex', sm: 'none' },
              justifyContent: 'center',
            }}
          >
            {editButton}
          </WBFlex>
          <WBFlex
            sx={{
              bgcolor: 'black',
              alignItems: 'center',
              justifyContent: 'center',
              py: { xs: 4, sm: 2 },
            }}
          >
            <WBBox sx={{ display: { xs: 'none', sm: 'block' } }}>
              {editButton}
            </WBBox>
            <WBLink
              ml={{ xs: 0, sm: 3 }}
              variant="body2"
              underline="always"
              color={theme.palette.common.white}
              onClick={handleRemoveDraft}
            >
              {t('removeDraft', { ns: 'taskbox' })}
            </WBLink>
          </WBFlex>
        </WBFlex>
      ) : null}
      {!isDraft &&
      task?.activity?.items?.length &&
      task?.activity?.items?.length > 0 ? (
        <TaskActivity task={task} />
      ) : null}
      {open ? (
        <TaskCreation open={open} handleCloseModal={handleClose} task={task} />
      ) : null}
    </>
  );
};
