import { gql, useMutation } from '@apollo/client';
import React, { useState } from 'react';
import {
  WBFlex,
  WBLinkButton,
  WBSvgIcon,
  WBTooltip,
  WBTypography,
} from '@admiin-com/ds-web';
import { t } from 'i18next';
import AdmiinLogo from '../../../assets/icons/admiin-black.svg';
import {
  Timeline as MuiTimeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab';
import { Payment } from '@admiin-com/ds-graphql';
import { dateTimeFormatFromISO } from '@admiin-com/ds-common';
import ConfirmationDlg from '../ConfirmationDlg/ConfirmationDlg';
import { cancelPaymentPayId as CANCEL_PAYMENT_PAYID } from '@admiin-com/ds-graphql';

export interface PayIDStatusProps {
  payment: Payment | null | undefined;
}

export function PayIDStatus({ payment }: PayIDStatusProps) {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [cancelPaymentPayId] = useMutation(gql(CANCEL_PAYMENT_PAYID));

  const handleCancelTransfer = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmDialogOpen(true);
  };

  const confirmCancelTransfer = async () => {
    try {
      const input = {
        id: payment?.id,
      };

      const { data } = await cancelPaymentPayId({ variables: { input } });
      console.log('Payment cancelled:', data);
    } catch (error) {
      console.error('Error cancelling payment:', error);
    }

    setConfirmDialogOpen(false);
  };

  if (!payment) return null;
  return (
    <>
      <WBTooltip
        title={
          <MuiTimeline
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            <TimelineItem sx={{ minHeight: '55px', maxWidth: '350px' }}>
              <TimelineSeparator>
                <TimelineDot
                  variant="filled"
                  sx={{ bgcolor: 'success.main', borderWidth: 0 }}
                />
                <TimelineConnector sx={{ my: '-4px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <WBTypography
                  color="inherit"
                  variant="body2"
                  fontWeight={'bold'}
                >
                  {dateTimeFormatFromISO(payment.createdAt ?? '')}
                </WBTypography>
                <WBTypography color="inherit" variant="body2">
                  {t('payIDSetUp', { ns: 'payment' })}
                </WBTypography>
                <WBTypography color="inherit" variant="body2">
                  <WBLinkButton
                    onClick={handleCancelTransfer}
                    color="primary.main"
                  >
                    {t('cancelTransfer', { ns: 'payment' })}
                  </WBLinkButton>
                </WBTypography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ minHeight: '55px', maxWidth: '350px' }}>
              <TimelineSeparator>
                <TimelineDot
                  variant="filled"
                  sx={{
                    bgcolor: payment.receivedAt
                      ? 'success.main'
                      : 'warning.main',
                    borderWidth: 0,
                  }}
                />
                <TimelineConnector sx={{ my: '-4px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <WBTypography
                  color="inherit"
                  variant="body2"
                  fontWeight={'bold'}
                >
                  {t('payIDWaitingMoney', { ns: 'payment' })}
                </WBTypography>
                <WBTypography color="inherit" variant="body2">
                  {t('payIDWaitingMoneyHelper', {
                    ns: 'payment',
                    amount: ((payment.amount ?? 0) / 100).toFixed(2),
                  })}
                </WBTypography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ minHeight: '35px', maxWidth: '350px' }}>
              <TimelineSeparator>
                <TimelineDot
                  variant="filled"
                  sx={{
                    bgcolor: payment.receivedAt ? 'success.main' : 'grey',
                    borderWidth: 0,
                  }}
                />
                <TimelineConnector sx={{ my: '-4px' }} />
              </TimelineSeparator>
              <TimelineContent>
                <WBTypography
                  color="inherit"
                  variant="body2"
                  fontWeight={'bold'}
                >
                  {t('payIDReceivedMoney', { ns: 'payment' })}
                </WBTypography>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem sx={{ minHeight: '35px', maxWidth: '350px' }}>
              <TimelineSeparator>
                <TimelineDot
                  variant="filled"
                  sx={{
                    bgcolor: payment.paidOutAt ? 'success.main' : 'grey',
                    borderWidth: 0,
                  }}
                />
              </TimelineSeparator>
              <TimelineContent>
                <WBTypography
                  color="inherit"
                  variant="body2"
                  fontWeight={'bold'}
                >
                  {t('payIDPaidOut', { ns: 'payment' })}
                </WBTypography>
                <WBTypography color="inherit" variant="body2">
                  {t('payIDPaidOutHelper', { ns: 'payment' })}
                </WBTypography>
              </TimelineContent>
            </TimelineItem>
          </MuiTimeline>
        }
      >
        <WBFlex alignItems={'center'}>
          <WBTypography
            variant="h4"
            sx={{
              textDecoration: 'underline',
              textDecorationStyle: 'dotted',
              textUnderlineOffset: '6px',
            }}
            fontWeight={'normal'}
            marginBottom={0.5}
          >
            {t('PENDING', { ns: 'taskbox' })}
          </WBTypography>
          <WBSvgIcon fontSize="small" sx={{ ml: 1, mt: 0, color: '#000' }}>
            <AdmiinLogo />
          </WBSvgIcon>
        </WBFlex>
      </WBTooltip>
      <ConfirmationDlg
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        title={t('cancelTransfer', { ns: 'payment' })}
        onOK={confirmCancelTransfer}
      >
        <WBTypography variant="body1" mt={1}>
          {t('cancelTransferConfirmation', { ns: 'payment' })}
        </WBTypography>
      </ConfirmationDlg>
    </>
  );
}

export default PayIDStatus;
