import { DocumentAnalysis } from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBCheckbox,
  WBListItem,
  WBListItemButton,
  WBListItemIcon,
  WBListItemText,
  WBTypography,
  WBFlex,
  useTheme,
} from '@admiin-com/ds-web';
import { getVendorName } from '../../../helpers/tasks';
import { dateTimeFormatFromISO } from '@admiin-com/ds-common';

export default function ForwardBillsItem(props: {
  documentAnalysis: DocumentAnalysis;
  checked: boolean;
  onCheck: (checked: boolean) => void;
}) {
  const theme = useTheme();
  return (
    <WBListItem sx={{ p: 0 }} divider>
      <WBListItemButton
        sx={{ p: 0 }}
        onClick={() => props.onCheck(!props.checked)}
      >
        <WBListItemIcon>
          <WBCheckbox
            checked={props.checked}
            onChange={(e) => props.onCheck(e.target.checked)}
          />
        </WBListItemIcon>
        <WBListItemText
          primary={getVendorName(props.documentAnalysis)}
          secondary={
            <>
              <WBTypography
                variant="body2"
                color={'text.secondary'}
                component={'span'}
              >
                {dateTimeFormatFromISO(props.documentAnalysis.createdAt ?? '')}
              </WBTypography>
              <br />
              <VectorIcon
                color={
                  theme.palette.mode === 'light'
                    ? 'black'
                    : theme.palette.primary.main
                }
              />
              &nbsp;
              <WBTypography
                variant="body2"
                color={'text.secondary'}
                component={'span'}
              >
                {props.documentAnalysis.fromEmail ?? ''}
              </WBTypography>
            </>
          }
        ></WBListItemText>
      </WBListItemButton>
    </WBListItem>
  );
}

const VectorIcon = (props: { color: string }) => (
  <svg
    width="9"
    height="8"
    viewBox="0 0 9 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.276951 6.03001C0.253433 6.07634 0.244936 6.12885 0.252643 6.18024C0.260351 6.23163 0.283878 6.27934 0.319955 6.31673C0.356032 6.35413 0.402864 6.37935 0.453942 6.3889C0.505019 6.39845 0.557802 6.39184 0.604951 6.37001L1.85445 5.79351C2.90445 5.30851 4.05095 5.08701 5.19495 5.13901C5.20862 5.50667 5.22878 5.87401 5.25545 6.24101L5.28995 6.71001C5.29466 6.77514 5.31621 6.83794 5.35249 6.89224C5.38877 6.94655 5.43853 6.99049 5.4969 7.01978C5.55528 7.04907 5.62026 7.06269 5.68548 7.05931C5.7507 7.05593 5.81392 7.03567 5.86895 7.00051C6.913 6.33262 7.82088 5.47276 8.54445 4.46651L8.77445 4.14651C8.80505 4.10397 8.82151 4.0529 8.82151 4.00051C8.82151 3.94811 8.80505 3.89704 8.77445 3.85451L8.54445 3.53451C7.82093 2.52807 6.91304 1.66804 5.86895 1.00001C5.81395 0.964857 5.75077 0.94459 5.68559 0.941187C5.62041 0.937784 5.55546 0.951361 5.4971 0.980591C5.43874 1.00982 5.38897 1.0537 5.35266 1.10794C5.31635 1.16218 5.29474 1.22491 5.28995 1.29001L5.25545 1.76001C5.23264 2.07471 5.21464 2.38975 5.20145 2.70501H4.87945C4.00142 2.70507 3.14058 2.9485 2.39254 3.40827C1.64451 3.86804 1.03855 4.52614 0.641951 5.30951L0.276951 6.03001Z"
      fill={props.color}
    />
  </svg>
);
