import { useSnackbar } from '@admiin-com/ds-web';
import { loadAirwallex, createElement } from 'airwallex-payment-elements';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAirwallexCustomerSecret as GET_CUSTOMER_SECRET,
  createAirwallexPaymentIntentGuest as CREATE_GUEST_PAYMENT_INTENT,
  getTaskPublic as GET_TASK_PUBLIC,
  getPaymentGuest as GET_PAYMENT_GUEST,
  TaskStatus,
  PaymentStatus,
} from '@admiin-com/ds-graphql';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { POLLING_INTERVAL } from '../../constants/config';
import { airwallexTheme } from '@admiin-com/ds-design-token';

const { VITE_AIRWALLEX_ENV } = import.meta.env;

let paymentIntent: any;
const AirwallexPaymentMethod: React.FC<{
  entityId?: string;
  isGuest: boolean;
  intentId?: string;
  paymentId?: string;
  taskId?: string;
  onClose: () => void;
  onSuccess: (e?: any) => void;
}> = ({
  entityId, //TODO: support hash for guest user
  intentId,
  paymentId,
  taskId,
  isGuest,
  onSuccess,
  onClose,
}) => {
  //const theme = useTheme();
  const [loading, setLoading] = useState<boolean>(true);
  const [isSDKPaymentSuccess, setIsSDKPaymentSuccess] =
    useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { data: taskPublicData } = useQuery(gql(GET_TASK_PUBLIC), {
    variables: {
      token,
    },
    notifyOnNetworkStatusChange: true,
    skip: token ? false : true,
    pollInterval: POLLING_INTERVAL,
  });
  const { data: paymentGuestData } = useQuery(gql(GET_PAYMENT_GUEST), {
    variables: {
      id: paymentId,
    },
    skip: (token ? false : true) || !paymentId,
    pollInterval: POLLING_INTERVAL,
  });
  const { data: getClientSecretData } = useQuery(gql(GET_CUSTOMER_SECRET), {
    variables: {
      entityId,
    },
    skip: isGuest || !entityId || (paymentId ? true : false),
    onError(err) {
      console.log(err);
    },
  });

  const [createPaymentIntentGuest] = useMutation(
    gql(CREATE_GUEST_PAYMENT_INTENT)
  );
  const showSnackbar = useSnackbar();
  const { t } = useTranslation();

  const initializeSDK = async () => {
    try {
      // initialize AirwallexOnboarding instance on window
      await loadAirwallex({
        env: VITE_AIRWALLEX_ENV, // Setup which Airwallex env('demo' | 'prod') to integrate with
        origin: window.location.origin, // Set up your event target to receive the browser events message
      });

      setInitialized(true);
    } catch (e) {
      console.error(e);
      showSnackbar({
        message: t('initError', { ns: 'airwallex' }),
        severity: 'error',
        horizontal: 'right',
        vertical: 'bottom',
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    let pollingInterval: any;
    if (isSDKPaymentSuccess) {
      //Implementing the setInterval method
      pollingInterval = setInterval(() => {
        if (
          taskPublicData?.getTaskPublic?.status === TaskStatus.COMPLETED ||
          taskPublicData?.getTaskPublic?.status === TaskStatus.SCHEDULED ||
          paymentGuestData?.getPaymentGuest?.status === PaymentStatus.SUCCEEDED
        ) {
          showSnackbar({
            message: t('paymentSuccess', { ns: 'airwallex' }),
            severity: 'success',
            horizontal: 'right',
            vertical: 'bottom',
          });
          onSuccess();
          setLoading(false);
        }
      }, POLLING_INTERVAL);
    }

    //Clearing the interval
    return () => clearInterval(pollingInterval);
  }, [isSDKPaymentSuccess, taskPublicData, paymentGuestData]);

  useEffect(() => {
    if (isGuest && !paymentIntent) {
      (async () => {
        const paymentIntentRes = await createPaymentIntentGuest({
          variables: {
            input: {
              entityId,
              taskId,
              paymentId: paymentId,
            },
          },
        });

        if (paymentIntentRes) {
          paymentIntent = JSON.parse(
            paymentIntentRes.data.createAirwallexPaymentIntentGuest
          );
          initializeSDK();
        }
      })();
    } else {
      if (paymentId && paymentIntent) {
        (async () => {
          initializeSDK();
        })();
      } else if (getClientSecretData) {
        initializeSDK();
      } else {
        console.log('WAITING TO LOAD SDK...');
      }
    }
  }, [isGuest, paymentId, getClientSecretData]);

  useEffect(() => {
    let element: any;
    const createElementSDK = async () => {
      //TODO: should not be here?
      if (!entityId) {
        showSnackbar({
          message: 'Entity is required',
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
        return;
      }

      let clientSecret;
      if (paymentIntent) {
        clientSecret = paymentIntent.client_secret;
      } else {
        const getAirwallexCustomerSecret = JSON.parse(
          getClientSecretData.getAirwallexCustomerSecret
        );
        clientSecret = getAirwallexCustomerSecret?.client_secret;
      }

      let dropInPayload: any = {
        client_secret: clientSecret, // customer client_secret or intent client_secret
        currency: 'AUD',
        mode: isGuest ? 'payment' : 'recurring',
        recurringOptions: {
          next_triggered_by: 'merchant',
          currency: 'AUD',
        },
        applePayRequestOptions: {
          countryCode: 'AU',
        },
        googlePayRequestOptions: {
          countryCode: 'AU',
        },
        theme: {
          airwallexTheme,
          //palette: {
          //  primary: theme.palette.primary.light,
          //},
        },
      };

      // If installments then use existing intent_id
      if (isGuest) {
        dropInPayload = {
          ...dropInPayload,
          intent_id: intentId || paymentIntent?.id || '',
        };
      }

      const element = createElement('dropIn', dropInPayload);

      const domElement = element?.mount('drop-in');
      domElement?.addEventListener('onSuccess', (event: any) => {
        console.log(event, token);
        setLoading(true);
        if (!token) {
          showSnackbar({
            message: t('paymentMethodSuccess', { ns: 'airwallex' }),
            severity: 'success',
            horizontal: 'right',
            vertical: 'bottom',
          });
          console.log(
            'SUBMITTED ',
            event?.detail.intent?.latest_payment_attempt?.payment_method
          );
          onSuccess(
            event?.detail.intent?.latest_payment_attempt?.payment_method
          );
          setLoading(false);
        } else {
          setIsSDKPaymentSuccess(true);
        }
      });
      domElement?.addEventListener('onError', (event: any) => {
        console.log('onError', event);
        showSnackbar({
          message: event?.detail?.error.message || '',
          severity: 'error',
          horizontal: 'right',
          vertical: 'bottom',
        });
        onClose();
      });
    };

    if (initialized) {
      createElementSDK();
    }

    return () => element?.destroy();
  }, [initialized, token]);

  if (!initialized) {
    return (
      <Stack spacing={2}>
        {loading && (
          <>
            <Typography variant="h4" textAlign="center" color="text.primary">
              {t('loadingPaymentForm', {
                ns: 'common',
              })}
            </Typography>
            <LinearProgress color="primary" />
          </>
        )}
      </Stack>
    );
  }

  if (loading) {
    return (
      <>
        <Typography variant="h4" textAlign="center" color="text.primary">
          Processing...
        </Typography>
        <LinearProgress color="primary" />
      </>
    );
  }

  return <div id="drop-in"></div>;
};

export default AirwallexPaymentMethod;
