import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { WBBox, WBFlex, WBTypography, WBLinkButton } from '@admiin-com/ds-web';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { CurrencyNumber } from '../../CurrencyNumber/CurrencyNumber';
import LineItemsTable from './LineItemsTable';
import { DocumentAnalysisLineItemInput } from '@admiin-com/ds-graphql';

interface Props {
  lineItems: DocumentAnalysisLineItemInput[];
  setLineItems: (lineItems: DocumentAnalysisLineItemInput[]) => void;
}
export function BillLineItems({ lineItems, setLineItems }: Props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const total = React.useMemo(
    () =>
      lineItems.reduce(
        (totalPrice, lineItem) =>
          totalPrice + (lineItem.price ? lineItem.price : 0),
        0
      ),
    [lineItems]
  );
  const removeLineItems = () => {
    setLineItems([]);
  };

  return (
    <Container>
      <Content>
        <LineItemsTable setRows={setLineItems} rows={lineItems} />
        <FooterContainer>
          <WBBox width={['100%', 200]}>
            <FooterRow>
              <WBTypography fontWeight={'bold'}>
                {t('totalDue', { ns: 'taskbox' })}
              </WBTypography>
              <CurrencyNumber
                mb={0}
                variant="h5"
                number={total}
                sup={false}
                fontWeight={'bold'}
              />
            </FooterRow>
          </WBBox>
        </FooterContainer>
        <WBFlex justifyContent={'space-between'}>
          <WBLinkButton
            noDecoration
            color={theme.palette.primary.main}
            sx={{ ':hover': { color: theme.palette.error.main } }}
            onClick={removeLineItems}
          >
            {t('removeLineItems', { ns: 'taskbox' })}
          </WBLinkButton>
        </WBFlex>
      </Content>
    </Container>
  );
}
const Container = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'end',
  backgroundColor: theme.palette.background.default,
}));
const Content = styled(WBBox)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));
const FooterRow = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
}));
const FooterContainer = styled(WBFlex)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.text.secondary}`,
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  width: '100%',
  alignItems: 'end',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'start',
  },
}));
