import { useCurrentyEntityUser } from '../useCurrentUser/useCurrentUser';

export const usePaymentEnabled = () => {
  const user = useCurrentyEntityUser();

  const isOwner = user?.role === 'OWNER';
  if (isOwner) {
    return true;
  }
  return user?.paymentsEnabled ?? false;
};
