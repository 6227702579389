import {
  NotificationStatus,
  notificationsByUser as NOTIFICATIONS_BY_USER,
  onUpdateEntity as ON_UPDATE_ENTITY,
  updateNotification as UPDATE_NOTIFICATION,
  onCreateNotification as ON_CREATE_NOTIFICATION,
  OnCreateNotificationSubscription,
  Notification,
  OnUpdateEntitySubscription,
  // OnUpdateEntitySubscription,
} from '@admiin-com/ds-graphql';
import { CSGetSub as GET_SUB } from '@admiin-com/ds-graphql';
import { useSnackbar } from '@admiin-com/ds-web';
import {
  OnDataOptions,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client';
import { AlertColor } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentEntityId } from '../useSelectedEntity/useSelectedEntity';
// import { useSelectedEntity } from '../useSelectedEntity/useSelectedEntity';

export function useNotificationService() {
  const { t } = useTranslation();
  const { data: sub } = useQuery(gql(GET_SUB));

  // const { entity } = useSelectedEntity();

  const { data, refetch } = useQuery(gql(NOTIFICATIONS_BY_USER), {
    variables: {
      filter: {
        status: {
          eq: NotificationStatus.UNREAD,
        },
      },
    },
  });

  const [updateNotification] = useMutation(gql(UPDATE_NOTIFICATION));

  const showSnackbar = useSnackbar();
  const showNotification = React.useCallback(
    (notification?: Notification | null, onClose?: () => void) => {
      if (notification && notification.status === NotificationStatus.UNREAD) {
        let message = '';
        if (notification?.title) {
          message = t(notification.title, { ns: 'notifications' });
        } else if (notification?.message) {
          message = t(notification.message, { ns: 'notifications' });
        }

        showSnackbar({
          message,
          severity: (notification?.type ?? 'info') as AlertColor | undefined,
          horizontal: 'center',
          vertical: 'bottom',
          onClose: async () => {
            await updateNotification({
              variables: {
                input: {
                  id: notification.id,
                  status: NotificationStatus.READ,
                },
              },
            });
            onClose && (await onClose());
          },
        });
      }
    },
    [t, showSnackbar, updateNotification]
  );

  useSubscription(
    gql`
      ${ON_CREATE_NOTIFICATION}
    `,
    {
      //TODO: may not be necessary - no longer can pass filter
      variables: {
        //filter: {
        //
        //  owner: {
        //    eq: sub?.sub,
        //  },
        //},
      },
      skip: !sub?.sub,
      onData: (data: OnDataOptions<OnCreateNotificationSubscription>) => {
        const notification = data?.data?.data?.onCreateNotification;
        showNotification(notification);
      },
    }
  );

  const entityId = useCurrentEntityId();

  const [_subscriptionErrorCount, setSubscriptionErrorCount] =
    React.useState(0);
  const [init, setInit] = React.useState(false);
  const onSubscriptionError = React.useCallback((err: any) => {
    console.log('ERROR subscription: ', err);
    setInit(false);
    setTimeout(() => {
      setInit(true);
      setSubscriptionErrorCount((prev) => prev + 1);
    }, 500);
  }, []);

  const client = useApolloClient();
  useSubscription(
    gql`
      ${ON_UPDATE_ENTITY}
    `,
    {
      variables: {
        entityId: entityId,
      },
      skip: !entityId || !init,
      onData: (data: OnDataOptions<OnUpdateEntitySubscription>) => {
        const onUpdateEntity = data?.data?.data?.onUpdateEntity;
        if (onUpdateEntity) {
          if (onUpdateEntity.xeroContactSyncStatus === 'PENDING') {
            showSnackbar({
              title: t('contactSyncingTitle', { ns: 'settings' }),
              message: t('contactSyncingDescription', { ns: 'settings' }),
            });
          } else if (onUpdateEntity.xeroContactSyncStatus === 'SYNCED') {
            showSnackbar({
              title: t('contactSyncedTitle', { ns: 'settings' }),
              message: t('contactSyncedDescription', { ns: 'settings' }),
            });
          }
          if (onUpdateEntity.xeroInvoiceSyncStatus === 'PENDING') {
            showSnackbar({
              title: t('invoiceSyncingTitle', { ns: 'settings' }),
              message: t('invoiceSyncingDescription', { ns: 'settings' }),
            });
          } else if (onUpdateEntity.xeroInvoiceSyncStatus === 'SYNCED') {
            showSnackbar({
              title: t('invoiceSyncedTitle', { ns: 'settings' }),
              message: t('invoiceSyncedDescription', { ns: 'settings' }),
            });
          }
          client.cache.modify({
            id: client.cache.identify(onUpdateEntity),
            fields: {
              xeroInvoiceSyncStatus() {
                return onUpdateEntity?.xeroInvoiceSyncStatus ?? null;
              },
              xeroLastInvoiceSyncAt() {
                return onUpdateEntity?.xeroLastInvoiceSyncAt ?? null;
              },
              xeroContactSyncStatus() {
                return onUpdateEntity?.xeroContactSyncStatus ?? null;
              },
              xeroLastContactSyncAt() {
                return onUpdateEntity?.xeroLastContactSyncAt ?? null;
              },
            },
          });
        }
      },
      onError: (err) => onSubscriptionError(err),
      shouldResubscribe: true,
    }
  );

  React.useEffect(() => {
    if (data) {
      const notification = data?.notificationsByUser?.items?.[0];
      showNotification(notification, () => refetch());
    }
  }, [data, refetch, showNotification]);
}
