import { BalanceHistory } from '@admiin-com/ds-graphql';
import {
  WBBox,
  WBButton,
  WBFlex,
  WBSvgIcon,
  WBTypography,
} from '@admiin-com/ds-web';
import { floatToCurrency } from '@admiin-com/ds-common';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import DownIcon from '../../../assets/icons/down-arrow.svg';
import UpIcon from '../../../assets/icons/up-arrow.svg';
import { Skeleton } from '@mui/material';
import React from 'react';

export interface TransactionListProps {
  balanceHistory: BalanceHistory;
  loading: boolean;
}

const CREDIT_SOURCE_TYPES = [
  'DEPOSIT',
  'CONVERSION',
  'PAYMENT',
  'ADJUSTMENT',
  'ISSUING',
  'PAYMENT_SETTLEMENT',
  'PAYMENT_RESERVE',
  'DIRECT_DEBIT',
  'PURCHASE',
  'YIELD',
];

const DEBIT_SOURCE_TYPES = [
  'CHARGE',
  'PAYMENT_REFUND',
  'PAYMENT_REFUND_FAILED',
  'PAYOUT',
  'BATCH_PAYOUT',
  'TRANSFER',
  'FEE',
];

export function TransactionList({
  balanceHistory,
  loading,
}: TransactionListProps) {
  const { t } = useTranslation();
  return (
    <>
      {balanceHistory?.items?.map(
        (transaction) =>
          transaction && (
            <WBFlex
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              mb={1}
            >
              <WBBox pr={1} alignItems="center" justifyContent="center">
                <WBSvgIcon>
                  {CREDIT_SOURCE_TYPES.includes(transaction.sourceType) && (
                    <UpIcon />
                  )}
                  {DEBIT_SOURCE_TYPES.includes(transaction.sourceType) && (
                    <DownIcon />
                  )}
                </WBSvgIcon>
              </WBBox>

              <WBBox flex={3}>
                <WBTypography>
                  {t(transaction.sourceType, { ns: 'admiinAccount' })}
                </WBTypography>
                <WBTypography variant="body2" color="text.secondary">
                  {DateTime.fromISO(transaction.postedAt).toLocaleString(
                    DateTime.DATETIME_MED
                  )}
                </WBTypography>
              </WBBox>

              <WBBox flex={2}>
                <WBTypography
                  color={
                    CREDIT_SOURCE_TYPES.includes(transaction.sourceType)
                      ? 'success.dark'
                      : 'inherit'
                  }
                  textAlign="right"
                >
                  {CREDIT_SOURCE_TYPES.includes(transaction.sourceType)
                    ? '+'
                    : transaction.amount.startsWith('-')
                    ? ''
                    : '-'}
                  {floatToCurrency(Number(transaction.amount))}
                </WBTypography>
                <WBTypography fontWeight="bold" textAlign="right">
                  {floatToCurrency(Number(transaction.balance))}
                </WBTypography>
              </WBBox>
            </WBFlex>
          )
      )}

      {loading &&
        !balanceHistory?.items &&
        Array.from({ length: 6 }).map((_, index) => (
          <WBFlex key={`balanceHistory${index}`} alignItems="center" mb={1}>
            <WBBox>
              <Skeleton width="15px" height="42px" />
            </WBBox>
            <WBBox ml="12px">
              <Skeleton width="145px" height="26px" />
              <Skeleton width="145px" height="26px" />
            </WBBox>
            <WBBox ml="auto">
              <Skeleton width="70px" height="26px" />
              <Skeleton width="70px" height="26px" />
            </WBBox>
          </WBFlex>
        ))}

      {!loading && balanceHistory?.items?.length === 0 && (
        <WBFlex justifyContent={['center', 'center', 'flex-start']} mt={1}>
          <WBTypography>
            {t('noTransactions', { ns: 'admiinAccount' })}
          </WBTypography>
        </WBFlex>
      )}

      {!loading && false && (
        <WBFlex justifyContent={['center', 'center', 'flex-start']} mt={1}>
          <WBButton variant="text">{t('showAll', { ns: 'common' })}</WBButton>
        </WBFlex>
      )}
    </>
  );
}

export default TransactionList;
