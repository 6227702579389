import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import ServicesLookup from '.';

export type ServicesLookupEditCellProps = GridRenderEditCellParams & {
  entityId: string;
  onChange: (value: any) => void;
};
export const ServicesLookupEditCell = (props: ServicesLookupEditCellProps) => {
  const { id, field, entityId } = props;
  const apiRef = useGridApiContext();

  const onChange = (value: any) => {
    const isValid = apiRef.current.setEditCellValue({
      id,
      field,
      value: value?.description,
    });
    props.onChange(value);
    apiRef.current.stopCellEditMode({ id, field });
  };
  return (
    <ServicesLookup
      entityId={entityId}
      value={props.value}
      onChange={onChange}
    />
  );
};
