import { xeroCreateContactSync } from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';
import { useSnackbar } from '@admiin-com/ds-web';
import React from 'react';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';

interface Props {
  onSucces?: () => void;
}

export const useContactSync = (props: Props) => {
  const [startContactSync] = useMutation(gql(xeroCreateContactSync));
  const showSnackbar = useSnackbar();

  const [loading, setLoading] = React.useState(false);

  const startXeroSync = async () => {
    try {
      setLoading(true);
      await startContactSync({
        variables: {
          input: {
            entityId: entity?.id,
          },
        },
      });
    } catch (err) {
      console.log('ERROR start contact sync', err);
      showSnackbar({
        message: 'Error starting contact sync',
        severity: 'error',
      });
      setLoading(false);
    }
  };

  const { entity } = useSelectedEntity();

  const xeroContactSyncStatus = entity?.xeroContactSyncStatus;

  React.useEffect(() => {
    if (xeroContactSyncStatus === 'PENDING') {
      setLoading(true);
    } else if (xeroContactSyncStatus === 'SYNCED') {
      if (props.onSucces) {
        props.onSucces();
      }
      setLoading(false);
    }
  }, [props, xeroContactSyncStatus]);

  return { startXeroSync, loading };
};
