import { WBDialog, WBFlex, WBIconButton } from '@admiin-com/ds-web';

export interface XModalProps {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export function XModal({ open, children, onClose }: XModalProps) {
  return (
    <WBDialog
      open={open}
      maxWidth={'sm'}
      fullWidth
      sx={{ '& .MuiPaper-root': { overflow: 'visible' } }}
    >
      <WBFlex
        flexDirection={'row'}
        height={'100%'}
        position={'relative'}
        justifyContent={'center'}
        overflow={'auto'}
      >
        <WBFlex overflow={'auto'} width="100%">
          {children}
        </WBFlex>
        <WBIconButton
          aria-label="close"
          icon="Close"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          color={'grey' as any}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
        />
      </WBFlex>
    </WBDialog>
  );
}

export default XModal;
