import { DocumentAnalysisLineItemInput } from '@admiin-com/ds-graphql';
import { FormProvider, useForm } from 'react-hook-form';
import LineItemsCreateModal from './LineItemCreateModal';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  onAdd: (lineItem: DocumentAnalysisLineItemInput) => void;
  item: DocumentAnalysisLineItemInput | null;
}

export default function InvoiceLineItemsCreateModal(props: Props) {
  const methods = useForm<DocumentAnalysisLineItemInput>({
    defaultValues: {
      description: props.item?.description ?? '',
      unitPrice: props.item?.unitPrice,
      quantity: props.item?.quantity,
      taxType: props.item?.taxType,
    },
  });

  const handleAdd = (data: DocumentAnalysisLineItemInput) => {
    props.onAdd({
      ...props.item,
      ...data,
    });
  };

  return (
    <FormProvider {...methods}>
      <LineItemsCreateModal
        {...props}
        onAdd={handleAdd}
        isEdit={!!props.item}
      />
    </FormProvider>
  );
}
