export const NAVBAR_HEIGHT = 64;
export const FOOTER_HEIGHT = 116;
export const ABN_LENGTH = 11;
export const ACN_LENGTH = 9;
export const DRAWER_WIDTH = 210;
export const GST_RATE = 10;
export const INSTALLMENT_FEE = 5;
export const POLLING_INTERVAL = 2000;
export const FEE_TIERS = {
  PRO: {
    BANK: 0.9,
    CARD: 0.018,
    TAX_PLAN_FEE: 35,
  },
  STANDARD: {
    BANK: 0.9,
    CARD: 0.018,
    TAX_PLAN_FEE: 35,
  },
  FREE: {
    BANK: 0.9,
    CARD: 0.018,
    TAX_PLAN_FEE: 88,
  },
};
