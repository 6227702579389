import {
  WBBox,
  WBFlex,
  WBCollapse,
  WBLinkButton,
  WBTypography,
  WBIcon,
  WBIconButton,
} from '@admiin-com/ds-web';
import { styled, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadSvgIcon from '../../../../../component/LoadSvgIcon/LoadSvgIcon';
import PenIcon from '../../../../../../assets/icons/pen-icon.svg';

import { Bill } from '../../../PayBillCreateForm';
import { numberToCurrency } from '@admiin-com/ds-common';
import { DocumentAnalysisLineItemInput } from '@admiin-com/ds-graphql';
import React from 'react';

interface ScanItemLineItemsProps {
  lineItems: DocumentAnalysisLineItemInput[];
  index: number;
  opened: number;
  setOpened: any;
  handleEditLineItem: (
    item: DocumentAnalysisLineItemInput | null,
    index: number
  ) => void;
  clearLineItems: () => void;
  handleDeleteLineItem: (deleteIndex: number) => void;
}
export const ScanItemLineItems = (props: ScanItemLineItemsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const handleLineItem = () => {
    props.setOpened(props.opened === props.index ? -1 : props.index);
  };

  const opend = props.opened === props.index;

  const { lineItems } = props;
  const total = React.useMemo(
    () =>
      lineItems.reduce(
        (totalPrice, lineItem) =>
          totalPrice + (lineItem.price ? lineItem.price : 0),
        0
      ),
    [lineItems]
  );

  return (
    <>
      <WBCollapse in={opend}>
        <WBBox my={[1, 2]} mx={2}>
          {lineItems ? (
            <WBFlex flexDirection={'column'} gap={1}>
              {lineItems?.map((item: any, index: any) => (
                <WBFlex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  key={index}
                  gap={2}
                >
                  <WBFlex
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    flex={1}
                    gap={1}
                  >
                    {item?.description ? (
                      <Text>{item?.description}</Text>
                    ) : (
                      <Text color={'action.disabled'} fontStyle={'italic'}>
                        {t('noDescription', { ns: 'taskbox' })}
                      </Text>
                    )}
                    <Text>{numberToCurrency(item?.price)}</Text>
                  </WBFlex>
                  <WBFlex alignItems={'center'}>
                    <WBIconButton
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => {
                        props.handleEditLineItem(item, index);
                      }}
                    >
                      <LoadSvgIcon component={PenIcon} />
                    </WBIconButton>
                    <WBIconButton
                      onClick={() => props.handleDeleteLineItem(index)}
                      icon="Trash"
                      size="small"
                      className="delete-icon"
                    />
                  </WBFlex>
                </WBFlex>
              ))}
            </WBFlex>
          ) : (
            <Text color={'action.disabled'} fontStyle={'italic'}>
              {t('noLineItems', { ns: 'taskbox' })}
            </Text>
          )}
        </WBBox>
        <WBFlex mb={[0, 4]} mt={[1, 2]} mx={2} justifyContent={'space-between'}>
          <WBLinkButton
            onClick={() => {
              props.handleEditLineItem(null, -1);
            }}
            color="primary.main"
            sx={{ ...theme.typography.button }}
          >
            {t('addNewItem', { ns: 'taskbox' })}
          </WBLinkButton>

          <WBLinkButton
            onClick={() => {
              props.clearLineItems();
            }}
            color="primary.main"
            sx={{ ...theme.typography.button }}
          >
            {t('clear', { ns: 'taskbox' })}
          </WBLinkButton>
        </WBFlex>
      </WBCollapse>
      <WBLinkButtonContainer>
        <WBLinkButton
          noDecoration
          color={theme.palette.primary.main}
          onClick={handleLineItem}
          sx={{
            display: props.opened === props.index ? 'block' : 'none',
          }}
        >
          {t('hideLineItem', { ns: 'taskbox' })}
          &nbsp;
          <WBIcon
            name={'ChevronUp'}
            size={3}
            color={theme.palette.primary.main}
          />
        </WBLinkButton>
        <WBLinkButton
          sx={{
            display: props.opened === props.index ? 'none' : 'block',
          }}
          noDecoration
          color={theme.palette.primary.main}
          onClick={handleLineItem}
        >
          {t('viewLineItem', { ns: 'taskbox' })}
          &nbsp;
          <WBIcon
            name={'ChevronDown'}
            size={3}
            color={theme.palette.primary.main}
          />
        </WBLinkButton>
      </WBLinkButtonContainer>

      <AmountContainer
        sx={{
          borderBottomWidth: 0,
          borderTopWidth: 1,
        }}
      >
        <Text textAlign={'left'} sx={{ fontWeight: 700 }}>
          {`${'Total '} ${numberToCurrency(total)}`}
        </Text>
      </AmountContainer>
    </>
  );
};

const Text = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
}));
const AmountContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  borderColor: theme.palette.grey[300],
  borderStyle: 'solid',
  borderLeftWidth: 0,
  borderRightWidth: 0,
  paddingBottom: theme.spacing(1),
  paddingTop: theme.spacing(1),
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
const WBLinkButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(2),
}));
