export const airwallexTheme = {
  palette: {
    primary: {
      '10': '#F3E6FF',
      '20': '#E0C2FF',
      '30': '#CC9EFF',
      '40': '#B97AFF',
      '50': '#A556FF',
      '60': '#8C52FF', // main primary color
      '70': '#703ECC',
      '80': '#542B99',
      '90': '#381766',
      '100': '#1C0333',
    },
  },
  components: {
    spinner: {
      colors: {
        start: {
          initial: '#E0C2FF',
        },
        stop: {
          initial: '#8C52FF',
        },
      },
    },
  },
};
