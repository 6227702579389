import { WBLinkButton, WBTypography } from '@admiin-com/ds-web';
import { WBButton } from '@admiin-com/ds-web';
import { WBFlex } from '@admiin-com/ds-web';
import { DialogContent, styled, useMediaQuery, useTheme } from '@mui/material';
import { Dialog as MUIDialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LottieAnimation from '../LottieAnimation/LottieAnimation';

interface Props {
  title: string;
  description: string;
  open: boolean;
  onClose: () => void;
  onCreateNewTask?: () => void;
  closeButtonText?: string;
}

export function PaymentSubmittedModal({
  open,
  onClose,
  onCreateNewTask,
  title,
  description,
  closeButtonText,
}: Props) {
  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      scroll="body"
      fullScreen={isMobile}
    >
      <DialogContent sx={{ padding: 0, height: '100%', width: '100%' }}>
        <Conatiner flexDirection={'column'} alignItems={'center'} rowGap={2.5}>
          <ImagePlaceholder>
            <LottieAnimation path="/tick.lottie" />
          </ImagePlaceholder>
          <Title textAlign={'center'}>{title}</Title>
          <Description variant="body1" textAlign={'center'}>
            {description}
          </Description>
          {onCreateNewTask && (
            <StartButton onClick={onCreateNewTask}>
              <WBTypography
                color={'inherit'}
                variant="body1"
                fontWeight={'medium'}
                noWrap
              >
                {t('createAnotherTask', { ns: 'taskbox' })}
              </WBTypography>
            </StartButton>
          )}
          <WBLinkButton color="primary.main" onClick={onClose}>
            {closeButtonText ?? t('backToTaskbox', { ns: 'taskbox' })}
          </WBLinkButton>
        </Conatiner>
      </DialogContent>
    </StyledDialog>
  );
}
const StyledDialog = styled(MUIDialog)({
  '& .MuiDialogContent-root': {
    width: '100%',
  },
});

const StartButton = styled(WBButton)(({ theme }) => ({
  padding: theme.spacing(2, 18),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2, 8),
  },
  flexWrap: 'nowrap',
}));
const ImagePlaceholder = styled(WBFlex)(({ theme }) => ({
  width: '200px',
  height: '200px',
  backgroundColor: 'transparent',
  borderRadius: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '90px',
    height: '90px',
  },
}));

const Title = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.h3,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.h3,
  },
}));

const Description = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
  [theme.breakpoints.down('sm')]: {
    ...theme.typography.body2,
  },
  color: theme.palette.text.secondary,
}));

const Conatiner = styled(WBFlex)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(10),
  height: '100%',
  width: '100%',
  // paddingTop: theme.spacing(10),
}));
