import { useClipboard } from '@admiin-com/ds-hooks';
import {
  WBBox,
  WBButton,
  WBDivider,
  WBFlex,
  WBIconButton,
  WBSkeleton,
  WBSvgIcon,
  WBTypography,
} from '@admiin-com/ds-web';
import { DialogActions, DialogContent, useTheme } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { usePaymentContext } from '../PaymentContainer/PaymentContainer';
import { useTaskBoxContext } from '../../pages/TaskBox/TaskBox';
import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { getEntityPayId as GET_ENTITY_PAY_ID } from '@admiin-com/ds-graphql';
import { useCurrentEntityId } from '../../hooks/useSelectedEntity/useSelectedEntity';
import { numberToCurrency } from '@admiin-com/ds-common';
import ErrorHandler from '../ErrorHandler/ErrorHandler';
import LinkIcon from '../../../assets/icons/link.svg';

export interface ConnectedAccountFormProps {
  onSubmitted: () => void;
  onOpenVerificationDialog?: () => void;
}

export function ConnectedAccount({
  onSubmitted,
  onOpenVerificationDialog,
}: ConnectedAccountFormProps) {
  const { t } = useTranslation();
  const { selectedTask: task, selectedTasks } = useTaskBoxContext();
  const { createPayId, getBillPayments } = usePaymentContext();
  const theme = useTheme();
  const [, copy] = useClipboard();

  const [error, setError] = React.useState<any>({});
  const entityId = useCurrentEntityId();
  const { data: getEntityPayIdData, loading } = useQuery(
    gql(GET_ENTITY_PAY_ID),
    {
      variables: {
        entityId,
        billPayments: getBillPayments(task ? [task] : selectedTasks),
      },
      onError(err) {
        setError(err);
      },
    }
  );
  //TODO: FETCH BALANCE OF CONNECTED ACCOUNT AND DISPLAY
  const entityPayId = getEntityPayIdData?.getEntityPayId;

  const onSubmit = () => {
    onSubmitted();
    createPayId(task ? [task] : selectedTasks);
  };

  const hasEnoughFunds = useMemo(
    () =>
      entityPayId?.currentBalance.availableAmount >=
      entityPayId?.transferAmount,
    [entityPayId]
  );

  const transferAmount = useMemo(() => {
    return (
      ((entityPayId?.transferAmount || 0) -
        (entityPayId?.currentBalance.availableAmount || 0)) /
      100
    );
  }, [entityPayId]);

  return (
    <>
      <DialogContent sx={{ px: 0 }}>
        <WBFlex flexDirection="row" alignItems="flex-end">
          {entityPayId ? (
            <>
              <WBTypography variant="h2" margin={0}>
                {numberToCurrency(
                  (entityPayId?.currentBalance.availableAmount || 0) / 100,
                  entityPayId?.currency ?? undefined
                )}
              </WBTypography>
              <WBTypography ml={1} mb={1}>
                {t('currentBalance', { ns: 'settings' })}
              </WBTypography>
            </>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
        </WBFlex>

        <WBDivider sx={{ mt: 3 }} />
        {!loading && hasEnoughFunds && !error && (
          <WBTypography mt={3}>
            {t('connectedAccountWillDeduct', {
              ns: 'payment',
            })}
            <b>
              {numberToCurrency(
                (entityPayId?.transferAmount || 0) / 100,
                entityPayId?.currency ?? undefined
              )}
            </b>
            {t('connectedAccountFromAccount', {
              ns: 'payment',
            })}
          </WBTypography>
        )}
        {!loading && !hasEnoughFunds && isEmpty(error) && (
          <WBTypography mt={3}>
            {t('connectedAccountSubTitle1', {
              ns: 'payment',
            })}
            <b>
              {numberToCurrency(
                ((entityPayId?.transferAmount || 0) -
                  (entityPayId?.currentBalance.availableAmount || 0)) /
                  100,
                entityPayId?.currency ?? undefined
              )}
            </b>
            {t('connectedAccountSubTitle2', {
              ns: 'payment',
            })}
          </WBTypography>
        )}
        <WBBox mt={7}>
          <WBTypography variant="h5" mt={3}>
            {t('accountName', { ns: 'settings' })}
          </WBTypography>
          {entityPayId ? (
            <WBFlex alignItems="center">
              <WBTypography mr={1}>{entityPayId?.accountName}</WBTypography>
              <WBIconButton>
                <WBSvgIcon
                  color={theme.palette.primary.main}
                  fontSize="small"
                  onClick={() => copy(entityPayId?.accountName)}
                >
                  <LinkIcon />
                </WBSvgIcon>
              </WBIconButton>
            </WBFlex>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
          <WBTypography variant="h5" mt={3}>
            {t('accountNumber', { ns: 'settings' })}
          </WBTypography>
          {entityPayId ? (
            <WBFlex alignItems="center">
              <WBTypography>{entityPayId.accountNumber}</WBTypography>
              <WBIconButton>
                <WBSvgIcon
                  color={theme.palette.primary.main}
                  fontSize="small"
                  onClick={() => copy(entityPayId?.accountNumber)}
                >
                  <LinkIcon />
                </WBSvgIcon>
              </WBIconButton>
            </WBFlex>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
          <WBTypography variant="h5" mt={3}>
            {t('routingNumber', { ns: 'settings' })}
          </WBTypography>
          {entityPayId ? (
            <WBFlex alignItems="center">
              <WBTypography>{entityPayId.routingNumber}</WBTypography>
              <WBIconButton>
                <WBSvgIcon
                  color={theme.palette.primary.main}
                  fontSize="small"
                  onClick={() => copy(entityPayId?.routingNumber)}
                >
                  <LinkIcon />
                </WBSvgIcon>
              </WBIconButton>
            </WBFlex>
          ) : (
            <WBSkeleton width={100} height={40} />
          )}
          {!hasEnoughFunds && (
            <>
              <WBTypography variant="h5" mt={3}>
                {t('amountToTransfer', { ns: 'settings' })}
              </WBTypography>
              {entityPayId ? (
                <WBFlex alignItems="center">
                  <WBTypography>
                    {numberToCurrency(
                      transferAmount,
                      entityPayId?.currency ?? undefined
                    )}
                  </WBTypography>
                  <WBIconButton>
                    <WBSvgIcon
                      color={theme.palette.primary.main}
                      fontSize="small"
                      onClick={() => copy(transferAmount?.toString() ?? '')}
                    >
                      <LinkIcon />
                    </WBSvgIcon>
                  </WBIconButton>
                </WBFlex>
              ) : (
                <WBSkeleton width={100} height={40} />
              )}
            </>
          )}
        </WBBox>
      </DialogContent>
      <DialogActions>
        <WBBox sx={{ width: '100%', px: 2, mt: 5 }}>
          <WBButton
            onClick={onSubmit}
            fullWidth
            disabled={Object.keys(error).length > 0}
            loading={
              !Object.keys(error).length &&
              !entityPayId?.currentBalance?.availableAmount &&
              entityPayId?.currentBalance?.availableAmount !== 0
            }
          >
            {t(hasEnoughFunds ? 'confirmPayment' : 'havePaid', {
              ns: 'payment',
            })}
          </WBButton>
          <WBTypography
            my={3}
            fontStyle={'italic'}
            variant="body2"
            textAlign={'center'}
          >
            {t('payIdHelper', { ns: 'payment' })}
          </WBTypography>
          <ErrorHandler
            onOpenNewDialog={() => {
              setError({});
              if (onOpenVerificationDialog) {
                onOpenVerificationDialog();
              }
            }}
            errorMessage={error?.message}
          />
        </WBBox>
      </DialogActions>
    </>
  );
}

export default ConnectedAccount;
