import {
  Task,
  TaskDirection,
  TaskStatus,
  getTask,
} from '@admiin-com/ds-graphql';
import React, { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { CSGetSelectedEntityId as GET_SELECTED_ENTITY_ID } from '@admiin-com/ds-graphql';

interface Props {
  tasks: Array<Task>;
}

export const useTaskSelection = (props: Props) => {
  const { id } = useParams();
  const { data: selectedEntityIdData } = useQuery(gql(GET_SELECTED_ENTITY_ID));
  const entityId = selectedEntityIdData?.selectedEntityId;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [getTaskQuery] = useLazyQuery(gql(getTask));

  const { data: getTaskData, loading } = useQuery(gql(getTask), {
    variables: {
      id,
      entityId,
    },
    skip: !id || !entityId,
  });

  const task = React.useMemo(
    () =>
      getTaskData?.getTask
        ? { ...getTaskData?.getTask, amount: getTaskData?.getTask.amount / 100 }
        : null,
    [getTaskData]
  );

  const [selectedTasks, setSelectedTasks] = React.useState<Array<Task>>([]);

  const [selectedTaskIds, setSelectedTaskIds] = React.useState<Array<string>>(
    []
  );
  const [selectedTask, setSelectedTask] = React.useState<Task | null>(null);

  const multiSelect = (task: Task, isUpdate = false) => {
    // Change selectedTasks to selectedTaskIds
    const index = selectedTaskIds.indexOf(task.id);
    let updated = [];
    if (index >= 0) {
      updated = [
        ...selectedTaskIds.slice(0, index),
        ...(isUpdate ? [task.id] : []),
        ...selectedTaskIds.slice(index + 1),
      ];
    } else {
      updated = [...selectedTaskIds, task.id];
    }
    setSelectedTaskIds(updated);

    updated = [];
    if (index >= 0) {
      updated = [
        ...selectedTasks.slice(0, index),
        ...(isUpdate ? [task] : []),
        ...selectedTasks.slice(index + 1),
      ];
    } else updated = selectedTasks.concat(task);
    setSelectedTasks(updated);
    if (updated.length > 0 && selectedTask) handleTaskSelection(null);
  };

  const getTaskDirection = (task: Task) => {
    if (entityId === task.toId) return TaskDirection.RECEIVING;
    if (entityId === task.fromId) return TaskDirection.SENDING;
  };

  const handleTaskSelection = React.useCallback(
    (task: Task | null) => {
      if (task) {
        const statusFilter = getStatusFilter(task.status);
        navigate(
          `/taskbox/${task.id}?direction=${getTaskDirection(
            task
          )}&status=${statusFilter}`
        );
        setSelectedTask(task);
      } else {
        setSelectedTask(null);
        navigate(`/taskbox?${searchParams.toString()}`);
      }
    },
    [navigate, selectedTask, searchParams]
  );

  const getStatusFilter = (status: TaskStatus) => {
    switch (status) {
      case TaskStatus.INCOMPLETE:
      case TaskStatus.DRAFT:
        return 'Due';
      case TaskStatus.COMPLETED:
        return 'Completed';
      case TaskStatus.SCHEDULED:
        return 'Scheduled';
      default:
        return 'Due';
    }
  };

  React.useEffect(() => {
    if (task) {
      handleTaskSelection(task);
    }
  }, [task]);

  const refreshSelectedTasks = async () => {
    const newTasks = [];
    for (const task of selectedTasks) {
      const taskData = await getTaskQuery({
        variables: {
          id: task.id,
          entityId: task.entityId,
        },
      });
      newTasks.push(taskData?.data?.getTask);
    }
    setSelectedTasks(newTasks);
  };

  const handleSelectedTasks = React.useCallback(
    (tasks: Task[]) => {
      setSelectedTasks(tasks);
      setSelectedTaskIds(tasks.map((task) => task.id));
    },
    [setSelectedTasks, setSelectedTaskIds]
  );

  return {
    selectedTasks,
    setSelectedTasks: handleSelectedTasks,
    refreshSelectedTasks,
    selectedTask,
    loadingTask: loading,
    detailView: !!id,
    setSelectedTask: handleTaskSelection,
    multiSelect,
  };
};
