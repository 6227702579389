import { WBBox, WBFlex } from '@admiin-com/ds-web';
import React from 'react';
import { styled } from '@mui/material';
import { ScanItemCard } from './ScanItemCard';
import { Bill } from '../../../PayBillCreateForm';

interface Props {
  bills: any[];
  opened: number;
  setOpened: any;
  setBills: (bills: Bill[]) => void;
}

export const ScanItemsMobile = (props: Props) => {
  return (
    <WBBox pb={2} bgcolor="background.default">
      <ScanItemsList>
        {props.bills.map((bill, index) => (
          <ScanItemCard
            key={index}
            index={index}
            opened={props.opened}
            setOpened={props.setOpened}
            bill={bill}
            updateBill={(newBill: Bill) => {
              const newBills = [...props.bills];
              newBills[index] = newBill;
              props.setBills(newBills);
            }}
          />
        ))}
      </ScanItemsList>
    </WBBox>
  );
};
const ScanItemsList = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
