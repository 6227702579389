import * as React from 'react';
import {
  WBCheckbox,
  WBTypography,
  WBFlex,
  WBIcon,
  WBCollapse,
  WBSvgIcon,
  WBImage,
} from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LoadSvgIcon from '../../../../../component/LoadSvgIcon/LoadSvgIcon';
import BPay from '../../../../../../assets/images/bpay.png';
import Bank from '../../../../../../assets/icons/bank.svg';
import File from '../../../../../../assets/icons/file.svg';
import { formatDateWithDaysDifference } from '../../../../../helpers/string';
import { BillDetail } from '../../BillDetail';
import { Bill } from '../../../PayBillCreateForm';
import {
  getFromName,
  isBillLoading,
} from 'apps/react-app/src/app/helpers/tasks';
import { numberToCurrency } from '@admiin-com/ds-common';
import { t } from 'i18next';
import { ScanItemTableLoading } from './ScanItemTableLoading';

interface Props {
  bill: Bill;
  index: number;
  opened: number;
  setOpened: any;
  updateBill: (newBill: Bill) => void;
}

export const ScanItemsTableRow = ({
  bill,
  index,
  opened,
  setOpened,
  updateBill,
}: Props) => {
  const isLoading = isBillLoading(bill.documentAnalysis);
  const checked = bill.checked;

  const handleContainerClick = () => {
    setOpened(opened === index ? -1 : index);
  };

  const handleCheck = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newChecked = !checked;
    updateBill({ ...bill, checked: newChecked });
  };

  const paymentDetails = React.useMemo(() => {
    if (bill?.bpay && bill?.bpay?.billerCode) {
      return (
        <WBFlex gap={1} alignItems="center">
          <WBImage src={BPay} width={40} height={40} />
          <Text>
            {`${bill?.bpay.billerCode || t('missing')} / ${
              bill?.bpay.referenceNumber || t('missing')
            }`}
          </Text>
        </WBFlex>
      );
    } else if (bill?.bank) {
      return (
        <WBFlex gap={1} alignItems="center">
          <LoadSvgIcon component={Bank} width={25} height={25} />
          <Text>
            {`${bill?.bank.routingNumber || t('missing')} / ${
              bill?.bank.accountNumber || t('missing')
            }`}
          </Text>
        </WBFlex>
      );
    }
    return null;
  }, [bill]);

  if (isLoading) return <ScanItemTableLoading />;

  return (
    <>
      <StyledTableRow onClick={handleContainerClick} hover>
        <TableCell padding="checkbox">
          <CircleCheckbox onClick={handleCheck} checked={checked} />
        </TableCell>
        <TableCell>
          <WBFlex gap={1} alignItems="center">
            <LoadSvgIcon component={File} width={25} height={25} />
            <Text>{getFromName(bill.from)}</Text>
          </WBFlex>
        </TableCell>
        <TableCell colSpan={2}>{paymentDetails}</TableCell>
        <TableCell>
          <Text>{formatDateWithDaysDifference(bill.dueAt)}</Text>
        </TableCell>
        <TableCell align="right">
          <Text textAlign={'right'}>{numberToCurrency(bill.amount ?? 0)}</Text>
        </TableCell>
        <TableCell padding="checkbox">
          <WBIcon
            name={opened === index ? 'ChevronUp' : 'ChevronDown'}
            size={2}
          />
        </TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell colSpan={7} sx={{ padding: 0 }}>
          <WBCollapse in={opened === index}>
            <BillDetail bill={bill} index={index} onUpdateBill={updateBill} />
          </WBCollapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '& td': {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const Text = styled(WBTypography)(({ theme }) => ({
  ...theme.typography.body1,
}));

const DefaultCheckIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10C0 7.34784 1.05357 4.8043 2.92893 2.92893C4.8043 1.05357 7.34784 0 10 0C12.6522 0 15.1957 1.05357 17.0711 2.92893C18.9464 4.8043 20 7.34784 20 10C20 12.6522 18.9464 15.1957 17.0711 17.0711C15.1957 18.9464 12.6522 20 10 20C7.34784 20 4.8043 18.9464 2.92893 17.0711C1.05357 15.1957 0 12.6522 0 10ZM9.42933 14.28L15.1867 7.08267L14.1467 6.25067L9.23733 12.3853L5.76 9.488L4.90667 10.512L9.42933 14.28Z"
      fill="#DDDDDD"
    />
  </svg>
);

export const CheckedIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9C0 6.61305 0.948212 4.32387 2.63604 2.63604C4.32387 0.948212 6.61305 0 9 0C11.3869 0 13.6761 0.948212 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 11.3869 17.0518 13.6761 15.364 15.364C13.6761 17.0518 11.3869 18 9 18C6.61305 18 4.32387 17.0518 2.63604 15.364C0.948212 13.6761 0 11.3869 0 9ZM8.4864 12.852L13.668 6.3744L12.732 5.6256L8.3136 11.1468L5.184 8.5392L4.416 9.4608L8.4864 12.852Z"
      fill="#30C86D"
    />
  </svg>
);

export const CircleCheckbox = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: (e: React.MouseEvent) => void;
}) => {
  return (
    <WBCheckbox
      onClick={onClick}
      checked={checked}
      icon={<WBSvgIcon fontSize="small">{DefaultCheckIcon}</WBSvgIcon>}
      checkedIcon={<WBSvgIcon fontSize="small">{CheckedIcon}</WBSvgIcon>}
    />
  );
};
