import {
  XeroScopeSet,
  xeroCreateConsentUrl as XERO_CREATE_CONSENT_URL,
} from '@admiin-com/ds-graphql';
import { gql, useMutation } from '@apollo/client';
import { getXeroEnv } from '../../helpers/xero';
import { useSnackbar } from '@admiin-com/ds-web';

export const useXeroConnect = (
  scopeSet: XeroScopeSet = XeroScopeSet.ACCOUNTING
) => {
  const showSnackbar = useSnackbar();
  const [xeroCreateConsentUrl] = useMutation(gql(XERO_CREATE_CONSENT_URL));
  const onSignUpXero = async () => {
    try {
      const { data } = await xeroCreateConsentUrl({
        variables: {
          input: {
            scopeSet,
            xeroEnv: getXeroEnv(window.location.href),
          },
        },
      });
      if (data?.xeroCreateConsentUrl) {
        window.location.replace(data?.xeroCreateConsentUrl);
      }
    } catch (err) {
      console.log('ERROR create xero token set', err);
      showSnackbar({
        message: 'Error creating xero token set',
        severity: 'error',
      });
    }
  };

  return onSignUpXero;
};
