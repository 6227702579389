import { COUNTRY_LIST } from '../constants';

export const getCountryList = (language: string) => {
  const options = COUNTRY_LIST.find((country) => country.language === language);

  if (options) {
    return options.options;
  }

  const enOptions = COUNTRY_LIST.find((country) => country.language === 'en');

  return (enOptions && enOptions.options) || [];
};

export function getOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
