import { STREET_TYPES_AUSTRALIA } from '@admiin-com/ds-common';
import {
  AccountStatus,
  Contact,
  Entity,
  EntityType,
  FromToType,
  VerificationStatus,
} from '@admiin-com/ds-graphql';

export function isVerifiedEntity(entity: Entity) {
  return entity?.accountStatus === AccountStatus.ACTIVE;
}

export function isVerifiedBeneficialOwner(entity: Entity) {
  // for()
}

export function isIndividualEntity(business: Entity | null | undefined) {
  return false;
  // business?.type === EntityType.INDIVIDUAL ||
  // business?.type === EntityType.SOLE_TRADER
}

export function isEntityOrContact(business: Entity | Contact) {
  if (!business) return null;
  if ('entityId' in business) return FromToType.CONTACT;
  else return FromToType.ENTITY;
}

export const mapStreetType = (address: string): string | null => {
  const addressParts = address?.split(' ') ?? [];

  for (const part of addressParts) {
    if (STREET_TYPES_AUSTRALIA.includes(part)) {
      return part;
    }
  }

  return null;
};
