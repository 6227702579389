import React from 'react';
import { useXeroConnectStatus } from '../../ConnectDisconnectXero/useXeroConnectStatus';
import { FormButton } from './TaskUpload';
import LoadSvgIcon from '../../../component/LoadSvgIcon/LoadSvgIcon';

import XeroLogoSuccess from '../../../../assets/icons/xero-logo-success.svg';
import { t } from 'i18next';
import { useXeroConnect } from '../../ConnectDisconnectXero/useXeroConnect';
import { useInvoiceSync } from '../../ConnectDisconnectXero/useInvoiceSync';
import { XeroScopeSet } from '@admiin-com/ds-graphql';

interface Props {
  onSucces?: () => void;
}

export const XeroButton = ({ onSucces }: Props) => {
  const { isXeroConnected } = useXeroConnectStatus();

  const [loading, setLoading] = React.useState(false);
  const xeroConnect = useXeroConnect(XeroScopeSet.ACCOUNTING);
  const { startXeroSync, loading: xeroSyncLoading } = useInvoiceSync({
    onSucces,
  });
  // const startXero
  const handleXeroConnect = async () => {
    setLoading(true);
    await xeroConnect();
    setLoading(false);
  };
  const handleXeroSync = async () => {
    setLoading(true);
    await startXeroSync();
    setLoading(false);
  };

  return (
    <FormButton
      loading={loading}
      disabled={xeroSyncLoading}
      startIcon={
        <LoadSvgIcon component={XeroLogoSuccess} width={30} height={30} />
      }
      onClick={(e) => {
        e.stopPropagation();
        isXeroConnected ? handleXeroSync() : handleXeroConnect();
      }}
      type={'button'}
    >
      {t(isXeroConnected ? 'sycnWithXero' : 'connectWithXero', {
        ns: 'authentication',
      })}
    </FormButton>
  );
};
