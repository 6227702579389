import { WBButton, WBFlex } from '@admiin-com/ds-web';
import { styled } from '@mui/material';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import LoadSvgIcon from '../../component/LoadSvgIcon/LoadSvgIcon';
import EyeIcon from '../../../assets/icons/eye-icon.svg';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
  onPreview?: () => void;
  onEnter?: () => void;
  onDraft?: () => Promise<void>;
  handleNext?: () => void;
  onConfirm?: () => Promise<void>;
  billCount?: number;
  isValid: boolean;
  isLoading?: boolean;
  disableDraft?: boolean;
}
export const InvoiceCreateButtons = ({
  onPreview,
  onEnter,
  onDraft,
  handleNext,
  onConfirm,
  billCount,
  isValid,
  disableDraft,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const [draftLoading, setDraftLoading] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const handleDraft = async () => {
    if (!onDraft) return;
    try {
      setDraftLoading(true);
      await onDraft();
      setDraftLoading(false);
    } catch (error) {
      setDraftLoading(false);
      throw error;
    }
  };
  const handleConfirm = async () => {
    if (!onConfirm) return;
    try {
      setConfirmLoading(true);
      await onConfirm();
      setConfirmLoading(false);
    } catch (error) {
      setConfirmLoading(false);
      throw error;
    }
  };
  return (
    <ButtonContainer>
      {onPreview && (
        <NoBorderButton
          variant="outlined"
          type="button"
          onClick={onPreview}
          sx={{ mt: 1, mr: 1 }}
          fullWidth
        >
          <LoadSvgIcon component={EyeIcon} width={25} height={25} />
          &nbsp; {t('preview', { ns: 'taskbox' })}
        </NoBorderButton>
      )}
      {onEnter && (
        <WBButton
          variant="outlined"
          onClick={onEnter}
          sx={{ mt: 1, mr: 1 }}
          fullWidth
          type="button"
        >
          {t('enterManually', { ns: 'taskbox' })}
        </WBButton>
      )}
      {onDraft && (
        <WBButton
          variant="outlined"
          onClick={handleDraft}
          loading={draftLoading}
          sx={{ mt: 1, mr: 1 }}
          fullWidth
          disabled={disableDraft}
        >
          {t('saveAsDraft', { ns: 'taskbox' })}
        </WBButton>
      )}
      {handleNext && (
        <WBButton
          onClick={handleNext}
          sx={{ mt: 1, mr: 1 }}
          disabled={!isValid}
          fullWidth
        >
          {t('next', { ns: 'taskbox' })}
        </WBButton>
      )}
      {onConfirm && (
        <WBButton
          loading={confirmLoading}
          onClick={handleConfirm}
          sx={{ mt: 1, mr: 1 }}
          disabled={!isValid}
          fullWidth
        >
          {`Add ${billCount} ${billCount === 1 ? 'bill' : 'bills'} to Taskbox`}
          &nbsp;
          {isLoading && <LoadingSpinner />}
        </WBButton>
      )}
    </ButtonContainer>
  );
};
const ButtonContainer = styled(WBFlex)(({ theme }) => ({
  flexDirection: 'row',
  width: '50%',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    flexDirection: 'column-reverse',
  },
}));

export const NoBorderButton = styled(WBButton)(({ theme }) => ({
  border: 0,
  '&:hover': {
    border: 0,
  },
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'start',
  },
}));
