import React from 'react';
import { WBBox, WBFlex, WBSkeleton } from '@admiin-com/ds-web';
import { styled, useTheme } from '@mui/material';

export const ScanItemMobileLoading = () => {
  const theme = useTheme();
  return (
    <WBBox pb={2} bgcolor="background.default">
      <Container>
        <WBFlex gap={2} justifyContent={'space-between'} alignItems={'center'}>
          <WBSkeleton
            variant="rounded"
            width="80%"
            height={16}
            animation="wave"
          />
          <WBBox
            width={16}
            height={16}
            sx={{
              borderRadius: '100%',
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          />
        </WBFlex>
        <WBSkeleton
          variant="rounded"
          width="80%"
          height={16}
          animation="wave"
        />
        <WBSkeleton
          variant="rounded"
          width="80%"
          height={16}
          animation="wave"
        />
        <WBFlex justifyContent={'end'}>
          <WBSkeleton
            variant="rounded"
            width="50%"
            height={16}
            animation="wave"
          />
        </WBFlex>
      </Container>
    </WBBox>
  );
};
const Container = styled(WBBox)(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
  minHeight: '100px',
  display: 'flex',
  gap: theme.spacing(2),
  flexDirection: 'column',
}));
