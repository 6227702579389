import { BillDetail } from './BillDetail';

interface Props {
  bill: any;
  onUpdateBill: (bill: any) => void;
}
export const ManualBillForm = ({ bill, onUpdateBill }: Props) => {
  return (
    <BillDetail
      bill={bill}
      onUpdateBill={onUpdateBill}
      index={0}
      isManualBillInput={true}
    />
  );
};
