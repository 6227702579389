import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { xeroCreateInvoiceSync } from '@admiin-com/ds-graphql';
import { useSelectedEntity } from '../../hooks/useSelectedEntity/useSelectedEntity';

interface Props {
  onSucces?: () => void;
}

export const useInvoiceSync = (props: Props) => {
  const { entity } = useSelectedEntity();
  const [startInvoiceSync] = useMutation(gql(xeroCreateInvoiceSync));
  const [loading, setLoading] = React.useState(false);

  const startXeroSync = async () => {
    try {
      setLoading(true);
      await startInvoiceSync({
        variables: {
          input: {
            entityId: entity?.id,
          },
        },
      });
    } catch (err) {
      console.log('ERROR start Invoice sync', err);
      setLoading(false);
    }
  };

  const xeroInvoiceSyncStatus = entity?.xeroInvoiceSyncStatus;

  React.useEffect(() => {
    if (xeroInvoiceSyncStatus === 'PENDING') {
      setLoading(true);
    } else if (xeroInvoiceSyncStatus === 'SYNCED') {
      if (props.onSucces) {
        props.onSucces();
      }
      setLoading(false);
    }
  }, [props, xeroInvoiceSyncStatus]);

  return { startXeroSync, loading };
};
