import { FormProvider, useForm } from 'react-hook-form';
import React, { useEffect } from 'react';
import { Bill } from '../PayBillCreateForm';
import { WBDialog, WBForm, WBBox, WBButton } from '@admiin-com/ds-web';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { t } from 'i18next';
import { BillDetail } from './BillDetail';

interface Props {
  open: boolean;
  onClose: () => void;
  onOk: (bill: Bill) => void;
  item: Bill;
}

export default function BillModal(props: Props) {
  const methods = useForm<Bill>({
    values: props.item,
  });

  const handleUpdate = (data: Bill) => {
    props.onOk(data);
  };

  useEffect(() => {
    methods.reset(props.item);
    setTimeout(() => methods.trigger(), 100);
  }, [methods, props.item]);

  const { handleSubmit } = methods;
  return (
    props.open && (
      <WBDialog fullScreen onClose={props.onClose} open={props.open}>
        <FormProvider {...methods}>
          <WBForm onSubmit={handleSubmit(handleUpdate)} mt={0}>
            <DialogTitle textAlign={'center'} variant="h5">
              {t('editItem', { ns: 'taskbox' })}
            </DialogTitle>
            <DialogContent dividers>
              <BillDetail bill={props.item} />
            </DialogContent>
            <DialogActions>
              <WBBox width="100%">
                <WBButton
                  variant="outlined"
                  type="button"
                  onClick={props.onClose}
                  sx={{ mt: 1 }}
                  fullWidth
                >
                  {t('cancel', { ns: 'taskbox' })}
                </WBButton>
                <WBButton type="submit" sx={{ mt: 1 }} fullWidth>
                  {t('save', { ns: 'taskbox' })}
                </WBButton>
              </WBBox>
            </DialogActions>
          </WBForm>
        </FormProvider>
      </WBDialog>
    )
  );
}
